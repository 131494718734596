import React from "react";
import { useForm, Controller } from "react-hook-form";
import { TextField, Button, Container, Typography } from "@mui/material";
import api from "../../api/api"; // Certifique-se de ajustar o caminho do seu arquivo API

const EditSeller = ({
  seller,
  setWarning,
  handleOpenSnackbar,
  onSave,
  onClose,
}) => {
  const { control, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      name: seller.name || "",
      email: seller.email || "",
      phone: seller.phone || "",
      docNumber: seller.docNumber || "",
      address: {
        street: seller.address?.street || "",
        number: seller.address?.number || "",
        neighborhood: seller.address?.neighborhood || "",
        city: seller.address?.city || "",
        state: seller.address?.state || "",
      },
    },
  });

  // Update form values when seller prop changes
  React.useEffect(() => {
    if (seller) {
      setValue("name", seller.name || "");
      setValue("email", seller.email || "");
      setValue("phone", seller.phone || "");
      setValue("docNumber", seller.docNumber || "");
      setValue("address", seller.address || "");
    }
  }, [seller, setValue]);

  const onSubmit = async (data) => {
    try {
      await api.put(
        `/sellers/${seller.id}`,
        <div style={{ marginTop: 20 }}>
          <Button
            onClick={onClose}
            variant="outlined"
            style={{ marginRight: 10 }}
          >
            Cancelar
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Salvar
          </Button>
        </div>
      ); // Ajuste o endpoint conforme necessário
      setWarning({
        type: "success",
        message: "Vendedor atualizado!",
      });
      handleOpenSnackbar();
      onSave();
      onClose();
    } catch (error) {
      console.error("Failed to update seller", error);
      setWarning({
        type: "error",
        message: "Erro ao atualizar o vendedor!",
      });
      handleOpenSnackbar();
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Editar Vendedor
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Nome"
              variant="outlined"
              fullWidth
              margin="normal"
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
            />
          )}
        />
        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Telefone"
              variant="outlined"
              fullWidth
              margin="normal"
            />
          )}
        />
        <Controller
          name="docNumber"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="CPF/CNPJ"
              variant="outlined"
              fullWidth
              margin="normal"
            />
          )}
        />
        <Typography variant="h6" gutterBottom>
          Endereço
        </Typography>
        <Controller
          name="address"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Endereço"
              variant="outlined"
              fullWidth
              margin="normal"
            />
          )}
        />

        <div style={{ marginTop: 20 }}>
          <Button
            onClick={onClose}
            variant="outlined"
            style={{ marginRight: 10 }}
          >
            Cancelar
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Salvar
          </Button>
        </div>
      </form>
    </Container>
  );
};

export default EditSeller;
