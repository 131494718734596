export const formatPhoneNumber = (phone) => {
  if (!phone) return "";

  const cleaned = phone.replace(/\D/g, ""); // Remove qualquer caractere não numérico
  const match = cleaned.match(/^(\d{2})(\d{4,5})(\d{4})$/);

  if (match) {
    const [, ddd, part1, part2] = match;
    return `(${ddd}) ${part1}-${part2}`;
  }

  return phone;
};
