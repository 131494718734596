import React, { useEffect, useState } from "react";
import api from "../../api/api";
import Create from "./Create";
import Edit from "./Edit";
import Alerts from "../Alerts";

const View = () => {
  const [cards, setCards] = useState("");
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [item, setItem] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [warning, setWarning] = useState({});

  useEffect(() => {
    // A função só será executada quando `cards` for uma string vazia.
    if (cards !== "") return;
    const fetchFeaturedProducts = async () => {
      try {
        const response = await api.get("products/featured");
        setFeaturedProducts(response.data);
      } catch (error) {
        console.error("Erro ao buscar produtos destacados:", error); // É bom manter o log de erros
        setWarning({
          type: "error",
          message: "Falha ao carregar os cards do servidor!",
        });
        handleOpenSnackbar();
      }
    };

    fetchFeaturedProducts();
  }, [cards]); // Dependência `cards` para re-executar o efeito quando seu valor mudar.

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <div className="p-4">
      <div className="flex justify-between mb-4">
        <button
          onClick={() => {
            cards === "" ? setCards("create") : setCards("");
          }}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition-colors"
        >
          {cards === "" ? "Criar Destaque" : "Voltar"}
        </button>
      </div>
      {cards === "create" ? (
        <Create
          setWarning={setWarning}
          handleOpenSnackbar={handleOpenSnackbar}
          setCards={setCards}
        />
      ) : cards === "edit" ? (
        <Edit
          setWarning={setWarning}
          handleOpenSnackbar={handleOpenSnackbar}
          item={item}
          setCards={setCards}
        />
      ) : featuredProducts.length > 0 ? (
        <div className="space-y-6">
          {featuredProducts
            .sort((a, b) => {
              if (a.type === "FOR_YOU" && b.type === "FOR_BUSINESS") return -1;
              if (a.type === "FOR_BUSINESS" && b.type === "FOR_YOU") return 1;
              return 0;
            })
            .map((featured, index) => (
              <div
                key={index}
                className="bg-white border rounded-lg overflow-hidden "
              >
                <div className="flex justify-between p-5 border-b border-gray-200">
                  <div>
                    <h2 className="text-xl font-bold text-black">
                      {featured.title}
                    </h2>
                    <div className="flex">
                      <p className="text-sm font-bold px-1 text-gray-700">
                        Posição: {featured.position}
                      </p>
                      <p className="text-sm font-bold px-1 text-gray-700">
                        Tipo: {featured.type}
                      </p>
                      <p className="text-sm font-bold px-1 text-gray-700">
                        Link: {featured.href}
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      setCards("edit");
                      setItem(featured);
                    }}
                    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 transition-colors"
                  >
                    Editar Card
                  </button>
                </div>
                <div className="flex flex-wrap justify-start items-stretch p-1 ">
                  {featured.products?.map((product) => (
                    <div
                      key={product.id}
                      className="max-w-xs border mx-1 rounded-lg overflow-hidden shadow-sm p-4 mb-4 flex flex-col w-full sm:w-1/2 md:w-1/3 lg:w-1/4"
                    >
                      <img
                        src={
                          product.imgUrl?.[0] ||
                          "https://via.placeholder.com/150"
                        }
                        alt={product.name || "No image"}
                        className="w-[150px] h-[150px] object-cover mb-4 self-center"
                      />
                      <h3 className="text-md font-bold mb-2">{product.name}</h3>
                      <p className="text-gray-700 text-sm mb-2">
                        {product.shortDesc || "Descrição não disponível"}
                      </p>
                      <div className="mt-auto">
                        <span className="text-gray-900 font-bold">Preço: </span>
                        <span className="text-gray-600">
                          {product.customerPrice
                            ? `R$ ${product.customerPrice}`
                            : "Consultar"}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      ) : (
        "Carregando..."
      )}
      <Alerts
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        type={warning.type}
        message={warning.message}
      />
    </div>
  );
};

export default View;
