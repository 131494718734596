import React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import api from "../../api/api";

const Edit = ({ selectedBanner, setOption }) => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: selectedBanner, // Assegura que os valores iniciais sejam carregados
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "carousel",
  });

  const type = watch("type");

  const onSubmit = (data) => {
    // Se o tipo for CAROUSEL, ajusta href, imgUrl e videoUrl para ""
    if (data.type === "CAROUSEL") {
      data.href = "";
      data.imgUrl = "";
      data.videoUrl = "";
    }
    if (data.type !== "CAROUSEL") {
      data.carousel = [];
    }
    api
      .post(`banners`, data) // Mudança para PUT para atualização
      .then((response) => {
        console.log(response.data);
        setOption("view"); // Retorna para a visualização após a atualização
      })
      .catch((error) => console.error("Erro na atualização", error));
  };
  return (
    <div className="space-y-4">
      <button
        onClick={() => setOption("view")}
        className="hover:text-blue-700 text-blue-500 font-bold py-2 px-4 rounded transition ease-in-out duration-150"
      >
        Voltar
      </button>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-4 max-w-xl mx-auto my-10"
      >
        {/* Tipo de Banner */}
        <div>
          <label
            htmlFor="type"
            className="block text-sm font-medium text-gray-700"
          >
            Tipo
          </label>
          <select
            {...register("type", { required: "Este campo é obrigatório" })}
            className="mt-1 block w-full p-2 border rounded-md"
          >
            <option value="STANDARD">STANDARD</option>
            <option value="CAROUSEL">CAROUSEL</option>
          </select>
          {errors.type && (
            <span className="text-red-500">{errors.type.message}</span>
          )}
        </div>

        {/* Campos dinâmicos para CAROUSEL */}
        {type === "CAROUSEL" &&
          fields.map((field, index) => (
            <div key={field.id} className="flex space-x-2 items-top px-4">
              <input
                {...register(`carousel.${index}.href`)}
                placeholder="URL de redirecionamento"
                className="mt-1 block w-full p-2 border rounded-md"
              />
              <input
                {...register(`carousel.${index}.imgUrl`)}
                placeholder="URL da imagem"
                className="mt-1 block w-full p-2 border rounded-md"
              />
              <button
                type="button"
                onClick={() => remove(index)}
                className="px-4 bg-red-500 text-white rounded-md h-8 text-center mt-4"
              >
                Remover
              </button>
            </div>
          ))}
        {type === "CAROUSEL" && (
          <button
            type="button"
            onClick={() => append({ href: "", imgUrl: "" })}
            className="px-4 py-2 bg-blue-500 text-white rounded-md"
          >
            Adicionar mais
          </button>
        )}

        {/* Posição do Banner */}
        {type !== "CAROUSEL" && (
          <div>
            <select
              {...register("position", {
                required: "Este campo é obrigatório",
              })}
              className="block w-full p-2 border rounded-md"
            >
              <option value="TOP">TOP</option>
              <option value="MID">MID</option>
              <option value="BOTTOM">BOTTOM</option>
            </select>
            {errors.position && (
              <span className="text-red-500">{errors.position.message}</span>
            )}
          </div>
        )}

        {/* Título */}
        <div>
          <input
            type="text"
            {...register("title", { required: "Este campo é obrigatório" })}
            className="block w-full p-2 border rounded-md"
            placeholder="Título"
          />
          {errors.title && (
            <span className="text-red-500">{errors.title.message}</span>
          )}
        </div>

        {/* Descrição */}
        <div>
          <input
            type="text"
            {...register("text", { required: "Este campo é obrigatório" })}
            className="block w-full p-2 border rounded-md"
            placeholder="Descrição"
          />
          {errors.text && (
            <span className="text-red-500">{errors.text.message}</span>
          )}
        </div>

        {/* Campos para STANDARD */}
        {type !== "CAROUSEL" && (
          <>
            <div>
              <input
                type="text"
                {...register("href")}
                className="block w-full p-2 border rounded-md"
                placeholder="Href"
              />
            </div>
            <div>
              <input
                type="text"
                {...register("imgUrl")}
                className="block w-full p-2 border rounded-md"
                placeholder="URL da Imagem"
              />
            </div>
            <div>
              <input
                type="text"
                {...register("videoUrl")}
                className="block w-full p-2 border rounded-md"
                placeholder="URL do Vídeo"
              />
            </div>
          </>
        )}

        <button
          type="submit"
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded transition ease-in-out duration-150"
        >
          Salvar Alterações
        </button>
      </form>
    </div>
  );
};

export default Edit;
