import React, { useState } from "react";

const View = ({ banners, setOption, setSelectedBanner }) => {
  const [selectedBannerId, setSelectedBannerId] = useState(null);

  // Primeiro, separar os banners CAROUSEL
  const carouselBanners = banners.filter(
    (banner) => banner.type === "CAROUSEL"
  );

  // Depois, separar e ordenar os banners STANDARD por position
  const standardBanners = banners
    .filter((banner) => banner.type === "STANDARD")
    .sort((a, b) => {
      const order = ["TOP", "MID", "BOTTOM"]; // Define a ordem desejada
      return order.indexOf(a.position) - order.indexOf(b.position);
    });

  // Combina os banners CAROUSEL no início e os STANDARD ordenados após
  const orderedBanners = [...carouselBanners, ...standardBanners];

  return (
    <div className="space-y-4">
      <button
        onClick={() => setOption("create")}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition ease-in-out duration-150"
      >
        Criar Novo Banner
      </button>

      {orderedBanners.map((banner) => (
        <Banner
          key={banner.id}
          banner={banner}
          setSelectedBannerId={setSelectedBannerId}
          setSelectedBanner={setSelectedBanner}
          isSelected={selectedBannerId === banner.id}
          setOption={setOption}
        />
      ))}
    </div>
  );
};

export default View;

const Banner = ({
  banner,
  setSelectedBannerId,
  isSelected,
  setSelectedBanner,
  setOption,
}) => {
  const { id, type, title, text, href, imgUrl, carousel, position } = banner;
  const b = banner;

  const renderStandardBanner = () => (
    <div className="bg-white border rounded-lg overflow-hidden ">
      <div className="bg-white border rounded-lg overflow-hidden">
        <div className="flex justify-between p-5 border-b border-gray-200">
          <div>
            <h2 className="text-xl font-semibold text-gray-800">{title}</h2>
            <div className="flex">
              <p className="mt-2 mx-1 text-gray-600">
                Posição:{" "}
                {position === "TOP"
                  ? "Topo"
                  : position === "MID"
                  ? "Meio"
                  : "Rodapé"}
              </p>
              <p className="mt-2 mx-1 text-gray-600">Link: {href}</p>
              <p className="mt-2 mx-1 text-gray-600">Descrição: {text}</p>
            </div>
          </div>
          <div className="">
            <button
              onClick={() => {
                setSelectedBannerId(id);
                setSelectedBanner(b);
                setOption("edit");
              }}
              className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 transition-colors"
            >
              Editar Card
            </button>
          </div>
        </div>
        <div className="flex justify-center m-2">
          <img
            src={imgUrl}
            alt={title}
            className="rounded-lg w-52 md:w-64 lg:w-80 h-auto object-cover"
          />
        </div>
      </div>
    </div>
  );

  const renderCarouselBanner = () => (
    <div className="bg-white border rounded-lg overflow-hidden">
      <div className="flex justify-between p-5 border-b border-gray-200">
        <div>
          <h2 className="text-xl font-bold text-black">{title}</h2>
          <div className="flex">
            <p className="text-sm font-bold px-1 text-gray-700">
              Posição: Carrossel
            </p>
            ,
            <p className="text-sm font-bold px-1 text-gray-700">
              Descrição: {text}
            </p>
          </div>
        </div>
        <div>
          <button
            onClick={() => {
              handleBannerClick();
              setOption("edit");
            }}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 transition-colors"
          >
            Editar Card
          </button>
        </div>
      </div>
      <div className="flex justify-around p-2">
        {carousel.map((item, index) => (
          <div className="">
            <img
              src={item.imgUrl}
              alt={`Carousel item ${index + 1}`}
              className="rounded-lg w-52 md:w-64 lg:w-80 h-auto object-cover"
            />
            <p className="mt-2 text-gray-600">Link: {item.href}</p>
          </div>
        ))}
      </div>
    </div>
  );

  const handleBannerClick = () => {
    setSelectedBannerId(id); // Atualiza o estado do banner selecionado no componente pai

    setSelectedBanner(b);
  };
  return (
    <div className={` max-w-4xl mx-auto my-8  rounded-lg shadow-lg `}>
      {type === "STANDARD" ? renderStandardBanner() : renderCarouselBanner()}
    </div>
  );
};
