import React, { useEffect, useState } from "react";
import api from "../api/api";
import { Header } from "../components";

const NewsLetters = () => {
  const [linhasSelecionadas, setLinhasSelecionadas] = useState([]);
  const [selecionarTudo, setSelecionarTudo] = useState(false);
  const [dados, setDados] = useState([]);

  useEffect(() => {
    api.get("/newsletters").then((res) => {
      setDados(res.data);
      console.log(res.data);
    });
  }, []);

  const toggleSelecionarTudo = () => {
    setSelecionarTudo(!selecionarTudo);
    if (!selecionarTudo) {
      setLinhasSelecionadas(dados.map((linha) => linha.id));
    } else {
      setLinhasSelecionadas([]);
    }
  };

  const toggleLinhaSelecionada = (id) => {
    const linhaSelecionadaIndex = linhasSelecionadas.indexOf(id);
    if (linhaSelecionadaIndex !== -1) {
      const novasSelecionadas = [...linhasSelecionadas];
      novasSelecionadas.splice(linhaSelecionadaIndex, 1);
      setLinhasSelecionadas(novasSelecionadas);
    } else {
      setLinhasSelecionadas([...linhasSelecionadas, id]);
    }
  };

  const copiarDados = () => {
    const dadosSelecionados = dados.filter((linha) =>
      linhasSelecionadas.includes(linha.id)
    );

    // Títulos das colunas
    const titulosColunas = ["Nome", "E-mail", "Inscrito em"];

    // Gerar linhas de dados formatadas como CSV
    const linhasCSV = dadosSelecionados.map((linha) =>
      [linha.name, linha.email, formatarData(linha.createdAt)]
        .map((valor) =>
          // Escapar as aspas duplas e adicionar aspas duplas se necessário
          typeof valor === "string" && valor.includes('"')
            ? `"${valor.replace(/"/g, '""')}"`
            : `"${valor}"`
        )
        .join(",")
    );

    // Montar o texto final com títulos de colunas e linhas de dados
    const textoParaCopiar = `${titulosColunas.join(",")}\n${linhasCSV.join(
      "\n"
    )}`;

    navigator.clipboard.writeText(textoParaCopiar).then(
      () => {
        console.log("Dados copiados com sucesso!");
      },
      (err) => {
        console.error("Erro ao copiar os dados: ", err);
      }
    );
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Home page" title="NewsLetters" />
      <div className=" w-full flex justify-end">
        <button
          onClick={copiarDados}
          disabled={linhasSelecionadas.length === 0}
          className={`${
            linhasSelecionadas.length === 0
              ? "bg-gray-400"
              : "bg-blue-500 hover:bg-blue-700"
          } text-white font-bold py-2 px-4 rounded mb-4`}
        >
          Copiar Dados
        </button>
      </div>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="px-6 py-3 border border-gray-200 bg-gray-50">
              <input
                type="checkbox"
                checked={selecionarTudo}
                onChange={toggleSelecionarTudo}
              />
            </th>
            <th className="px-6 py-3 border border-gray-200 bg-gray-50">
              Nome
            </th>
            <th className="px-6 py-3 border border-gray-200 bg-gray-50">
              E-mail
            </th>
            <th className="px-6 py-3 border border-gray-200 bg-gray-50">
              Inscrito em
            </th>
          </tr>
        </thead>
        <tbody>
          {dados.map(
            (linha) =>
              linha.status === "SUBSCRIBED" && (
                <tr
                  key={linha.id}
                  className={`hover:bg-blue-100 cursor-pointer ${
                    linhasSelecionadas.includes(linha.id)
                      ? "bg-blue-300 text-pretty "
                      : ""
                  }`}
                  onClick={() => toggleLinhaSelecionada(linha.id)}
                >
                  <td className="px-6 py-4 border text-center border-gray-200">
                    <input
                      type="checkbox"
                      checked={linhasSelecionadas.includes(linha.id)}
                      onChange={() => toggleLinhaSelecionada(linha.id)}
                    />
                  </td>
                  <td className="px-6 py-4 border text-center border-gray-200">
                    {linha.name}
                  </td>
                  <td className="px-6 py-4 border text-center  border-gray-200">
                    {linha.email}
                  </td>
                  <td className="px-6 py-4 border text-center border-gray-200">
                    {formatarData(linha.createdAt)}
                  </td>
                </tr>
              )
          )}
        </tbody>
      </table>
    </div>
  );
};

// Função para formatar a data no formato desejado
const formatarData = (data) => {
  if (!data) return "-";
  const dataFormatada = new Date(data).toLocaleString("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  return dataFormatada;
};
export default NewsLetters;
