import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import St from "./St";
import { cfop } from "../../../data/cfop";
import { Divider } from "@mui/material";
import api from "../../../api/api";
import Header from "../../Header";
import CFOPAutocomplete from "./CFOPAutocomplete";
import Alerts from "../../Alerts";

export default function Create() {
  const location = useLocation();
  const { order } = location.state || {};
  const [data, setData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [warning, setWarning] = useState({});

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };
  const methods = useForm();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = methods;

  const onSubmit = (formData, emitir) => {
    const invoiceData = {
      codigo_identificacao_destino:
        data.aliquota_mva_st && data.valor_bc_st && data.aliquota_st ? 2 : 1,
      cliente: {
        endereco_principal: {
          ibge_cidade: order.orderShippingAddress.ibgeId,
          logradouro: order.orderShippingAddress.street,
          bairro: order.orderShippingAddress.neighborhood,
          numero: order.orderShippingAddress.number.toString(),
          complemento: order.orderShippingAddress.complement,
          cep: order.orderShippingAddress.zipCode.toString(),
        },
        nome_razao_social: order.user.name,
        nome_fantasia: "",
        tipo_cliente: order.user.docNumber.length <= 11 ? "PF" : "PJ",
        cnpj_cpf: order.user.docNumber,
        tipo_cadastro: "Cliente",
        email: order.user.email,
        status: 0,
        consumidor_final: "1",
      },
      transporte: {
        modalidade_frete: "0",
      },
      endereco_entrega: {
        cpf_cnpj: order.user.docNumber,
        razao_social: order.user.name,
        email: order.user.email,
        endereco: {
          ibge_cidade: order.orderShippingAddress.ibgeId,
          logradouro: order.orderShippingAddress.street,
          bairro: order.orderShippingAddress.neighborhood,
          numero: order.orderShippingAddress.number.toString(),
          complemento: order.orderShippingAddress.complement,
          cep: order.orderShippingAddress.zipCode.toString(),
        },
      },
      parcelas_pagamento: [
        {
          a_vista: 0.00,
          metodo: "17",
          valor: order.total,
        },
      ],
      produtos: order.items.map((item, index) => {
        let produto = {
          codigo: item.id, // Se tiver código de produto específico, use-o
          descricao: item.name,
          ncm: item.ncm,
          cest: data.cest,
          escala_relevante: 0,
          cfop: formData.cfop,
          unidade_comercial: "UN",
          quantidade_comercial: item.quantity,
          valor_unitario_comercial: item.price,
          valor_bruto: item.price,
          unidade_tributavel: "Un",
          quantidade_tributavel: item.quantity,
          valor_unitario_tributavel: item.price,
          frete: 0.00,
          desconto: 0.00,
          outros: 0.00,
          compoe_total: 1,
          numero_pedido: "",
          item_pedido: "001",
          impostos: {
            icms: {
              origem: 0.00,
              valor: 0.00,
              csosn:
                data.aliquota_mva_st && data.valor_bc_st && data.aliquota_st
                  ? "203"
                  : "102",
              aliquota: parseInt(formData.produtos[index].icms, 10) || 0.00,
              valor_bc: item.price || 0.00,
              aliquota_mva_st: parseInt(data.aliquota_mva_st, 10) || 0.00,
              valor_bc_st: item.price || 0.00,
              aliquota_st: parseInt(data.aliquota_st, 10) || 0.00,
            },
          },
        };
        if (
          formData.produtos[index].icms_dest_bc &&
          formData.produtos[index].icms_interna
        ) {
          produto.impostos.icms.icms_destino = {
            valor_bc_uf: parseInt(formData.produtos[index].icms_dest_bc, 10),
            aliquota_interna: parseInt(
              formData.produtos[index].icms_interna,
              10
            ),
          };
        }

        return produto;
      }),
    };

    api
      .post("/invoices", { data: invoiceData, orderId: order.id })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          let nfeId = res.data.data.id;
          if (emitir === true) {
            emitirNf(nfeId);
          } else {
            setWarning({
              type: "success", // Corrigi "sucess" para "success"
              message: "Nota Salva no banco de dados!",
            });
            handleOpenSnackbar();
          }
        }
      })
      .catch((err) => {
        console.error("Error no /invoices:", err); // Alterei para console.error para melhorar a semântica
      });
  };

  const emitirNf = (nfId) => {
    const emitirNfe = {
      id: nfId,
    };

    api.post("/invoices/issuance", emitirNfe).then((res) => {
      console.log("res", res);
    });
  };

  return order ? (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Fiscal" title="Emissão de NF-e" />
      <div className="container mx-auto p-4">
        <div className="rounded px-2 pt-6 pb-8 mb-4">
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="naturezaOperacao"
                >
                  Natureza da operação
                </label>
                <CFOPAutocomplete cfop={cfop} />
              </div>
              <Divider />
              <div className="my-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor={`user`}
                >
                  Cliente
                </label>
                <input
                  {...register(`cliente`)}
                  className="cursor-not-allowed appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id={`user`}
                  type="text"
                  disabled
                  defaultValue={order.user.name}
                  placeholder="Cliente"
                />
              </div>
              <Divider />
              <div className="flex flex-col my-6">
                {order.items?.map((product, index) => (
                  <>
                    <div key={product.id} className="flex w-full">
                      <div className="w-4/12 px-1 mb-6">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          htmlFor={`produto_${index}`}
                        >
                          Produto
                        </label>
                        <input
                          {...register(`produtos[${index}].name`)}
                          className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`produto_${index}`}
                          type="text"
                          defaultValue={product.name}
                          placeholder="Nome do produto"
                        />
                      </div>
                      <div className="w-[7%] px-1 mb-6">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          htmlFor={`Qtde${index}`}
                        >
                          Qtde.
                        </label>
                        <input
                          {...register(`produtos[${index}].quantity`)}
                          className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`Qtde.${index}`}
                          type="number"
                          defaultValue={product.quantity}
                        />
                      </div>
                      <div className="w-1/12 px-1 mb-6">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          htmlFor={`icms${index}`}
                        >
                          ICMS %
                        </label>
                        <input
                          {...register(`produtos[${index}].icms`)}
                          className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`icms${index}`}
                          type="text"
                          placeholder="%"
                          defaultValue={product.icms}
                        />
                      </div>
                      <div className="w-1/12 px-1 mb-6">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          htmlFor={`price${index}`}
                        >
                          Valor Unit
                        </label>
                        <input
                          {...register(`produtos[${index}].price`)}
                          className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`price${index}`}
                          type="text"
                          placeholder="%"
                          defaultValue={product.price}
                        />
                      </div>
                      <div className="w-1/12 px-1 mb-6">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          htmlFor={`cfop${index}`}
                        >
                          CFOP
                        </label>
                        <input
                          {...register(`produtos[${index}].cfop`)}
                          className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`cfop${index}`}
                          type="text"
                          placeholder="0"
                          defaultValue={getValues("cfop")}
                        />
                      </div>
                      <div className="w-1/12 px-1 mb-6">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          htmlFor={`ncm${index}`}
                        >
                          NCM
                        </label>
                        <input
                          {...register(`produtos[${index}].ncm`)}
                          className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`ncm${index}`}
                          type="text"
                          placeholder="0"
                          defaultValue={product.ncm}
                        />
                      </div>
                      <div className="w-[13%] px-1 mb-6">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          htmlFor={`icms_dest_bc${index}`}
                        >
                          Bc destino(ICMS)
                        </label>
                        <input
                          {...register(`produtos[${index}].icms_dest_bc`)}
                          className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`icms_dest_bc${index}`}
                          type="text"
                          placeholder="0"
                          defaultValue={product.icms_dest_bc}
                        />
                      </div>
                      <div className="w-2/12 px-1 mb-6">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          htmlFor={`icms_interna${index}`}
                        >
                          Alíquota interna(ICMS)
                        </label>
                        <input
                          {...register(`produtos[${index}].icms_interna`)}
                          className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`icms_interna${index}`}
                          type="text"
                          placeholder="%"
                          defaultValue={product.icms_interna}
                        />
                      </div>

                      <button
                        className="cursor-pointer text-blue-700 underline text-xs"
                        onClick={openModal}
                        type="button"
                      >
                        Editar ST
                      </button>
                    </div>
                    <Divider />
                  </>
                ))}
              </div>
              <div className="w-full flex mb-6">
                <div className="w-3/12 px-1">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor={`frete`}
                  >
                    Valor do Frete
                  </label>
                  <input
                    {...register(`Vfrete`)}
                    className="cursor-not-allowed appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`frete`}
                    type="text"
                    disabled
                    defaultValue={order.shippingPrice}
                  />
                </div>
                <div className="w-3/12 px-1 ml-6">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor={`subtotal`}
                  >
                    Valor Total dos Produtos
                  </label>
                  <input
                    {...register(`Stotal`)}
                    className="cursor-not-allowed appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`subtotal`}
                    type="text"
                    disabled
                    defaultValue={order.subTotal}
                  />
                </div>
                <div className="w-3/12 px-1 ml-6">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor={`total`}
                  >
                    Valor Total da Nota
                  </label>
                  <input
                    {...register(`total`)}
                    className="cursor-not-allowed appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`total`}
                    type="text"
                    disabled
                    defaultValue={order.total}
                  />
                </div>
              </div>
              {/* Botão de submissão */}
              <div className="flex">
                <div className="flex items-center justify-between">
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    Salvar Nota
                  </button>
                </div>
                <div className="flex items-center justify-between ml-3">
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={() =>
                      handleSubmit((data) => onSubmit(data, true))()
                    }
                  >
                    Salvar & Emitir
                  </button>
                </div>
              </div>
            </form>{" "}
          </FormProvider>
        </div>
        <St isOpen={isOpen} setIsOpen={setIsOpen} setData={setData} />{" "}
        <Alerts
          open={openSnackbar}
          handleClose={handleCloseSnackbar}
          type={warning.type}
          message={warning.message}
        />
      </div>
    </div>
  ) : (
    "Nenhuma ordem selecionada!"
  );
}
