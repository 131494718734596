import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useForm } from "react-hook-form"; // Importar useForm

export default function St({ isOpen, setIsOpen, setData }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const cleanData = (data) => {
    const cleanField = (value) => {
      return value
        ? value
            .toString()
            .replace(/[^\d.]/g, "")
            .replace(/(\..*)\./g, "$1")
        : null;
    };

    return {
      aliquota_mva_st: cleanField(data.aliquota_mva_st),
      valor_bc_st: cleanField(data.valor_bc_st),
      cest: cleanField(data.cest),
      aliquota_st: cleanField(data.aliquota_st),
    };
  };

  const onSubmit = (data) => {
    const cleanedData = cleanData(data);

    setData({
      aliquota_mva_st: cleanedData.aliquota_mva_st,
      valor_bc_st: cleanedData.valor_bc_st,
      cest: cleanedData.cest,
      aliquota_st: cleanedData.aliquota_st,
    });

    closeModal();
  };

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {/* Formulário envolto por Dialog.Panel com onSubmit */}
              <Dialog.Panel
                as="form"
                className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Informações da Substituição Tributária
                </Dialog.Title>

                {/* Campos do Formulário */}
                <div className="px-8 pt-6 pb-8 mb-4">
                  <div className="w-full px-3 mb-6">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="valor_bc_st"
                    >
                      Valor da BC do ICMS-ST
                    </label>
                    <input
                      {...register("valor_bc_st")}
                      placeholder="0"
                      id="valor_bc_st"
                      className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                    {errors.valor_bc_st && (
                      <span className="text-red-500">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>
                  <div className="w-full px-3 mb-6">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="aliquota_mva_st"
                    >
                      MVA do ICMS-ST
                    </label>
                    <input
                      {...register("aliquota_mva_st")}
                      placeholder="0"
                      id="aliquota_mva_st"
                      className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                    {errors.aliquota_mva_st && (
                      <span className="text-red-500">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>
                  <div className="w-full px-3 mb-6">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="cest"
                    >
                      Código CEST
                    </label>
                    <input
                      {...register("cest")}
                      placeholder="0"
                      id="cest"
                      className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                    {errors.cest && (
                      <span className="text-red-500">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>
                  <div className="w-full px-3 mb-6">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="aliquota_st"
                    >
                      Alíquota do ICMS-ST
                    </label>
                    <input
                      {...register("aliquota_st")}
                      placeholder="%"
                      id="aliquota_st"
                      className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                    {errors.aliquota_st && (
                      <span className="text-red-500">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>
                </div>

                {/* Botão de Submissão */}
                <div className="mt-4">
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                  >
                    Salvar
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
