import React, { useState, useEffect, useCallback } from "react";
import api from "../api/api";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import SearchIcon from "@mui/icons-material/Search";
import { QuickEditProduct } from "../components/Produtos/QuickEditProduct";
import { ProductItem } from "../components/Produtos/Item";
import { QuickCreateProduct } from "../components/Produtos/QuickCreateProduct";
import { debounce } from "lodash";
import { useStateContext } from "../contexts/ContextProvider";
import Alerts from "../components/Alerts";
import CreateProduct from "../components/Produtos/CreateProduct";
import EditProduct from "../components/Produtos/EditProduct";
import Duplicate from "../components/Produtos/Duplicate";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const ShowProducts = () => {
  const { currentColor } = useStateContext();
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filters, setFilters] = useState({ name: "" });
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedDuplicate, setSelectedDuplicate] = useState(null);
  const [quickSelectedProduct, setQuickSelectedProduct] = useState(null);
  const [visible, setVisible] = useState("");
  const [quickVisible, setQuickVisible] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [warning, setWarning] = useState({});
  const [confirmDelete, setConfirmDelete] = useState(null);

  const handleConfirmDelete = (productId) => {
    setConfirmDelete(productId);
  };

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const debouncedSearch = useCallback(
    debounce(() => {
      api
        .get(`/products?name=${filters.name}`)
        .then((res) => {
          setProducts(res.data.products);
        })
        .catch((error) => {
          console.error("There was an error fetching the products:", error);
        });
    }, 500),
    [filters.name, currentPage]
  );

  useEffect(() => {
    if (filters.name !== "") {
      debouncedSearch();
    } else {
      api
        .get(`/products?take=500`)
        .then((res) => {
          const sortedProducts = res.data.products.sort(
            (a, b) =>
              new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          );
          setProducts(sortedProducts);
        })
        .catch((error) => {
          console.error("There was an error fetching the products:", error);
        });
    }

    // Retorna uma função de limpeza que cancela a chamada debounced se o componente for desmontado
    // ou se os valores de filters ou currentPage mudarem antes que o debounce seja invocado.
    return () => {
      debouncedSearch.cancel();
    };
  }, [currentPage, filters, debouncedSearch]);

  const handleSearchChange = (event) => {
    setFilters({ ...filters, name: event.target.value });
  };

  const handlePaginationChange = (event, page) => {
    event.preventDefault();
    setCurrentPage(page);
  };

  const handleClose = () => {
    setVisible("");
    setQuickVisible(false);
    setSelectedProduct(null); // Limpa o produto selecionado para fechar o drawer
    setQuickSelectedProduct(null); // Limpa o produto selecionado para fechar o drawer
  };

  const handleSave = async () => {
    api
      .get(`/products?take=500`)
      .then((res) => {
        const sortedProducts = res.data.products.sort(
          (a, b) =>
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
        setProducts(sortedProducts);
      })
      .catch((error) => {
        console.error("There was an error fetching the products:", error);
      });
    setVisible("");
    setQuickVisible(false);
    setSelectedProduct(null);
    setQuickSelectedProduct(null);
  };

  const handleSelectedProduct = (product) => {
    setSelectedProduct(product);
    setVisible("edit");
  };

  const handleDuplicate = (product) => {
    setSelectedDuplicate(product);
    setVisible("duplicate");
  };

  const handleQuickSelectedProduct = (product) => {
    setQuickSelectedProduct(product);
  };

  const confirmDeleteProduct = () => {
    if (confirmDelete) {
      try {
        api.delete(`products/${confirmDelete}`).then((res) => {
          if (res.status === 204) {
            setWarning({
              type: "success",
              message: "Produto Deletado",
            });
            handleOpenSnackbar();

            api
              .get(`/products?take=500`)
              .then((res) => {
                const sortedProducts = res.data.products.sort(
                  (a, b) =>
                    new Date(b.updatedAt).getTime() -
                    new Date(a.updatedAt).getTime()
                );
                setProducts(sortedProducts);
              })
              .catch((error) => {
                console.error(
                  "There was an error fetching the products:",
                  error
                );
              });
          } else {
            setWarning({
              type: "error",
              message:
                "Houve uma falha ao tentar Deletar o Produto, Tente Novamente Mais Tarde",
            });
            handleOpenSnackbar();
          }
        });

        setConfirmDelete(null);
      } catch (error) {
        console.error("There was an error deleting the product:", error);
        setConfirmDelete(null);
      }
    }
  };

  return (
    <>
      <Dialog
        open={Boolean(confirmDelete)}
        onClose={() => setConfirmDelete(null)}
      >
        <DialogTitle>Confirmação de Deleção</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Você tem certeza de que deseja deletar este produto?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDelete(null)} color="primary">
            Cancelar
          </Button>
          <Button onClick={confirmDeleteProduct} color="secondary">
            Deletar
          </Button>
        </DialogActions>
      </Dialog>
      <QuickCreateProduct
        visible={quickVisible}
        onClose={handleClose}
        onSave={handleSave}
        setWarning={setWarning}
        handleOpenSnackbar={handleOpenSnackbar}
      />
      {quickSelectedProduct ? (
        <QuickEditProduct
          product={quickSelectedProduct}
          onClose={handleClose}
          onSave={handleSave}
          setWarning={setWarning}
          handleOpenSnackbar={handleOpenSnackbar}
        />
      ) : null}
      <Paper
        sx={{ paddingX: { xs: 3, md: 2 }, paddingY: { xs: 2, md: 3 }, my: 0.5 }}
      >
        {visible === "create" ? (
          <CreateProduct
            onClose={handleClose}
            onSave={handleSave}
            setWarning={setWarning}
            handleOpenSnackbar={handleOpenSnackbar}
          />
        ) : visible === "edit" ? (
          <EditProduct
            product={selectedProduct}
            onClose={handleClose}
            onSave={handleSave}
            setWarning={setWarning}
            handleOpenSnackbar={handleOpenSnackbar}
          />
        ) : visible === "duplicate" ? (
          <Duplicate
            product={selectedDuplicate}
            onClose={handleClose}
            onSave={handleSave}
            setWarning={setWarning}
            handleOpenSnackbar={handleOpenSnackbar}
          />
        ) : (
          <Grid container columns={16}>
            <Grid item xs={16} md={12}>
              <Stack
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
                padding={1}
                direction="row"
                gap={2}
              >
                <Typography variant="h5">{"Produtos"}</Typography>
                <Paper
                  component="form"
                  sx={{ display: "flex", alignItems: "center", width: 400 }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder={"Procurar Produto"}
                    inputProps={{ "aria-label": "procurar produto" }}
                    value={filters.name}
                    onChange={handleSearchChange}
                  />
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
                <div className="flex flex-col  md:flex-row">
                  <button
                    style={{ backgroundColor: currentColor }}
                    className="ml-2 inline-block text-white py-1 px-3 rounded-full text-sm cursor-pointer  transition-colors"
                    onClick={() => setVisible("create")}
                  >
                    Adicionar Produto
                  </button>
                  <button
                    style={{ backgroundColor: currentColor }}
                    className="ml-2 inline-block text-white py-1 px-3 rounded-full text-sm cursor-pointer  transition-colors"
                    onClick={() => setQuickVisible(true)}
                  >
                    Criação Rápida
                  </button>
                </div>
              </Stack>
              <Grid container>
                {products === null || products.length === 0 ? (
                  // Caso products seja null, mostrar "Carregando..."
                  <Grid container justifyContent="center" padding={3}>
                    <Typography variant="body2">Carregando...</Typography>
                  </Grid>
                ) : products !== null && products.length === 0 ? (
                  // Caso products seja um array vazio, mostrar "Nenhum Produto Encontrado"
                  <Grid container justifyContent="center" padding={3}>
                    <Typography variant="body2">
                      Nenhum Produto Encontrado!
                    </Typography>
                  </Grid>
                ) : (
                  // Caso existam produtos, renderizá-los
                  products.map((product) => (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={4}
                      xl={3}
                      key={product.id}
                      sx={{ padding: "8px" }}
                    >
                      <ProductItem
                        product={product}
                        onDelete={() => handleConfirmDelete(product.id)}
                        onEdit={() => handleSelectedProduct(product)}
                        onDuplicate={() => handleDuplicate(product)}
                        quickEdit={() => handleQuickSelectedProduct(product)}
                      />
                    </Grid>
                  ))
                )}
              </Grid>

              <Pagination
                count={totalPages}
                variant="outlined"
                color="primary"
                shape="rounded"
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  paddingY: "20px",
                }}
                onChange={handlePaginationChange}
              />
            </Grid>
            <Grid
              item
              sm={0}
              md={4}
              sx={{ display: { xs: "none", md: "block" } }}
            >
              <Stack padding="8px">
                <Typography variant="subtitle1">
                  {"Filtrar Categoria"}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        )}
        <Alerts
          open={openSnackbar}
          handleClose={handleCloseSnackbar}
          type={warning.type}
          message={warning.message}
        />
      </Paper>
    </>
  );
};

export default ShowProducts;
