import React from "react";
import View from "../components/FaixaTexto/View";
import { Header } from "../components";

const FaixaTexto = () => {
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Home Page" title="Faixa de Texto" />
      <View />
    </div>
  );
};

export default FaixaTexto;
