import React, { useState, useEffect } from "react";
import api from "../../api/api";
import TransferList from "../TransferList";
import { useForm } from "react-hook-form";

const Edit = ({ setCards, item, setWarning, handleOpenSnackbar }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: item, // Assegura que os valores iniciais sejam carregados
  });

  const onSubmit = (formData) => {
    api
      .post("/mosaics", formData)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          // 201 para Created
          setWarning({
            type: "success",
            message: "Card criado com sucesso!",
          });
          handleOpenSnackbar();
          setCards("");
        } else {
          // Alguma outra resposta de sucesso não esperada
          setWarning({
            type: "warning",
            message: "Card criado, mas com respostas inesperadas do servidor.",
          });
          handleOpenSnackbar();
          setCards("");
        }
      })
      .catch((error) => {
        let errorMessage = "Falha ao salvar o card no servidor!";
        if (error.response) {
          // Adiciona detalhes específicos do erro se disponível
          errorMessage += ` Detalhes: ${
            error.response.data.message || error.response.status
          }`;
        } else if (error.request) {
          // A requisição foi feita mas não houve resposta

          errorMessage = "O servidor não respondeu ao pedido.";
        } else {
          // Algum erro aconteceu ao montar o pedido

          errorMessage = "Erro ao enviar o pedido para o servidor.";
        }
        setWarning({
          type: "error",
          message: errorMessage,
        });
        handleOpenSnackbar();
      });
  };

  return (
    <div className=" mx-auto p-5 ">
      <ol className="list-decimal pl-5 space-y-2">
        <li className="text-sm text-gray-600">
          <p>Siga o layout para escolher a posição correta:</p>
          <img src="https://i.imgur.com/wXF9UMw.jpg" alt="Layout banners" />
        </li>
      </ol>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-4 max-w-xl mx-auto my-10"
      >
        <div className="flex flex-col">
          <label
            htmlFor="type"
            className="block text-sm font-medium text-gray-700"
          >
            Posição:
          </label>
          <select
            {...register("position", { required: true })}
            className="mt-1 block w-full p-2 border rounded-md"
          >
            <option value="">SELECIONE UMA POSIÇÃO</option>
            <option value="LEFT">1</option>
            <option value="RIGHT">2</option>
            <option value="BOTTOM_LEFT">3</option>
            <option value="BOTTOM_RIGHT">4</option>
          </select>
        </div>

        <div className="flex flex-col">
          <label htmlFor="title" className="mb-2 font-medium text-gray-700">
            Título:
          </label>
          <input
            {...register("title", { required: true })}
            type="text"
            className="mt-1 block w-full p-2 border rounded-md"
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="text" className="mb-2 font-medium text-gray-700">
            Descrição:
          </label>
          <input
            {...register("text", { required: true })}
            type="text"
            className="mt-1 block w-full p-2 border rounded-md"
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="href" className="mb-2 font-medium text-gray-700">
            Link:
          </label>
          <input
            {...register("href", { required: true })}
            type="text"
            className="mt-1 block w-full p-2 border rounded-md"
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="imgUrl" className="mb-2 font-medium text-gray-700">
            Url da imagem:
          </label>
          <input
            {...register("imgUrl")}
            type="text"
            className="mt-1 block w-full p-2 border rounded-md"
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="videoUrl" className="mb-2 font-medium text-gray-700">
            Url do Video:
          </label>
          <input
            {...register("videoUrl")}
            type="text"
            className="mt-1 block w-full p-2 border rounded-md"
          />
        </div>
        <button
          type="submit"
          className="w-full px-4 py-2 bg-blue-500 text-white font-medium rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          Salvar
        </button>
      </form>
    </div>
  );
};

export default Edit;
