import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const CFOPAutocomplete = ({ cfop }) => {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const filterOptions = createFilterOptions({
    matchFrom: "start", // Combina do início da string
    stringify: (option) => option.code.toString(), // Converte a opção para string para comparação
  });

  return (
    <Controller
      name="naturezaOperacao"
      control={control}
      rules={{ required: "CFOP é obrigatório" }} // Adiciona regra de campo obrigatório
      render={({ field }) => (
        <Autocomplete
          {...field}
          // Encontra o objeto de opção com base no 'code' ou retorna null se não encontrado
          value={
            cfop.find((c) => c.text === getValues("naturezaOperacao")) || null
          }
          // Atualiza o valor no react-hook-form para o 'code' da opção selecionada
          onChange={(event, item) => {
            setValue("naturezaOperacao", item ? item.text : null, {
              shouldValidate: true,
            });
            setValue("cfop", item ? item.code.toString() : "", {
              shouldValidate: true,
            });
          }}
          options={cfop}
          filterOptions={filterOptions} // Usa a função de filtro personalizada
          getOptionLabel={(option) =>
            option ? `${option.code} - ${option.text}` : ""
          }
          isOptionEqualToValue={(option, value) => option.code === value.code}
          renderInput={(params) => (
            <TextField
              {...params}
              label="CFOP"
              variant="outlined"
              error={!!errors.naturezaOperacao} // Mostra o erro se existir um erro para este campo
              helperText={
                errors.naturezaOperacao ? errors.naturezaOperacao.message : ""
              } // Exibe a mensagem de erro
            />
          )}
        />
      )}
    />
  );
};

export default CFOPAutocomplete;
