import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import Stack from "@mui/material/Stack";
import { useStateContext } from "../../contexts/ContextProvider";
import api from "../../api/api";

export const ProductItem = ({
  product,
  onDuplicate,
  onEdit,
  quickEdit,
  onDelete,
}) => {
  const { currentColor } = useStateContext();
  const {
    id,
    name,
    shortDesc,
    imgUrl,
    productCost,
    customerPrice,
    customerPriceNew,
    vendorPrice,
    vendorPriceNew,
    sku,
    type,
    width,
    height,
    length,
    weight,
    subcategories,
  } = product;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        height: "100%",
      }}
    >
      <CardHeader
        action={
          <Box>
            <IconButton
              aria-describedby={popoverId}
              onClick={handleClick}
              aria-label="settings"
            >
              <MoreVertIcon />
            </IconButton>
            <Popover
              id={popoverId}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
              <Stack direction="column" spacing={1}>
                <Typography variant="subtitle1">Ações de Edição</Typography>
                <Divider />
                <Button
                  onClick={() => {
                    quickEdit(product); // Chama a função onEdit passada pelo componente pai para abrir o Drawer de edição
                    handleClose(); // Fecha o popover
                  }}
                  startIcon={<EditIcon />}
                  fullWidth
                >
                  Edição Rápida
                </Button>
                <Button
                  onClick={() => {
                    onEdit(product); // Chama a função onEdit passada pelo componente pai para abrir o Drawer de edição
                    handleClose(); // Fecha o popover
                  }}
                  startIcon={<EditIcon />}
                  fullWidth
                >
                  Edição Completa
                </Button>
                <Typography variant="subtitle1" sx={{ mt: 2 }}>
                  Outras Ações
                </Typography>
                <Divider />
                <Button
                  onClick={() => {
                    onDuplicate(product); // Chama a função OnDuplicate passada pelo componente pai para abrir o Drawer de edição
                    handleClose(); // Fecha o popover
                  }}
                  startIcon={<EditIcon />}
                  fullWidth
                >
                  Duplicar Produto
                </Button>
                <Divider />
                <Button
                  onClick={() => onDelete(id)} // Chama a função para deletar o produto
                  startIcon={<DeleteIcon />}
                  fullWidth
                  variant="contained" // Define o estilo do botão como contido
                  color="error" // Define a cor como 'error' para destacar a ação de deletar
                  size="large" // Define o tamanho do botão
                  sx={{
                    textTransform: "none", // Remove a transformação do texto para maiúsculas
                    fontWeight: "bold", // Adiciona negrito ao texto
                    borderRadius: "8px", // Adiciona bordas arredondadas ao botão
                    "&:hover": {
                      backgroundColor: "#d32f2f", // Altera a cor de fundo ao passar o mouse sobre o botão
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Adiciona uma sombra ao botão ao passar o mouse
                    },
                  }}
                >
                  Deletar Produto
                </Button>
                <Divider />
                <Button
                  disabled
                  startIcon={<AddBusinessIcon />}
                  fullWidth
                  variant="contained" // Define o estilo do botão como contido
                  color="error" // Define a cor como 'error' para destacar a ação de deletar
                  size="large" // Define o tamanho do botão
                  sx={{
                    textTransform: "none", // Remove a transformação do texto para maiúsculas
                    fontWeight: "bold", // Adiciona negrito ao texto
                    borderRadius: "8px", // Adiciona bordas arredondadas ao botão
                    "&:hover": {
                      backgroundColor: "#ebab34", // Altera a cor de fundo ao passar o mouse sobre o botão
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Adiciona uma sombra ao botão ao passar o mouse
                    },
                  }}
                >
                  Add Merchant
                </Button>
              </Stack>
            </Popover>
          </Box>
        }
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "0 0 16px 0",
        }}
      >
        <CardMedia
          component="img"
          sx={{
            width: 150,
            height: 150,
          }}
          alt={name}
          image={imgUrl || "default-image-url"}
        />
      </Box>
      <Divider />
      <div className="p-2 flex flex-col justify-between">
        <div className="mb-4">
          <h5 className="text-lg font-semibold mb-2">{name}</h5>
          <Divider />
          <p className="text-sm text-gray-600 mb-2">{shortDesc}</p>
          <p className="text-sm mb-2">
            SKU:{" "}
            <span
              style={{ backgroundColor: currentColor }}
              className="ml-2 inline-block text-white py-1 px-3 rounded-full text-sm cursor-default  transition-colors"
            >
              {sku}
            </span>
          </p>
          <p className="text-sm mb-2">
            Dimensões: {`${width} x ${height} x ${length} cm`}
          </p>
          <p className="text-sm mb-2">Peso: {`${weight} g`}</p>
        </div>

        <div className="mb-4">
          <span className="text-sm font-semibold">Categoria:</span>
          <span
            style={{ backgroundColor: currentColor }}
            className="ml-2 inline-block text-white py-1 px-3 rounded-full text-sm cursor-default  transition-colors"
          >
            {type}
          </span>
        </div>

        {subcategories && (
          <div className="mb-4">
            <span className="text-sm font-semibold">Subcategorias:</span>
            <div className="flex flex-wrap gap-2 mt-2">
              {subcategories.map((subcategory) => (
                <span
                  key={subcategory.id}
                  style={{ backgroundColor: currentColor }}
                  className=" text-white py-1 px-3 rounded-full text-sm cursor-default transition-colors"
                >
                  {subcategory.name}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
      <Divider />
      <div className="p-2 mt-2">
        <span className="text-lg font-medium">
          Preço de Custo: R${productCost.toFixed(2)}
        </span>
      </div>
      <Divider />
      <div className="p-2 mt-2">
        <span className="text-lg font-medium">
          Preço Cliente: R${customerPrice.toFixed(2)}
        </span>
      </div>

      {customerPriceNew > 0 && (
        <>
          <Divider />
          <div className="p-2 mt-2">
            <span className="text-lg font-medium">
              Preço Cliente PROMO: R${customerPriceNew.toFixed(2)}
            </span>
          </div>
        </>
      )}
      <Divider />
      <div className="p-2 mt-2">
        <span className="text-lg font-medium">
          Preço Salão: R${vendorPrice.toFixed(2)}
        </span>
      </div>
      {vendorPriceNew > 0 && (
        <>
          <Divider />
          <div className="p-2 mt-2">
            <span className="text-lg font-medium">
              Preço Salão PROMO: R${vendorPriceNew.toFixed(2)}
            </span>
          </div>
        </>
      )}
    </Card>
  );
};
