import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  Button,
  Container,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import api from "../../api/api";

const Create = ({ setWarning, handleOpenSnackbar, onSave }) => {
  const [sellers, setSellers] = useState([]);
  const [loading, setLoading] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      docNumber: "",
      phone: "",
    },
  });
  useEffect(() => {
    const fetchSellers = async () => {
      try {
        const response = await api.get("/sellers");
        if (response.status === 200) {
          setSellers(response.data.sellers);
          setLoading(false);
        }
      } catch (error) {
        console.error("Failed to fetch sellers", error);
        setLoading(false);
      }
    };

    fetchSellers();
  }, []);
  const onSubmit = (data) => {
    api.post("/users", data).then((res) => {
      if (res.status === 200 || res.status === 201) {
        setWarning({
          type: "success",
          message: "Cliente Criado!",
        });
        handleOpenSnackbar();
        onSave();
      } else {
        setWarning({
          type: "error",
          message: "Falha ao criar o cliente!",
        });
        handleOpenSnackbar();
      }
    });
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Criar Cliente
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <TextField
          {...register("name", { required: "Nome é obrigatório" })}
          label="Nome"
          variant="outlined"
          fullWidth
          margin="normal"
          error={!!errors.name}
          helperText={errors.name?.message}
        />
        <TextField
          {...register("email", {
            required: "Email é obrigatório",
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: "Invalid email address",
            },
          })}
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          error={!!errors.email}
          helperText={errors.email?.message}
        />
        <TextField
          {...register("password", {
            required: "Senha é obrigatório",
            minLength: {
              value: 6,
              message: "A senha deve possuir ao menos 6 caracteres",
            },
          })}
          label="Senha"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          error={!!errors.password}
          helperText={errors.password?.message}
        />
        <TextField
          {...register("confirmPassword", {
            required: "Confirmação de senha é obrigatório",
            minLength: {
              value: 6,
              message:
                "A confirmação de senha deve possuir ao menos 6 caracteres",
            },
          })}
          label="Confirmar Senha"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword?.message}
        />
        <TextField
          {...register("docNumber", {
            required: "CPF/CNPJ é obrigatório",
          })}
          label="CPF/CNPJ"
          variant="outlined"
          fullWidth
          margin="normal"
          error={!!errors.docNumber}
          helperText={errors.docNumber?.message}
        />
        <TextField
          {...register("phone", {
            required: "Número de Telefone é obrigatório",
          })}
          label="Número de Telefone"
          variant="outlined"
          fullWidth
          margin="normal"
          error={!!errors.phone}
          helperText={errors.phone?.message}
        />
        <FormControl
          fullWidth
          variant="outlined"
          margin="normal"
          error={!!errors.seller}
        >
          <InputLabel>Vendedor</InputLabel>
          <Select {...register("seller")} label="Vendedor" disabled={loading}>
            {loading ? (
              <MenuItem disabled>Carregando...</MenuItem>
            ) : (
              sellers.map((seller) => (
                <MenuItem key={seller.id} value={seller.id}>
                  {seller.name}
                </MenuItem>
              ))
            )}
          </Select>
          <FormHelperText>{errors.seller?.message}</FormHelperText>
        </FormControl>
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Criar
        </Button>
      </form>
    </Container>
  );
};

export default Create;
