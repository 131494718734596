import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { formatPhoneNumber } from "../../utils/FormatPhone";

const ClientTable = ({ clients, onEdit }) => {
  return (
    <div className="flex flex-col">
      <p></p>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell align="center">Contato</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Endereço</TableCell>
              <TableCell align="center">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map((client) => (
              <TableRow key={client.id}>
                <TableCell>{client.name}</TableCell>
                <TableCell align="center">
                  {formatPhoneNumber(client.phone)}
                </TableCell>
                <TableCell align="center">{client.email}</TableCell>
                <TableCell
                  align="center"
                  width={400}
                  className="whitespace-normal break-words"
                >
                  {client.address
                    ? `${client.address}`
                    : "Sem Endereço Cadastrado"}
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => onEdit(client)}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ClientTable;
