import React from "react";
import { useForm } from "react-hook-form";
import api from "../../api/api";

export default function Create({ setOption }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const onSubmit = (data) => {
    api
      .post("banners/body", data)
      .then((response) => setOption("view"))
      .catch((error) => console.error("Houve um erro na solicitação", error));
  };

  return (
    <>
      <button
        onClick={() => setOption("view")}
        className=" hover:text-blue-700 text-blue-500 font-bold py-2 px-4 rounded transition ease-in-out duration-150"
      >
        Voltar
      </button>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-4 max-w-xl mx-auto my-10"
      >
        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Titulo
          </label>

          <input
            type="text"
            placeholder="Titulo"
            {...register("name", {
              required: "Este campo é obrigatório",
              maxLength: 100,
            })}
            className="block w-full p-2 border rounded-md"
          />
          <p className="text-xs text-gray-500">
            Nome para o banner, será exibido somente no painel administrativo
          </p>
          {errors.name && (
            <span className="text-red-500">{errors.name.message}</span>
          )}
        </div>

        <div>
          <label
            htmlFor="text"
            className="block text-sm font-medium text-gray-700"
          >
            Descrição
          </label>

          <input
            type="text"
            placeholder="text"
            {...register("text", {
              required: "Este campo é obrigatório",
              maxLength: 100,
            })}
            className="block w-full p-2 border rounded-md"
          />
          <p className="text-xs text-gray-500">
            Descrição para o banner, será exibido somente no painel
            administrativo
          </p>
          {errors.text && (
            <span className="text-red-500">{errors.text.message}</span>
          )}
        </div>

        <div>
          <label
            htmlFor="href"
            className="block text-sm font-medium text-gray-700"
          >
            Link
          </label>
          <input
            type="text"
            placeholder="Ex:
                produtos?category=categoryID"
            {...register("href")}
            className="block w-full p-2 border rounded-md"
          />
          <p className="text-xs text-gray-500">
            Caminho de redirecionamento, este categoryID pode ser retirado do
            próprio site, ou na seção de categorias.
          </p>
          {errors.href && (
            <span className="text-red-500">{errors.href.message}</span>
          )}
        </div>

        <div>
          <label
            htmlFor="imgUrl"
            className="block text-sm font-medium text-gray-700"
          >
            Imagem
          </label>
          <input
            type="text"
            placeholder="Ex: https://i.imgur.com/LIz7pxN.jpg"
            {...register("imgUrl")}
            className="block w-full p-2 border rounded-md"
          />
          <p className="text-xs text-gray-500">Link da imagem</p>
          {errors.imgUrl && (
            <span className="text-red-500">{errors.imgUrl.message}</span>
          )}
        </div>

        <button
          type="submit"
          className="px-4 py-2 bg-green-500 text-white rounded-md cursor-pointer"
        >
          Salvar
        </button>
      </form>
    </>
  );
}
