import React, { useState } from "react";
import { useForm } from "react-hook-form";
import api from "../../api/api";
import Alerts from "../Alerts";

const Create = ({ setOption, categories }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showSelect, setShowSelect] = useState(false); // Estado para controlar a visibilidade do select
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [warning, setWarning] = useState({});

  const onSubmit = async (data) => {
    // Se parentId for vazio, deleta a propriedade do objeto
    if (data.parentId === "") {
      delete data.parentId;
    }

    try {
      const response = await api
        .post("/categories", data)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            // 201 para Created
            setWarning({
              type: "success",
              message: "Faixa de Texto criada com sucesso!",
            });
            handleOpenSnackbar();
          } else {
            // Alguma outra resposta de sucesso não esperada
            setWarning({
              type: "warning",
              message:
                "Faixa de Texto criada, mas com respostas inesperadas do servidor.",
            });
            handleOpenSnackbar();
          }
        })
        .catch((error) => {});
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  // Função para renderizar categorias e subcategorias de forma recursiva
  const renderOptions = (categories, prefix = "") => {
    return categories.map((category) => [
      <option key={category.id} value={category.id}>
        {prefix + category.name}
      </option>,
      // Se existirem children, chama a função recursivamente com um prefixo adicional
      category.children && renderOptions(category.children, prefix + "--"),
    ]);
  };

  // Toggle da visibilidade do select
  const toggleSelectVisibility = () => {
    setShowSelect(!showSelect);
  };

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <div className="flex flex-col">
      <button
        type="button"
        className="mb-4 self-start inline-block bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
        onClick={() => setOption("")}
      >
        Voltar
      </button>
      <div className="max-w-xl w-full self-center">
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div>
            <input
              type="text"
              placeholder="Nome da categoria"
              {...register("name", { required: true })}
              className="form-input mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
            {errors.name && (
              <p className="text-red-500 text-xs italic">Nome é obrigatório.</p>
            )}
          </div>

          {/* Checkbox para controlar a visibilidade do select */}
          <div className="mb-4">
            <label
              htmlFor="toggleSelect"
              className="flex items-center space-x-2"
            >
              <input
                type="checkbox"
                id="toggleSelect"
                className="form-checkbox"
                onChange={toggleSelectVisibility}
              />
              <span>Vincular a uma categoria existente</span>
            </label>
          </div>

          {/* Renderização condicional do select baseada no estado do checkbox */}
          {showSelect && (
            <div className="mb-4">
              <label
                htmlFor="parentId"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Categoria/Subcategoria
              </label>
              <select
                id="parentId"
                {...register("parentId")}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base leading-6 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-200 focus:border-blue-300 focus:ring-opacity-50 focus:rounded-md"
              >
                <option value="">Selecione uma opção</option>
                {renderOptions(categories)}
              </select>
            </div>
          )}
          <div>
            <input
              type="submit"
              value="Criar"
              className="inline-flex items-center justify-center w-full mt-4 px-6 py-3 text-base font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            />
          </div>
        </form>
      </div>
      <Alerts
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        type={warning.type}
        message={warning.message}
      />
    </div>
  );
};

export default Create;
