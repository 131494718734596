import React, { useEffect, useState } from "react";
import api from "../api/api";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import Header from "../components/Header";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";

dayjs.extend(duration);

const calculateTimeLeft = (dueDate) => {
  const now = dayjs();
  const expirationDate = dayjs(dueDate);
  const diff = expirationDate.diff(now);

  return dayjs.duration(diff);
};

function Promotions() {
  const [produtos, setProdutos] = useState([]);

  useEffect(() => {
    try {
      api
        .get("products?customerPriceNewMin=1&customerPriceNewMax=9999999999")
        .then((res) => {
          if (res.status === 200) {
            setProdutos(res.data.products);
            console.log(res.data.products);
          }
        });
    } catch (error) {}
  }, []);

  // Função para verificar se uma promoção está ativa
  const isPromotionActive = (dueDate) => {
    return dueDate === null || dayjs(dueDate).isAfter(dayjs());
  };

  // Divida as promoções em ativas e encerradas
  const activePromotions = produtos.filter(
    (product) =>
      isPromotionActive(product.discountCustomerDueDate) ||
      isPromotionActive(product.discountVendorDueDate)
  );

  const expiredPromotions = produtos.filter(
    (product) =>
      !isPromotionActive(product.discountCustomerDueDate) &&
      !isPromotionActive(product.discountVendorDueDate)
  );

  return (
    <div className="m-4 md:m-8 mt-24 p-4 md:p-8 bg-white rounded-lg shadow-lg">
      <Header category="Promoções" title="Produtos em Promoção" />
      <Accordion
        defaultExpanded
        sx={{ border: "1px solid #ddd", borderRadius: 1 }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ backgroundColor: "#f5f5f5" }}
        >
          <Typography variant="h6">Promoções Ativas</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {activePromotions.map((product) => (
              <Grid item key={product.id} xs={12} sm={6} md={4}>
                <PromotionProductCard product={product} />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ border: "1px solid #ddd", borderRadius: 1 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          sx={{ backgroundColor: "#f5f5f5" }}
        >
          <Typography variant="h6">Promoções Encerradas</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {expiredPromotions.map((product) => (
              <Grid item key={product.id} xs={12} sm={6} md={4}>
                <PromotionProductCard product={product} />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default Promotions;

const PromotionProductCard = ({ product }) => {
  const [customerTimeLeft, setCustomerTimeLeft] = useState(
    product.discountCustomerDueDate
      ? calculateTimeLeft(product.discountCustomerDueDate)
      : null
  );
  const [vendorTimeLeft, setVendorTimeLeft] = useState(
    product.discountVendorDueDate
      ? calculateTimeLeft(product.discountVendorDueDate)
      : null
  );

  useEffect(() => {
    if (product.discountCustomerDueDate || product.discountVendorDueDate) {
      const timer = setInterval(() => {
        setCustomerTimeLeft(
          product.discountCustomerDueDate
            ? calculateTimeLeft(product.discountCustomerDueDate)
            : null
        );
        setVendorTimeLeft(
          product.discountVendorDueDate
            ? calculateTimeLeft(product.discountVendorDueDate)
            : null
        );
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [product.discountCustomerDueDate, product.discountVendorDueDate]);

  const formatTimeLeft = (duration) => {
    if (!duration || duration.asSeconds() <= 0) return null;

    const parts = [];
    if (duration.months()) parts.push(`${duration.months()}M`);
    if (duration.weeks()) parts.push(`${duration.weeks()}S`);
    if (duration.days()) parts.push(`${duration.days()}D`);
    if (duration.hours()) parts.push(`${duration.hours()}H`);
    if (duration.minutes()) parts.push(`${duration.minutes()}m`);
    if (duration.seconds()) parts.push(`${duration.seconds()}s`);
    return parts.join(" ");
  };

  const isExpired = (duration) => duration && duration.asSeconds() <= 0;

  const {
    name,
    imgUrl,
    customerPrice,
    customerPriceNew,
    vendorPrice,
    vendorPriceNew,
  } = product;

  const formatCurrency = (value) => {
    const numberValue = Number(value);
    if (isNaN(numberValue)) {
      return "R$ 0,00";
    }
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(numberValue);
  };

  return (
    <div className="max-w-sm mx-auto my-4 p-4 bg-white rounded-lg shadow-lg">
      <div className="flex justify-center">
        <img
          className="w-full h-full object-cover rounded-t-lg"
          src={imgUrl[0]}
          alt={name}
        />
      </div>
      <div className="p-4">
        <h2 className="text-xl font-semibold mb-2">{name}</h2>
        {customerPrice > 0 && (
          <p className="text-gray-700 mb-2">
            Preço Cliente: {formatCurrency(customerPrice)}
          </p>
        )}
        {customerPriceNew > 0 && (
          <p className="text-gray-700 mb-2">
            Preço Cliente Promocional: {formatCurrency(customerPriceNew)}
          </p>
        )}
        {vendorPrice > 0 && (
          <p className="text-gray-700 mb-2">
            Preço Vendedor Original: {formatCurrency(vendorPrice)}
          </p>
        )}
        {vendorPriceNew > 0 && (
          <p className="text-gray-700 mb-2">
            Preço Vendedor Promocional: {formatCurrency(vendorPriceNew)}
          </p>
        )}
        <p className="text-gray-700 mb-2">
          Desconto Cliente expira em:{" "}
          <span className="text-red-600">
            {product.discountCustomerDueDate
              ? dayjs(product.discountCustomerDueDate).format("DD/MM/YYYY")
              : "Sem data de expiração"}
          </span>
        </p>
        <p className="text-gray-700 mb-2">
          Desconto Vendedor expira em:{" "}
          <span className="text-red-600">
            {product.discountVendorDueDate
              ? dayjs(product.discountVendorDueDate).format("DD/MM/YYYY")
              : "Sem data de expiração"}
          </span>
        </p>
        <div className="mt-4">
          {customerTimeLeft && !isExpired(customerTimeLeft) && (
            <p className="text-red-600">
              {`Cliente - Expira em: ${formatTimeLeft(customerTimeLeft)}`}
            </p>
          )}
          {vendorTimeLeft && !isExpired(vendorTimeLeft) && (
            <p className="text-red-600">
              {`Vendedor - Expira em: ${formatTimeLeft(vendorTimeLeft)}`}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
