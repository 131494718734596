import React, { useState, useEffect } from "react";
import api from "../../api/api";
import TransferList from "../TransferList";
import { useForm } from "react-hook-form";

const Create = ({ setCards, setWarning, handleOpenSnackbar }) => {
  const [allProducts, setAllProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const res = await api.get("/products?take=300");
        const fetchedProducts = res.data.products;

        // Define os produtos selecionados
        setSelectedProducts([]);
        setAllProducts(fetchedProducts);
      } catch (error) {
        console.error("Erro ao buscar produtos:", error);
        // Aqui você pode adicionar qualquer lógica adicional para tratar o erro,
        // como atualizar o estado para mostrar uma mensagem de erro na UI.
        setWarning({
          type: "error",
          message: "Falha ao carregar produtos!",
        });
        handleOpenSnackbar();
      }
    };

    fetchProducts();
  }, []); // Dependências do useEffect. Se 'item' for uma prop que pode mudar, considere adicioná-la aqui.

  const onSubmit = (formData) => {
    // Constrói o objeto esperado pela API
    const payload = {
      ...formData,
      productIDs: selectedProducts.map((product) => product.id),
    };

    api
      .post("/products/featured", payload)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          // 201 para Created
          setWarning({
            type: "success",
            message: "Card criado com sucesso!",
          });
          handleOpenSnackbar();
          setCards("");
        } else {
          // Alguma outra resposta de sucesso não esperada
          setWarning({
            type: "warning",
            message: "Card criado, mas com respostas inesperadas do servidor.",
          });
          handleOpenSnackbar();
          setCards("");
        }
      })
      .catch((error) => {
        let errorMessage = "Falha ao salvar o card no servidor!";
        if (error.response) {
          // Adiciona detalhes específicos do erro se disponível
          errorMessage += ` Detalhes: ${
            error.response.data.message || error.response.status
          }`;
        } else if (error.request) {
          // A requisição foi feita mas não houve resposta

          errorMessage = "O servidor não respondeu ao pedido.";
        } else {
          // Algum erro aconteceu ao montar o pedido

          errorMessage = "Erro ao enviar o pedido para o servidor.";
        }
        setWarning({
          type: "error",
          message: errorMessage,
        });
        handleOpenSnackbar();
      });
  };

  return (
    <div className=" mx-auto p-5 ">
      <h1 className="text-2xl font-bold mb-5 text-gray-800">
        Selecione os Produtos em Destaque
      </h1>

      <p className="text-sm text-gray-600 mb-4">
        Algumas regras para o card de produtos em Destaque:
      </p>
      <ol className="list-decimal pl-5 space-y-2">
        <li className="text-sm text-gray-600">
          O limite máximo de produtos em Destaque é 4.
        </li>
        <li className="text-sm text-gray-600">
          Verifique com cuidado o nome do item a ser exibido.
        </li>
      </ol>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-4 max-w-xl mx-auto my-10"
      >
        <div className="flex flex-col">
          <label
            htmlFor="position"
            className="block text-sm font-medium text-gray-700"
          >
            Posição:
          </label>
          <select
            {...register("position", { required: true })}
            className="mt-1 block w-full p-2 border rounded-md"
          >
            <option value="">SELECIONE UMA OPÇÂO</option>
            <option value="TOP">TOPO</option>
            <option value="MID">MEIO</option>
            <option value="REVIEW">DESTAQUES</option>
            <option value="BOTTOM">RODAPE</option>
            <option value="CHECKOUT">CARRINHO</option>
          </select>
        </div>

        <div className="flex flex-col">
          <label
            htmlFor="type"
            className="block text-sm font-medium text-gray-700"
          >
            Tipo:
          </label>
          <select
            {...register("type", { required: true })}
            className="mt-1 block w-full p-2 border rounded-md"
          >
            <option value="">SELECIONE UMA OPÇÂO</option>
            <option value="FOR_YOU">Para você</option>
            <option value="FOR_BUSINESS">Para salão</option>
          </select>
        </div>

        <div className="flex flex-col">
          <label htmlFor="title" className="mb-2 font-medium text-gray-700">
            Título:
          </label>
          <input
            {...register("title", { required: true })}
            type="text"
            className="mt-1 block w-full p-2 border rounded-md"
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="href" className="mb-2 font-medium text-gray-700">
            Link:
          </label>
          <input
            {...register("href")}
            type="text"
            className="mt-1 block w-full p-2 border rounded-md"
          />
        </div>

        <TransferList
          items={allProducts}
          selectedItems={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          setAllProducts={setAllProducts}
        />

        <button
          type="submit"
          className="w-full px-4 py-2 bg-blue-500 text-white font-medium rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          Salvar
        </button>
      </form>
    </div>
  );
};

export default Create;
