import React from "react";
import { Header } from "../components";

const MelhorEnvio = () => {
  const redirectToExternalUrl = () => {
    window.location.href =
      "https://www.melhorenvio.com.br/oauth/authorize?client_id=15108&redirect_uri=https://api.zpcosmeticos.com.br/users/me/callback&response_type=code&scope=cart-read%20cart-write%20shipping-calculate%20shipping-checkout%20shipping-generate%20shipping-tracking%20shipping-print%20shipping-preview%20orders-read%20notifications-read%20ecommerce-shipping";
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Melhor Envio" title="Gerar Token" />
      <button
        onClick={redirectToExternalUrl}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Gerar Token
      </button>
    </div>
  );
};

export default MelhorEnvio;
