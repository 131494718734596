import React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import axios from "axios"; // Importando Axios
import api from "../../api/api";

export default function Create({ setOption }) {
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      carousel: [{ href: "", imgUrl: "" }],
      type: "STANDARD", // Valor padrão para o tipo, se necessário
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "carousel",
  });

  const type = watch("type"); // Assistindo o campo 'type' para mudanças

  const onSubmit = (data) => {
    api
      .post("banners", data)
      .then((response) => setOption("view"))
      .catch((error) => console.error("Houve um erro na solicitação", error));
  };

  return (
    <>
      <button
        onClick={() => setOption("view")}
        className=" hover:text-blue-700 text-blue-500 font-bold py-2 px-4 rounded transition ease-in-out duration-150"
      >
        Voltar
      </button>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-4 max-w-xl mx-auto my-10"
      >
        <div>
          <label
            htmlFor="type"
            className="block text-sm font-medium text-gray-700"
          >
            Tipo
          </label>
          <select
            {...register("type", { required: "Este campo é obrigatório" })}
            className="mt-1 block w-full p-2 border rounded-md"
          >
            <option value="STANDARD">STANDARD</option>
            <option value="CAROUSEL">CAROUSEL</option>
          </select>
          {errors.type && (
            <span className="text-red-500">{errors.type.message}</span>
          )}
          <p className="text-xs text-gray-500">Escolha o tipo de conteúdo.</p>
        </div>

        {type === "CAROUSEL" && (
          <div className="mb-4 mt-4">
            <span className="text-md text-center mb-4">
              Imagens do Carrosel
            </span>
            {fields.map((field, index) => (
              <div key={field.id} className="flex space-x-2 items-top px-4">
                <div className="flex-1">
                  <label
                    htmlFor={`carousel[${index}].href`}
                    className="block text-sm font-medium text-gray-700"
                  >
                    Href
                  </label>
                  <input
                    type="text"
                    placeholder="Ex:
                    produtos?category=categoryID"
                    {...register(`carousel[${index}].href`)}
                    className="mt-1 block w-full p-2 border rounded-md"
                  />
                  <p className="text-xs text-gray-500">
                    Caminho de redirecionamento, este categoryID pode ser
                    retirado do próprio site, ou na seção de categorias.
                  </p>
                </div>
                <div className="flex-1">
                  <label
                    htmlFor={`carousel[${index}].imgUrl`}
                    className="block text-sm font-medium text-gray-700"
                  >
                    Imagem URL
                  </label>
                  <input
                    type="text"
                    placeholder="URL da imagem"
                    {...register(`carousel[${index}].imgUrl`)}
                    className="mt-1 block w-full p-2 border rounded-md"
                  />
                  <p className="text-xs text-gray-500">
                    Insira a URL da imagem.
                  </p>
                </div>
                <button
                  type="button"
                  onClick={() => remove(index)}
                  className="px-4 bg-red-500 text-white rounded-md h-8 text-center mt-4"
                >
                  Remover
                </button>
              </div>
            ))}
            <div className="px-4">
              <button
                type="button"
                onClick={() => append({ href: "", imgUrl: "" })}
                className="px-4 py-2 bg-blue-500 text-white rounded-md"
              >
                Adicionar mais
              </button>
            </div>
          </div>
        )}

        {type !== "CAROUSEL" && (
          <div>
            <label
              htmlFor="position"
              className="block text-sm font-medium text-gray-700"
            >
              Posição do Banner
            </label>
            <select
              {...register("position", {
                required: "Este campo é obrigatório",
              })}
              className="block w-full p-2 border rounded-md"
            >
              <option value="TOP">TOP</option>
              <option value="MID">MID</option>
              <option value="BOTTOM">BOTTOM</option>
            </select>{" "}
            <p className="text-xs text-gray-500">
              Posições dos banners na Home
            </p>
            {errors.position && (
              <span className="text-red-500">{errors.position.message}</span>
            )}
          </div>
        )}

        <div>
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700"
          >
            Titulo
          </label>

          <input
            type="text"
            placeholder="Titulo"
            {...register("title", {
              required: "Este campo é obrigatório",
              maxLength: 100,
            })}
            className="block w-full p-2 border rounded-md"
          />
          <p className="text-xs text-gray-500">
            Titulo para o banner, será exibido somente no painel administrativo
          </p>
          {errors.title && (
            <span className="text-red-500">{errors.title.message}</span>
          )}
        </div>

        <div>
          <label
            htmlFor="text"
            className="block text-sm font-medium text-gray-700"
          >
            Descrição
          </label>
          <input
            type="text"
            placeholder="Descrição..."
            {...register("text", { required: "Este campo é obrigatório" })}
            className="block w-full p-2 border rounded-md"
          />
          <p className="text-xs text-gray-500">
            Descrição para o banner, será exibido somente no painel
            administrativo
          </p>
          {errors.text && (
            <span className="text-red-500">{errors.text.message}</span>
          )}
        </div>
        {type !== "CAROUSEL" && (
          <>
            <div>
              <label
                htmlFor="text"
                className="block text-sm font-medium text-gray-700"
              >
                Link
              </label>
              <input
                type="text"
                placeholder="Ex:
                produtos?category=categoryID"
                {...register("href")}
                className="block w-full p-2 border rounded-md"
              />
              <p className="text-xs text-gray-500">
                Caminho de redirecionamento, este categoryID pode ser retirado
                do próprio site, ou na seção de categorias.
              </p>
              {errors.href && (
                <span className="text-red-500">{errors.href.message}</span>
              )}
            </div>
            <div>
              <label
                htmlFor="imgUrl"
                className="block text-sm font-medium text-gray-700"
              >
                Imagem
              </label>
              <input
                type="text"
                placeholder="Ex: https://i.imgur.com/LIz7pxN.jpg"
                {...register("imgUrl")}
                className="block w-full p-2 border rounded-md"
              />
              <p className="text-xs text-gray-500">Link da imagem</p>
              {errors.imgUrl && (
                <span className="text-red-500">{errors.imgUrl.message}</span>
              )}
            </div>
            <div>
              <label
                htmlFor="videoUrl"
                className="block text-sm font-medium text-gray-700"
              >
                Video
              </label>
              <input
                type="text"
                placeholder="Ex: https://www.youtube.com/shorts/mFYAYooJRI4"
                {...register("videoUrl")}
                className="block w-full p-2 border rounded-md"
              />
              <p className="text-xs text-gray-500">Link do video</p>
              {errors.videoUrl && (
                <span className="text-red-500">{errors.videoUrl.message}</span>
              )}
            </div>
          </>
        )}

        <button
          type="submit"
          className="px-4 py-2 bg-green-500 text-white rounded-md cursor-pointer"
        >
          Salvar
        </button>
      </form>
    </>
  );
}
