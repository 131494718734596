import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useForm, Controller } from "react-hook-form";
import api from "../../api/api";

const EditClient = ({ client, onSave, onClose }) => {
  const [sellers, setSellers] = useState([]);
  const [loading, setLoading] = useState(true);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: client.name || "",
      email: client.email || "",
      phone: client.phone || "",
      docNumber: client.docNumber || "",
      seller: client.seller || "",
      billingAddress: client.billingAddress || {},
      shippingAddress: client.shippingAddress || [],
    },
  });

  // Fetch sellers from API
  useEffect(() => {
    const fetchSellers = async () => {
      try {
        const response = await api.get("/sellers");
        if (response.status === 200) {
          setSellers(response.data.sellers);
        }
      } catch (error) {
        console.error("Failed to fetch sellers", error);
      } finally {
        setLoading(false);
      }
    };
    fetchSellers();
  }, []);

  // Update form values when client prop changes
  useEffect(() => {
    if (client) {
      setValue("name", client.name || "");
      setValue("email", client.email || "");
      setValue("phone", client.phone || "");
      setValue("docNumber", client.docNumber || "");
      setValue("seller", client.seller || "");
      setValue("billingAddress", client.billingAddress || {});
      setValue("shippingAddress", client.shippingAddress || []);
    }
  }, [client, setValue]);

  const onSubmit = async (data) => {
    try {
      await onSave(client.id, data);
      onClose();
    } catch (error) {
      console.error("Failed to update client", error);
    }
  };

  const formatBillingAddress = () => {
    const billingAddress = getValues("billingAddress");
    if (!billingAddress || !billingAddress.street) {
      return "Endereço de Cobrança não cadastrado";
    }
    return `${billingAddress.street || ""} - Nº${
      billingAddress.number || ""
    }, ${billingAddress.neighborhood || ""}, ${billingAddress.city || ""}/${
      billingAddress.state || ""
    } - ${billingAddress.country || ""} - ${billingAddress.zipCode || ""}`;
  };
  return (
    <div style={{ padding: 20 }}>
      <Typography variant="h5" gutterBottom>
        Editar Cliente
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          {/* Campos Básicos */}
          <Grid item xs={12} sm={6}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField {...field} label="Nome" fullWidth />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField {...field} label="Email" fullWidth />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <TextField {...field} label="Telefone" fullWidth />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="docNumber"
              control={control}
              render={({ field }) => (
                <TextField {...field} label="Número do Documento" fullWidth />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl
              fullWidth
              variant="outlined"
              margin="none"
              error={!!errors.seller}
            >
              <InputLabel>Vendedor</InputLabel>
              <Controller
                name="seller"
                control={control}
                render={({ field }) => (
                  <Select {...field} label="Vendedor" disabled={loading}>
                    {loading ? (
                      <MenuItem disabled>Carregando...</MenuItem>
                    ) : (
                      sellers.map((seller) => (
                        <MenuItem key={seller.id} value={seller.id}>
                          {seller.name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                )}
              />
              <FormHelperText>{errors.seller?.message}</FormHelperText>
            </FormControl>
          </Grid>

          {/* Endereço de Cobrança */}
          <Grid item xs={12}>
            <TextField
              label="Endereço de Cobrança"
              fullWidth
              margin="normal"
              disabled
              value={formatBillingAddress()}
            />
          </Grid>

          {/* Adicione outros campos do endereço de cobrança aqui */}

          {/* Endereços de Envio */}
          <Grid item xs={12}>
            {getValues("shippingAddress") &&
            getValues("shippingAddress").length > 0 ? (
              getValues("shippingAddress").map((address, index) => (
                <Accordion key={address.id || index} sx={{ mb: 1 }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">
                      Endereço de Envio {index + 1}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {`${address.street || ""} - Nº${address.number || ""}, ${
                        address.neighborhood || ""
                      }, ${address.city || ""}/${address.state || ""} - ${
                        address.country || ""
                      } - CEP: ${address.zipCode || ""}`}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <Typography>Nenhum Endereço de Envio Cadastrado</Typography>
            )}
          </Grid>
        </Grid>
        <div style={{ marginTop: 20 }}>
          <Button
            onClick={onClose}
            variant="outlined"
            style={{ marginRight: 10 }}
          >
            Cancelar
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditClient;
