import React, { useState, useEffect } from "react";
import api from "../../api/api";
import {
  FormProvider,
  useForm,
  useFieldArray,
  Controller,
} from "react-hook-form";
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  IconButton,
  TextField,
  InputAdornment,
  Typography,
  Divider,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import AutoCompleteCategory from "../Categorias/AutoCompleteCategory";
import dayjs from "dayjs";
import "dayjs/locale/pt-br"; // Importa o locale pt-br do dayjs

// Placeholder para função de tradução
const t = (key) => key;

export const QuickCreateProduct = ({
  visible,
  onClose,
  onSave,
  setWarning,
  handleOpenSnackbar,
}) => {
  const methods = useForm({
    defaultValues: {
      name: "",
      shortDesc: "",
      description: [],
      type: "",
      forYou: false,
      width: 0,
      height: 0,
      length: 0,
      weight: 0,
      customerPrice: 0,
      customerPriceNew: 0,
      discountCustomerDueDate: "",
      vendorPrice: 0,
      vendorPriceNew: 0,
      discountVendorDueDate: "",
      productCost: 0,
      imgUrl: [],
      categoryIds: [],
    },
    mode: "all", // Adiciona esta linha para garantir a validação contínua dos campos
  });

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isValid },
  } = methods;
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubCategories] = useState([]);

  // Use `useFieldArray` para gerenciar URLs de imagens
  const {
    fields: imageFields,
    append: appendImage,
    remove: removeImage,
  } = useFieldArray({
    control,
    name: "imgUrl",
  });

  // Função para adicionar uma nova URL de imagem, com limite de 5
  const addImage = () => {
    if (imageFields.length < 5) {
      appendImage("");
    }
  };
  useEffect(() => {
    api
      .get("/categories/nested")
      .then((res) => {
        const response = res.data.categories;
        setCategories(response);
        let sub = [];
        response.forEach((category) => {
          if (category.subcategories) {
            sub.push(...category.subcategories);
          }
        });
        setSubCategories(sub);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onSubmit = (data) => {
    const formattedData = {
      ...data,
      discountCustomerDueDate: dayjs(
        data.discountCustomerDueDate
      ).toISOString(),
      discountVendorDueDate: dayjs(data.discountVendorDueDate).toISOString(),
    };
    api
      .post("/products", formattedData)
      .then((response) => {
        if (response.status === 201) {
          setWarning({
            type: "success",
            message: "Produto Editado",
          });
          handleOpenSnackbar();
          onSave();
        }
      })
      .catch((error) => {
        const issues = error.response.data.issues;
        setWarning({
          type: "error",
          message:
            "Houve uma falha ao tentar salvar o Produto, Tente Novamente Mais Tarde",
        });
        handleOpenSnackbar();
      });
  };

  // Função de validação para garantir que o valor seja maior ou igual a zero
  const validatePositive = (value) =>
    parseFloat(value) >= 0 || "O valor deve ser maior ou igual a zero";

  return (
    <Drawer
      open={visible}
      onClose={onClose}
      anchor="right"
      sx={{ "& .MuiDrawer-paper": { width: "100%", maxWidth: 500 } }}
    >
      <Box sx={{ p: 3 }}>
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <CloseOutlined />
        </IconButton>
        <Typography variant="h6" sx={{ mb: 3 }}>
          Cadastrar Novo Produto
        </Typography>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              label={t("Nome do Produto")}
              {...register("name", {
                required: "Nome é um campo Obrigatório",
              })}
              error={!!errors.name}
              helperText={errors.name?.message}
              fullWidth
              margin="normal"
            />
            <Divider />
            <TextField
              label={t("Descrição Curta")}
              {...register("shortDesc", {
                required: "Descrição Curta é um campo Obrigatório",
              })}
              multiline
              rows={2}
              fullWidth
              margin="normal"
            />
            <Divider />
            <div>
              <InputLabel id="type-label">Tipo</InputLabel>
              <Controller
                name="type"
                control={control}
                rules={{ required: "Tipo é um campo Obrigatório" }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Select
                    labelId="type-label"
                    label="Tipo"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                  >
                    <MenuItem value="SEM_TIPO">Selecione uma Opção</MenuItem>
                    <MenuItem value="ACESSORIOS">Acessórios</MenuItem>
                    <MenuItem value="CONDICIONADOR">Condicionador</MenuItem>
                    <MenuItem value="FINALIZADORES_E_OLEOS">
                      Finalizadores e Óleos
                    </MenuItem>
                    <MenuItem value="MASCARA">Máscara</MenuItem>
                    <MenuItem value="QUIMICAS">Químicas</MenuItem>
                    <MenuItem value="SALAO">Salão</MenuItem>
                    <MenuItem value="SHAMPOO">Shampoo</MenuItem>
                    <MenuItem value="TRATAMENTOS">Tratamentos</MenuItem>
                    <MenuItem value="KIT">Kit</MenuItem>
                  </Select>
                )}
              />
            </div>
            <FormControlLabel
              control={<Checkbox {...register("forYou")} />}
              label={t("For You")}
              margin="normal"
            />
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <TextField
                label="Largura"
                {...register("width", {
                  valueAsNumber: true,
                  validate: validatePositive,
                })}
                type="number"
                sx={{ mb: "1rem", maxWidth: "32%" }}
              />

              <TextField
                label="Altura"
                {...register("height", {
                  valueAsNumber: true,
                  validate: validatePositive,
                })}
                type="number"
                sx={{ mb: "1rem", maxWidth: "32%" }}
              />

              <TextField
                label="Comprimento"
                {...register("length", {
                  valueAsNumber: true,
                  validate: validatePositive,
                })}
                sx={{ maxWidth: "32%" }}
                type="number"
              />
            </Box>
            <TextField
              label="Peso"
              type="number"
              {...register("weight", {
                valueAsNumber: true,
                validate: validatePositive,
              })}
              fullWidth
              margin="normal"
              inputProps={{ step: "any" }}
            />
            <Divider />{" "}
            <TextField
              label="Preço de Custo"
              {...register("productCost", {
                required: "Preço para salão é obrigatório",
                valueAsNumber: true,
                validate: (value) =>
                  value >= 0 || "O preço não pode ser menor que zero",
              })}
              type="number"
              inputProps={{
                step: "any", // This allows for decimal numbers
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
              error={!!errors.productCost}
              helperText={errors.productCost?.message}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Preço Cliente"
              {...register("customerPrice", {
                required: "Preço é obrigatório",
                valueAsNumber: true,
                validate: (value) =>
                  value >= 0 || "O preço não pode ser menor que zero",
              })}
              type="number"
              inputProps={{
                step: "any", // This allows for decimal numbers
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
              error={!!errors.customerPrice}
              helperText={errors.customerPrice?.message}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Preço Cliente Promocional"
              {...register("customerPriceNew", {
                required: "Preço é obrigatório",
                valueAsNumber: true,
                validate: (value) =>
                  value >= 0 || "O preço não pode ser menor que zero",
              })}
              type="number"
              inputProps={{
                step: "any", // This allows for decimal numbers
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
              error={!!errors.customerPriceNew}
              helperText={errors.customerPriceNew?.message}
              fullWidth
              margin="normal"
            />
            <Controller
              name="discountCustomerDueDate"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Vencimento Promoção Cliente"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              )}
            />
            <TextField
              label="Preço Salão"
              {...register("vendorPrice", {
                required: "Preço é obrigatório",
                valueAsNumber: true,
                validate: (value) =>
                  value >= 0 || "O preço não pode ser menor que zero",
              })}
              type="number"
              inputProps={{
                step: "any", // This allows for decimal numbers
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
              error={!!errors.vendorPrice}
              helperText={errors.vendorPrice?.message}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Preço Salão Promocional"
              {...register("vendorPriceNew", {
                required: "Preço para salão é obrigatório",
                valueAsNumber: true,
                validate: (value) =>
                  value >= 0 || "O preço não pode ser menor que zero",
              })}
              type="number"
              inputProps={{
                step: "any", // This allows for decimal numbers
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
              error={!!errors.vendorPriceNew}
              helperText={errors.vendorPriceNew?.message}
              fullWidth
              margin="normal"
            />
            <Controller
              name="discountVendorDueDate"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Vencimento Promoção Salão"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              )}
            />
            <Divider />
            <Typography variant="h6" sx={{ mb: 2 }}>
              Imagens do Produto
            </Typography>
            {imageFields.map((field, index) => (
              <Box
                key={field.id}
                sx={{ display: "flex", alignItems: "center", mb: 2 }}
              >
                <TextField
                  label={`URL da Imagem ${index + 1}`}
                  {...register(`imgUrl.${index}`, {
                    required: "URL da imagem é obrigatória",
                  })}
                  fullWidth
                  margin="normal"
                />
                <IconButton onClick={() => removeImage(index)}>
                  <CloseOutlined />
                </IconButton>
              </Box>
            ))}
            {imageFields.length < 5 && (
              <Button onClick={addImage} variant="contained" sx={{ mb: 2 }}>
                Adicionar Imagem
              </Button>
            )}
            <Divider />
            <Typography variant="h6" sx={{ mt: 1 }}>
              Fiscal
            </Typography>
            <TextField
              label={"NCM"}
              {...register("ncm", {
                required: "NCM é um campo Obrigatório",
              })}
              fullWidth
              margin="normal"
            />
            <TextField
              label={"CEST"}
              {...register("cest", {
                required: "CEST é um campo Obrigatório",
              })}
              fullWidth
              margin="normal"
            />
            <Divider />
            <Controller
              name="categoryIds"
              control={control}
              render={({ field }) => (
                <AutoCompleteCategory
                  categories={categories}
                  fullWidth
                  margin="normal"
                />
              )}
            />
            <Divider />
            <div className="flex flex-col">
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 2 }}
                disabled={!isValid} // O botão será desabilitado se o formulário não for válido
              >
                Salvar
              </Button>
              {!isValid ? (
                <p className=" text-red-600">
                  Preencha todos os campos antes de salvar!
                </p>
              ) : null}
            </div>
          </form>
        </FormProvider>
      </Box>
    </Drawer>
  );
};
