import React, { useEffect, useState } from "react";
import api from "../../api/api";
import Create from "./Create";
import Edit from "./Edit";
import Alerts from "../Alerts";

const View = () => {
  const [cards, setCards] = useState("");
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [item, setItem] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [warning, setWarning] = useState({});

  useEffect(() => {
    // A função só será executada quando `cards` for uma string vazia.
    if (cards !== "") return;
    const fetchFeaturedProducts = async () => {
      try {
        const response = await api.get("/categoriesFeatured");
        setFeaturedProducts(response.data.categoryFeaturedProduct);
      } catch (error) {
        console.error("Erro ao buscar produtos destacados:", error); // É bom manter o log de erros
        setWarning({
          type: "error",
          message: "Falha ao carregar os cards do servidor!",
        });
        handleOpenSnackbar();
      }
    };
    const fetchCategories = async () => {
      try {
        const response = await api.get("/categories/nested");
        setCategories(response.data.categories);
      } catch (error) {
        console.error("Erro ao buscar produtos destacados:", error); // É bom manter o log de erros
        setWarning({
          type: "error",
          message: "Falha ao carregar os cards do servidor!",
        });
        handleOpenSnackbar();
      }
    };

    fetchCategories();
    fetchFeaturedProducts();
  }, [cards]); // Dependência `cards` para re-executar o efeito quando seu valor mudar.

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <div className="p-4">
      <div className="flex justify-between mb-4">
        <button
          onClick={() => {
            cards === "" ? setCards("create") : setCards("");
          }}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition-colors"
        >
          {cards === "" ? "Criar Destaque" : "Voltar"}
        </button>
      </div>
      {cards === "create" ? (
        <Create
          setWarning={setWarning}
          handleOpenSnackbar={handleOpenSnackbar}
          setCards={setCards}
        />
      ) : cards === "edit" ? (
        <Edit
          setWarning={setWarning}
          handleOpenSnackbar={handleOpenSnackbar}
          item={item}
          setCards={setCards}
        />
      ) : featuredProducts.length > 0 && categories.length > 0 ? (
        <div className="space-y-6">
          {featuredProducts.map((featured, index) => {
            const category = categories.find(
              (c) => c.id === featured.categoryId
            );
            console.log("Category:", category);
            return (
              <div
                key={index}
                className="bg-white border rounded-lg overflow-hidden "
              >
                <div className="flex justify-between p-5 border-b border-gray-200">
                  <div>
                    <h2 className="text-xl font-bold text-black">
                      Categoria:{" "}
                      {category
                        ? category.name
                        : "Categoria Não Encontrada, Verifique o ID"}
                    </h2>
                  </div>
                  <button
                    onClick={() => {
                      setCards("edit");
                      setItem(featured);
                    }}
                    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 transition-colors"
                  >
                    Editar Card
                  </button>
                </div>
                <div className="flex justify-evenly items-stretch p-1 ">
                  {featured.viewProducts?.map((product) => (
                    <div
                      key={product.name}
                      className="group relative text-base sm:text-sm"
                    >
                      <div className="aspect-w-1 aspect-h-1 overflow-hidden flex justify-center rounded-lg bg-gray-50">
                        <img
                          src={product.imgSrc}
                          alt={product.imgAlt}
                          className="object-cover object-center h-56 w-56"
                        />
                      </div>
                      <a
                        href={product.href}
                        className="mt-6 block font-medium text-black"
                      >
                        <span
                          className="absolute inset-0 z-10 "
                          aria-hidden="true"
                        />
                        {product.name}
                      </a>
                      <p aria-hidden="true" className="mt-1">
                        {product.actionText}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        "Carregando..."
      )}
      <Alerts
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        type={warning.type}
        message={warning.message}
      />
    </div>
  );
};

export default View;
