import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { formatPhoneNumber } from "../../utils/FormatPhone";
import api from "../../api/api";

const getStatusColor = (deletedAt, emailConfirmed) => {
  if (deletedAt) return "bg-red-500"; // Red for deleted
  return emailConfirmed ? "bg-green-500" : "bg-orange-500"; // Green if confirmed, orange if not
};

const getCompanyColor = (isCompany) => {
  return isCompany ? "bg-green-500" : "bg-red-500";
};

const formatDate = (date) =>
  format(new Date(date), "dd/MM/yyyy", { locale: ptBR });

const ClientTable = ({ clients, onEdit }) => {
  const [sellers, setSellers] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSellers = async () => {
      setLoading(true);
      try {
        const sellerIds = [...new Set(clients.map((client) => client.seller))];
        const responses = await Promise.all(
          sellerIds.map((id) =>
            id ? api.get(`sellers/${id}`).then((res) => res.data.seller) : null
          )
        );
        const sellersData = responses.reduce((acc, seller) => {
          if (seller) acc[seller.id] = seller.name;
          return acc;
        }, {});
        setSellers(sellersData);
      } catch (error) {
        console.error("Failed to fetch sellers", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSellers();
  }, [clients]);

  if (loading) {
    return <CircularProgress />;
  }
  return (
    <div className="flex flex-col">
      <p></p>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell align="center">Salão</TableCell>
              <TableCell align="center">Vendedor</TableCell>
              <TableCell align="center">Contato</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Data de Cadastro</TableCell>
              <TableCell align="center">Cidade</TableCell>
              <TableCell align="center">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map((client) => (
              <TableRow key={client.id}>
                <TableCell>{client.name}</TableCell>
                <TableCell>
                  <div className="flex justify-center w-full">
                    <div
                      className={`w-3 h-3 rounded-full ${getCompanyColor(
                        client.isCompany
                      )}`}
                    ></div>
                  </div>
                </TableCell>
                <TableCell align="center">
                  {client.seller
                    ? sellers[client.seller] || "Vendedor Desconhecido"
                    : "Nenhum Vendedor Cadastrado"}
                </TableCell>
                <TableCell align="center">
                  {formatPhoneNumber(client.phone)}
                </TableCell>
                <TableCell align="center">{client.email}</TableCell>
                <TableCell align="center" className="flex justify-center">
                  <div className="flex justify-center w-full">
                    <div
                      className={`w-3 h-3 rounded-full ${getStatusColor(
                        client.deletedAt,
                        client.emailConfirmed
                      )}`}
                    ></div>
                  </div>
                </TableCell>
                <TableCell align="center">
                  {formatDate(client.createdAt)}
                </TableCell>
                <TableCell
                  align="center"
                  width={200}
                  className="whitespace-normal break-words"
                >
                  {client.billingAddress
                    ? `${client.billingAddress.city}/${client.billingAddress.state}`
                    : "Sem Endereço Cadastrado"}
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => onEdit(client)}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ClientTable;
