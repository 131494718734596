import React from "react";
import { useForm, Controller } from "react-hook-form";
import { TextField, Button, Container, Typography } from "@mui/material";
import api from "../../api/api"; // Certifique-se de ajustar o caminho do seu arquivo API

const CreateSeller = ({ setWarning, handleOpenSnackbar, onSave }) => {
  const { control, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      docNumber: "",
      address: {
        street: "",
        number: "",
        neighborhood: "",
        city: "",
        state: "",
      },
    },
  });

  const formatAddress = () => {
    const { street, number, neighborhood, city, state } = getValues("address");
    return `${street}, Nº ${number}, ${neighborhood}, ${city} - ${state}`;
  };

  const onSubmit = async (data) => {
    const formattedAddress = formatAddress();
    const sellerData = {
      ...data,
      address: formattedAddress,
    };

    try {
      const response = await api.post("/sellers", sellerData);
      if (response.status === 200 || response.status === 201) {
        setWarning({
          type: "success",
          message: "Vendedor Criado!",
        });
        handleOpenSnackbar();
        onSave();
      } else {
        setWarning({
          type: "error",
          message: "Falha ao criar o vendedor!",
        });
        handleOpenSnackbar();
      }
    } catch (error) {
      console.error("Failed to create seller", error);
      setWarning({
        type: "error",
        message: "Erro ao criar o vendedor!",
      });
      handleOpenSnackbar();
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Criar Vendedor
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Nome"
              variant="outlined"
              fullWidth
              margin="normal"
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
            />
          )}
        />
        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Telefone"
              variant="outlined"
              fullWidth
              margin="normal"
            />
          )}
        />
        <Controller
          name="docNumber"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="CPF/CNPJ"
              variant="outlined"
              fullWidth
              margin="normal"
            />
          )}
        />
        <Typography variant="h6" gutterBottom>
          Endereço
        </Typography>
        <Controller
          name="address.street"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Rua"
              variant="outlined"
              fullWidth
              margin="normal"
            />
          )}
        />
        <Controller
          name="address.number"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Número"
              variant="outlined"
              fullWidth
              margin="normal"
            />
          )}
        />
        <Controller
          name="address.neighborhood"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Bairro"
              variant="outlined"
              fullWidth
              margin="normal"
            />
          )}
        />
        <Controller
          name="address.city"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Cidade"
              variant="outlined"
              fullWidth
              margin="normal"
            />
          )}
        />
        <Controller
          name="address.state"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Estado"
              variant="outlined"
              fullWidth
              margin="normal"
            />
          )}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Criar
        </Button>
      </form>
    </Container>
  );
};

export default CreateSeller;
