import React, { useState } from "react";

const View = ({ banners, setOption, setSelectedBanner }) => {
  const [selectedBannerId, setSelectedBannerId] = useState(null);

  return (
    <div className="space-y-4">
      <button
        onClick={() => setOption("create")}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition ease-in-out duration-150"
      >
        Criar Novo Banner
      </button>

      {banners.map((banner) => (
        <Banner
          key={banner.id}
          banner={banner}
          setSelectedBannerId={setSelectedBannerId}
          setSelectedBanner={setSelectedBanner}
          isSelected={selectedBannerId === banner.id}
          setOption={setOption}
        />
      ))}
    </div>
  );
};

export default View;

const Banner = ({
  banner,
  setSelectedBannerId,
  isSelected,
  setSelectedBanner,
  setOption,
}) => {
  const { id, name, text, href, imgUrl } = banner;
  const b = banner;

  const renderStandardBanner = () => (
    <div className="bg-white border rounded-lg overflow-hidden ">
      <div className="bg-white border rounded-lg overflow-hidden">
        <div className="flex justify-between p-5 border-b border-gray-200">
          <div>
            <h2 className="text-xl font-semibold text-gray-800">{name}</h2>
            <div className="flex">
              <p className="mt-2 mx-1 text-gray-600">Link: {href}</p>
              <p className="mt-2 mx-1 text-gray-600">Descrição: {text}</p>
            </div>
          </div>
          <div className="">
            <button
              onClick={() => {
                setSelectedBannerId(id);
                setSelectedBanner(b);
                setOption("edit");
              }}
              className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 transition-colors"
            >
              Editar Card
            </button>
          </div>
        </div>
        <div className="flex justify-center m-2">
          <img
            src={imgUrl}
            alt={name}
            className="rounded-lg w-52 md:w-64 lg:w-80 h-auto object-cover"
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className={` max-w-4xl mx-auto my-8  rounded-lg shadow-lg `}>
      {renderStandardBanner()}
    </div>
  );
};
