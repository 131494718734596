import React, { useEffect } from "react";
import Header from "../components/Header";
import noImage from "../assets/noImage.jpg";

const UniqueOrder = ({ order, setUniqueOrder }) => {
  const formatCurrency = (value) => {
    return `R$ ${value.toFixed(2).replace(".", ",")}`;
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Administrativo" title="Detalhamento de Pedido" />
      <div className="flex flex-col">
        <h1 className="underline">
          Compra em nome de <b>{order.user.name}</b>
        </h1>
        {order.shippingTrack && (
          <h2>
            Número para Rastreamento do Pedido no MR: {order.shippingTrack}
          </h2>
        )}
      </div>
      <button
        className="btn text-blue-900 mt-4 mb-4"
        onClick={() => setUniqueOrder(null)}
      >
        voltar
      </button>
      <div className="bg-white border rounded-lg p-4 mb-4 ">
        <div>
          <h2 className="text-xl font-semibold">Informações do Pedido:</h2>
          <div className="flex justify-evenly mt-3">
            <p>
              Número do Pedido:{" "}
              <span className="font-medium">
                {order.orderCode.toUpperCase()}
              </span>
            </p>

            <p>
              Data do Pedido:{" "}
              <span className="font-medium">
                {new Date(order.createdAt).toLocaleDateString()}
              </span>
            </p>

            <p>
              Tipo do Frete:{" "}
              <span className="font-medium">{order.shippingMethod}</span>
            </p>
            <p>
              Valor do Frete:{" "}
              <span className="font-medium">
                {formatCurrency(order.shippingPrice)}
              </span>
            </p>
            <p>
              Valor Total:{" "}
              <span className="font-medium">{formatCurrency(order.total)}</span>
            </p>
          </div>
        </div>
        <div className="flex justify-evenly mt-4">
          <p>
            Status do Pedido:{" "}
            <span className="font-medium">{gridOrderStatus(order)}</span>
          </p>
          <p>
            Status do Pagamento:{" "}
            <span className="font-medium">{gridPaymentStatus(order)}</span>
          </p>
          <p>
            Forma de Pagamento:{" "}
            <span className="font-medium">{paymentType(order)}</span>
          </p>
          <p>
            Status do Envio:{" "}
            <span className="font-medium">{gridShipStatus(order)}</span>
          </p>
        </div>
      </div>

      {/* Items do pedido */}

      <div className="flex">
        <div className="w-7/12 pr-8">
          <div className="bg-white border rounded-lg p-4 mb-4">
            <h2 className="text-xl font-semibold">Produtos:</h2>
            {order.items.map((item) => (
              <div className="flex items-center border-b py-4" key={item.id}>
                <div className="flex-shrink-0 mr-4">
                  <img
                    src={item.imgUrl ? item.imgUrl : noImage}
                    alt={item.name}
                    className="w-44 h-44 object-contain"
                  />
                </div>
                <div>
                  <h3 className="text-xl font-semibold">{item.name}</h3>
                  <p>{formatCurrency(item.price)}</p>
                  <p>Qtd: {item.quantity}</p>
                  <p>Subtotal: {formatCurrency(item.total)}</p>
                  <p>{item.shortDesc}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-5/12">
          <div className="bg-white border rounded-lg p-4 mb-4">
            <h2 className="text-xl font-semibold">
              Informações de cobrança e entrega:
            </h2>
            <div className="bg-[#FAF9FB] rounded-sm p-4">
              <h3 className="text-md font-semibold">Endereço de Cobrança</h3>
              <p className="text-sm leading-tight text-gray-500">
                <strong>Endereço:</strong> {order.orderBillingAddress.street},{" "}
                {order.orderBillingAddress.number}
              </p>
              <p className="text-sm leading-tight text-gray-500">
                <strong>Complemento:</strong>{" "}
                {order.orderBillingAddress.complement}
              </p>
              <p className="text-sm leading-tight text-gray-500">
                <strong>Bairro:</strong>{" "}
                {order.orderBillingAddress.neighborhood} -{" "}
                {order.orderBillingAddress.city} -{" "}
                {order.orderBillingAddress.state}
              </p>
              <p className="text-sm leading-tight text-gray-500">
                <strong>País:</strong> {order.orderBillingAddress.country} -{" "}
                {order.orderBillingAddress.zipCode}
              </p>
            </div>
            <div className="mt-4 bg-[#FAF9FB] rounded-sm p-4">
              <h3 className="text-md font-semibold">Endereço de Entrega</h3>
              <p className="text-sm leading-tight text-gray-500">
                <strong>Endereço:</strong> {order.orderShippingAddress.street},{" "}
                {order.orderShippingAddress.number}
              </p>
              <p className="text-sm leading-tight text-gray-500">
                <strong>Complemento:</strong>{" "}
                {order.orderShippingAddress.complement}
              </p>
              <p className="text-sm leading-tight text-gray-500">
                <strong>Bairro:</strong>{" "}
                {order.orderShippingAddress.neighborhood} -{" "}
                {order.orderShippingAddress.city} -{" "}
                {order.orderShippingAddress.state}
              </p>
              <p className="text-sm leading-tight text-gray-500">
                <strong>País:</strong> {order.orderShippingAddress.country} -{" "}
                {order.orderShippingAddress.zipCode}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UniqueOrder;

const gridPaymentStatus = (status) => (
  <button
    type="button"
    style={{
      background:
        status.paymentStatus === "PENDING"
          ? "#FFC107"
          : status.paymentStatus === "PAYMENT_CREATED"
          ? "#007BFF"
          : status.paymentStatus === "PAYMENT_APPROVED_BY_RISK_ANALYSIS"
          ? "#28A745"
          : status.paymentStatus === "PAYMENT_REPROVED_BY_RISK_ANALYSIS"
          ? "#DC3545"
          : status.paymentStatus === "PAYMENT_UPDATED"
          ? "#007BFF"
          : status.paymentStatus === "PAYMENT_CONFIRMED"
          ? "#28A745"
          : status.paymentStatus === "PAYMENT_RECEIVED"
          ? "#28A745"
          : status.paymentStatus === "PAYMENT_CREDIT_CARD_CAPTURE_REFUSED"
          ? "#DC3545"
          : status.paymentStatus === "PAYMENT_ANTICIPATED"
          ? "#4C16E0"
          : status.paymentStatus === "PAYMENT_OVERDUE"
          ? "#DC3545"
          : status.paymentStatus === "PAYMENT_DELETED"
          ? "#DC3545"
          : status.paymentStatus === "PAYMENT_RESTORED"
          ? "#ECDD00"
          : status.paymentStatus === "PAYMENT_REFUNDED"
          ? "#DC3545"
          : status.paymentStatus === "PAYMENT_UPDATED"
          ? "#DC3545"
          : "#3D3D3D",
    }}
    className="text-black py-2 px-4 capitalize rounded-2xl text-md"
  >
    {status.paymentStatus === "PENDING"
      ? "Pendente"
      : status.paymentStatus === "PAYMENT_CREATED"
      ? "Criado"
      : status.paymentStatus === "PAYMENT_APPROVED_BY_RISK_ANALYSIS"
      ? "Aprovado pela Análise de Riscos"
      : status.paymentStatus === "PAYMENT_REPROVED_BY_RISK_ANALYSIS"
      ? "Reprovado pela Análise de Riscos"
      : status.paymentStatus === "PAYMENT_UPDATED"
      ? "Atualizado"
      : status.paymentStatus === "PAYMENT_CONFIRMED"
      ? "Confirmado"
      : status.paymentStatus === "PAYMENT_RECEIVED"
      ? "Recebido"
      : status.paymentStatus === "PAYMENT_CREDIT_CARD_CAPTURE_REFUSED"
      ? "Recusado pela Operadora do Cartão"
      : status.paymentStatus === "PAYMENT_ANTICIPATED"
      ? "Antecipado"
      : status.paymentStatus === "PAYMENT_OVERDUE"
      ? "Expirado"
      : status.paymentStatus === "PAYMENT_DELETED"
      ? "Deletado"
      : status.paymentStatus === "PAYMENT_RESTORED"
      ? "Restaurado"
      : status.paymentStatus === "PAYMENT_REFUNDED"
      ? "Extornado"
      : "Outros"}
  </button>
);

const gridOrderStatus = (props) => (
  <button
    type="button"
    style={{
      background:
        props.orderStatus === "CRIADO"
          ? "#B3B3B3"
          : props.orderStatus === "AGUARDANDO_PAGAMENTO"
          ? "#F2F20C"
          : props.orderStatus === "PROBLEMAS_PAGAMENTO"
          ? "#F3C211"
          : props.orderStatus === "PAGAMENTO_RECUSADO"
          ? "#FF4D4D"
          : props.orderStatus === "PAGAMENTO_REALIZADO"
          ? "#FFD400"
          : props.orderStatus === "EM_SEPARACAO"
          ? "#FFAA00"
          : props.orderStatus === "PEDIDO_SEPARADO"
          ? "#F28B24"
          : props.orderStatus === "AGUARDANDO_COLETA"
          ? "#F28B24"
          : props.orderStatus === "NOTA_FISCAL_EMITIDA"
          ? "#22C5E5"
          : props.orderStatus === "PEDIDO_DESPACHADO"
          ? "#2681FF"
          : props.orderStatus === "PEDIDO_ENTREGUE"
          ? "#3DCC6D"
          : props.orderStatus === "ORDEM_FINALIZADA"
          ? "#3DCC6D"
          : props.orderStatus === "ORDEM_CANCELADA"
          ? "#FF4D4D"
          : "#FF4D4D",
    }}
    className="text-[#000000] py-2 px-4 capitalize rounded-2xl text-md font-semibold"
  >
    {props.orderStatus === "CRIADO"
      ? "CRIADO"
      : props.orderStatus === "AGUARDANDO_PAGAMENTO"
      ? "AGUARDANDO PAGAMENTO"
      : props.orderStatus === "PROBLEMAS_PAGAMENTO"
      ? "PROBLEMAS COM PAGAMENTO"
      : props.orderStatus === "PAGAMENTO_RECUSADO"
      ? "PAGAMENTO RECUSADO"
      : props.orderStatus === "PAGAMENTO_REALIZADO"
      ? "PAGAMENTO REALIZADO"
      : props.orderStatus === "EM_SEPARACAO"
      ? "EM SEPRARAÇÃO"
      : props.orderStatus === "PEDIDO_SEPARADO"
      ? "PEDIDO SEPARADO"
      : props.orderStatus === "AGUARDANDO_COLETA"
      ? "AGUARDANDO COLETA"
      : props.orderStatus === "NOTA_FISCAL_EMITIDA"
      ? "NOTA FISCAL EMITIDA"
      : props.orderStatus === "PEDIDO_DESPACHADO"
      ? "PEDIDO DESPACHADO"
      : props.orderStatus === "PEDIDO_ENTREGUE"
      ? "PEDIDO ENTREGUE"
      : props.orderStatus === "ORDEM_FINALIZADA"
      ? "FINALIZADO"
      : props.orderStatus === "ORDEM_CANCELADA"
      ? "CANCELADO"
      : "OUTROS"}
  </button>
);

const gridShipStatus = (props) => (
  <button
    type="button"
    style={{
      background:
        props.shippingStatus === "PENDENTE"
          ? "#F2F20C"
          : props.shippingStatus === "EM_SEPARACAO"
          ? "#FFD400"
          : props.shippingStatus === "FRETE_COMPRADO"
          ? "#FFAA00"
          : props.shippingStatus === "AGUARDANDO_COLETA"
          ? "#F28B24"
          : props.shippingStatus === "COLETADO"
          ? "#A2C5E5"
          : props.shippingStatus === "EM_TRANSITO"
          ? "#22C5E5"
          : props.shippingStatus === "ENTREGUE"
          ? "#3DCC6D"
          : props.shippingStatus === "NAO_ENTREGUE"
          ? "#D941A6"
          : props.shippingStatus === "CANCELADO"
          ? "#FF4D4D"
          : "#FF4D4D",
    }}
    className="text-[#000000] py-2 px-4 capitalize rounded-2xl text-md"
  >
    {props.shippingStatus === "PENDENTE"
      ? "PENDENTE"
      : props.shippingStatus === "EM_SEPARACAO"
      ? "EM SEPARAÇÃO"
      : props.shippingStatus === "FRETE_COMPRADO"
      ? "FRETE COMPRADO"
      : props.shippingStatus === "AGUARDANDO_COLETA"
      ? "AGUARDANDO COLETA"
      : props.shippingStatus === "COLETADO"
      ? "COLETADO"
      : props.shippingStatus === "EM_TRANSITO"
      ? "EM TRÂNSITO"
      : props.shippingStatus === "ENTREGUE"
      ? "ENTREGUE"
      : props.shippingStatus === "NAO_ENTREGUE"
      ? "NÃO ENTREGUE"
      : props.shippingStatus === "CANCELADO"
      ? "CANCELADO"
      : "OUTROS"}
  </button>
);

const paymentType = (props) => (
  <button
    type="button"
    style={{
      background:
        props.paymentMethod === "CREDIT_CARD"
          ? "#22C5E5"
          : props.paymentMethod === "PIX"
          ? "#3DCC6D"
          : props.paymentMethod === "BANK_SLIP"
          ? "#D941A6"
          : "#FF4D4D",
    }}
    className="text-white py-1 px-2 capitalize rounded-2xl text-md"
  >
    {props.paymentMethod === "CREDIT_CARD"
      ? "CARTÃO DE CRÉDITO"
      : props.paymentMethod === "PIX"
      ? "PIX"
      : props.paymentMethod === "BANK_SLIP"
      ? "BOLETO"
      : "OUTROS"}
  </button>
);
