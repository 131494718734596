import React, { useEffect, useState } from "react";
import Create from "../components/Banner/Create";
import View from "../components/Banner/View";
import Edit from "../components/Banner/Edit";
import { Header } from "../components";
import api from "../api/api";

const CreateBanner = () => {
  const [option, setOption] = useState("view");
  const [banners, setBanners] = useState(null);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [form, setForm] = useState({});

  useEffect(() => {
    api.get("banners").then((response) => {
      setBanners(response.data.banners);
    });
  }, [option]);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      {option === "create" ? (
        <>
          <Header category="Home Page" title="Novo Banner" />
          <Create setOption={setOption} />
        </>
      ) : option === "edit" && selectedBanner ? ( // Garantir que selectedBanner não é nulo
        <>
          <Header category="Home Page" title="Editar Banner" />
          <Edit selectedBanner={selectedBanner} setOption={setOption} />
        </>
      ) : banners ? (
        <>
          <Header category="Home Page" title="Banners" />
          <View
            banners={banners}
            setOption={setOption}
            setSelectedBanner={setSelectedBanner}
          />
        </>
      ) : (
        <>
          <Header category="Home Page" title="Banners" />
          "Carregando..."
        </>
      )}
    </div>
  );
};

export default CreateBanner;
