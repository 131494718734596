import React, { useState, useContext } from "react";
import logo from "../assets/Logo.svg";
import { useNavigate } from "react-router-dom";
import { Context } from "../contexts/AuthContext";

const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const { handleLogin } = useContext(Context);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the form from submitting in the traditional way
    try {
      await handleLogin(username, password); // Assuming handleLogin is adjusted to accept username and password
      navigate("/"); // Redirect to dashboard or another route on successful login
    } catch (error) {
      if (error.response) {
        // Erros de resposta da API
        setErrors(["Email ou senha incorretos, verifique e tente novamente!"]);
      } else {
        // Erros de rede ou desconhecidos
        console.error(error);
        setErrors(["Erro de conexão ou problema de rede"]);
      }
    }
  };

  return (
    <div className="bg-white">
      <main className="flex justify-center items-center h-screen">
        <div className="max-w-2xl px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <a href="/" className="flex justify-center">
              <img src={logo} alt="Logo" />
            </a>
            <h2 className="mt-6 text-3xl font-bold tracking-tight text-dark-blue">
              Painel Administrativo
            </h2>
          </div>
          <form className="mt-6 space-y-6" onSubmit={handleSubmit}>
            {errors.map((error, idx) => (
              <p key={idx} style={{ color: "red" }}>
                {error}
              </p>
            ))}
            <div className="mb-3">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                id="email"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-green focus:outline-none focus:ring-green sm:text-sm"
                placeholder="email@provedor.com ou CPF"
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Senha
              </label>
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-green focus:outline-none focus:ring-green sm:text-sm"
                placeholder="**************************"
              />
            </div>
            <button
              type="submit"
              className="w-full flex justify-center lowercase rounded-md border border-transparent bg-[#0013FF] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-[#3641cf] focus:outline-none focus:ring-2 focus:ring-green focus:ring-offset-2"
            >
              Login
            </button>
          </form>
        </div>
      </main>
    </div>
  );
};

export default LoginForm;
