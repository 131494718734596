import React, { useEffect, useState } from "react";
import Create from "./Create";
import Edit from "./Edit";
import Alerts from "../Alerts";
import api from "../../api/api";
import DOMPurify from "dompurify";

const View = () => {
  const [options, setOptions] = useState("");
  const [faixasTexto, setFaixasTexto] = useState(null);
  const [selectedFaixa, setSelectedFaixa] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [warning, setWarning] = useState({});

  useEffect(() => {
    // A função só será executada quando `cards` for uma string vazia.
    if (options !== "") return;

    const fetchFeaturedProducts = async () => {
      try {
        const response = await api.get("banners/line");
        setFaixasTexto(response.data.inLineBanners);
      } catch (error) {
        console.error("Erro ao buscar produtos destacados:", error); // É bom manter o log de erros
        setWarning({
          type: "error",
          message: "Falha ao carregar os cards do servidor!",
        });
        handleOpenSnackbar();
      }
    };

    fetchFeaturedProducts();
  }, [options]);

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleHtml = (html) => {
    const cleanHTML = DOMPurify.sanitize(html);
    return cleanHTML;
  };

  return (
    <div className="p-4">
      <div className="flex justify-between mb-4">
        <button
          onClick={() => {
            options === "" ? setOptions("create") : setOptions("");
          }}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition-colors"
        >
          {options === "" ? "Criar Nova Faixa de Texto" : "Voltar"}
        </button>
      </div>
      {options === "create" ? (
        <Create
          setWarning={setWarning}
          handleOpenSnackbar={handleOpenSnackbar}
          setOptions={setOptions}
        />
      ) : options === "edit" ? (
        <Edit
          item={selectedFaixa}
          setWarning={setWarning}
          handleOpenSnackbar={handleOpenSnackbar}
          setOptions={setOptions}
        />
      ) : faixasTexto ? (
        <div className="space-y-6 max-w-xl">
          {faixasTexto.map((featured, index) => (
            <div
              key={index}
              className="bg-white border rounded-lg overflow-hidden "
            >
              <div className="flex justify-between p-5 border-b border-gray-200">
                <div>
                  <div className="flex">
                    <p className="text-sm font-bold px-1 text-gray-700">
                      Posição:{" "}
                      {featured.position === "TOP"
                        ? "Topo"
                        : "Abaixo do Carrossel"}
                    </p>
                  </div>
                </div>
                <button
                  onClick={() => {
                    setOptions("edit");
                    setSelectedFaixa(featured);
                  }}
                  className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 transition-colors"
                >
                  Editar Card
                </button>
              </div>
              <div className="flex flex-wrap justify-start items-stretch p-1 ">
                <div className="mx-1 rounded-lg overflow-hidden p-4 mb-4 flex flex-col w-full ">
                  <h3
                    className="text-md flex mb-2"
                    dangerouslySetInnerHTML={{
                      __html: `Mensagem:&nbsp; ${handleHtml(featured.text)}`,
                    }}
                  />

                  {featured.button && (
                    <>
                      <p className="text-sm font-bold px-1 text-gray-500">
                        Tem um botão de cupom? {featured.button ? "Sim" : "Não"}
                      </p>
                      <p className="text-sm font-bold px-1 text-gray-500">
                        Código do Cupom: {featured.textButton}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        "Carregando..."
      )}
      <Alerts
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        type={warning.type}
        message={warning.message}
      />
    </div>
  );
};

export default View;
