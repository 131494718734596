import React, { useState } from "react";
import api from "../../api/api";
import { Edit } from "./Edit";
import {
  IconButton,
  Box,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import EditNoteIcon from "@mui/icons-material/EditNote";

const CategoryItem = ({
  category,
  step,
  setStep,
  setCategoryToEdit,
  depth,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openDeleteCategory, setOpenDeleteCategory] = useState(false);
  const [openDeleteAllProducts, setOpenDeleteAllProducts] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleDeleteCategory = () => {
    if (!category.children || category.children.length === 0) {
      api.delete(`/categories/${category.id}`).then((res) => {
        if (res.status === 200) {
          setOpenDeleteCategory(false);
        }
      });
    }
  };

  const handleDeleteAllProducts = () => {
    if (category.products && category.products.length > 0) {
      api
        .put(`/categories/${category.id}`, { clearProducts: true })
        .then((res) => {
          if (res.status === 200) {
            setOpenDeleteAllProducts(false);
          }
        });
    }
  };

  const handleEdit = (category) => {
    setCategoryToEdit(category);
    setStep("edit");
  };

  return (
    <div>
      <div
        onClick={toggleDropdown}
        className={`cursor-pointer py-2 px-4 hover:bg-gray-100 flex justify-between items-center `}
      >
        <div
          className={`flex items-center ${depth > 0 ? `px-${depth * 2}` : ""}`}
        >
          {isOpen && category.children && category.children.length > 0 ? (
            <KeyboardDoubleArrowDownIcon />
          ) : (
            <KeyboardDoubleArrowRightIcon />
          )}
          <Typography variant={depth > 0 ? "body2" : "body1"}>
            {category.name}
          </Typography>
        </div>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Tooltip title="Excluir Categoria">
            <IconButton
              color="error"
              onClick={() => setOpenDeleteCategory(true)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Desvincular todos os Produtos">
            <IconButton
              color="warning"
              disabled={!category.products || category.products.length === 0}
              onClick={() => setOpenDeleteAllProducts(true)}
            >
              <ClearAllIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Editar Categoria">
            <IconButton color="primary" onClick={() => handleEdit(category)}>
              <EditNoteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </div>
      {isOpen && category.children && category.children.length > 0 && (
        <List disablePadding>
          {category.children.map((subcategory) => (
            <div key={subcategory.id}>
              <CategoryItem
                category={subcategory}
                setStep={setStep}
                setCategoryToEdit={setCategoryToEdit}
                depth={depth + 1}
              />
              <Divider />
            </div>
          ))}
        </List>
      )}

      {/* Modal para Excluir Categoria */}
      <Dialog
        open={openDeleteCategory}
        onClose={() => setOpenDeleteCategory(false)}
      >
        <DialogTitle>Confirmar Exclusão da Categoria</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza de que deseja excluir a categoria {category.name}?
          </DialogContentText>

          {(category.children && category.children.length > 0) ||
          (category.products && category.products.length > 0) ? (
            <>
              <br />
              <DialogContentText>
                <Typography color="error">
                  {category.children && category.children.length > 0
                    ? "Essa categoria contém outras categorias vinculadas a ela, desvincule as subcategorias primeiro antes de excluir!"
                    : "Essa categoria contém produtos vinculados a ela, desvincule os produtos primeiro antes de excluir!"}
                </Typography>
              </DialogContentText>
            </>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteCategory(false)} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={handleDeleteCategory}
            color="error"
            disabled={
              category.children && category.children.length > 0
                ? true
                : category.products && category.products.length > 0
                ? true
                : false
            }
          >
            Excluir
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal para Excluir Todos os Produtos */}
      <Dialog
        open={openDeleteAllProducts}
        onClose={() => setOpenDeleteAllProducts(false)}
      >
        <DialogTitle>Confirmar Desvinculação de TODOS OS PRODUTOS</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza de que deseja excluir todos os produtos da categoria{" "}
            {category.name}?
          </DialogContentText>
          {(!category.products || category.products.length === 0) && (
            <>
              <br />
              <DialogContentText>
                <Typography color="error">
                  Não foi encotrado nenhum produto vinculado a esta categoria
                </Typography>
              </DialogContentText>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDeleteAllProducts(false)}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            onClick={handleDeleteAllProducts}
            color="error"
            disabled={!category.products || category.products.length === 0}
          >
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const CollapsibleTable = ({ onSelect, categories, step, setStep }) => {
  const [categoryToEdit, setCategoryToEdit] = useState({});

  return step === "" ? (
    <List component="div">
      {categories.map((category) => (
        <div key={category.id}>
          <CategoryItem
            category={category}
            setStep={setStep}
            setCategoryToEdit={setCategoryToEdit}
            depth={0}
          />
          <Divider />
        </div>
      ))}
    </List>
  ) : (
    <Edit
      categoryToEdit={categoryToEdit}
      setCategoryToEdit={setCategoryToEdit}
      setStep={setStep}
    />
  );
};

export default CollapsibleTable;
