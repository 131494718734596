import React, { useState } from "react";

function not(a, bIds) {
  return a.filter((aItem) => !bIds.includes(aItem.id));
}

function intersection(checkedIds, items) {
  return items.filter((item) => checkedIds.includes(item.id));
}

export default function TransferList({
  items,
  setAllProducts,
  selectedItems,
  setSelectedProducts,
}) {
  const [checked, setChecked] = useState([]);

  const leftChecked = intersection(checked, items);
  const rightChecked = intersection(checked, selectedItems);

  const handleToggle = (value) => () => {
    const currentIndex = checked.findIndex(
      (checkedId) => checkedId === value.id
    );
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    // Adicione os itens selecionados aos produtos selecionados
    const newSelectedItems = [...selectedItems, ...leftChecked];
    // Limitar o número total de itens selecionados a 4
    const limitedSelectedItems = newSelectedItems.slice(0, 4);
    // Remova os itens selecionados da lista de todos os produtos
    setAllProducts((prevItems) =>
      not(
        prevItems,
        limitedSelectedItems.map((item) => item.id)
      )
    );
    // Atualize a lista de produtos selecionados
    setSelectedProducts(limitedSelectedItems);
    // Limpa os IDs marcados que foram movidos
    setChecked((prevChecked) =>
      prevChecked.filter(
        (id) => !limitedSelectedItems.find((item) => item.id === id)
      )
    );
  };

  const handleCheckedLeft = () => {
    // Adicione os itens selecionados de volta à lista de todos os produtos
    setAllProducts((prevItems) => [...prevItems, ...rightChecked]);
    // Remova os itens selecionados da lista de produtos selecionados
    setSelectedProducts((prevSelected) =>
      not(
        prevSelected,
        rightChecked.map((item) => item.id)
      )
    );
    // Limpa os IDs marcados que foram movidos
    setChecked((prevChecked) =>
      prevChecked.filter((id) => !rightChecked.find((item) => item.id === id))
    );
  };

  const customList = (listItems, isLeftList = true) => (
    <div className="w-80 h-72 overflow-auto border rounded-md">
      {listItems.map((item) => {
        // Desabilita se já tem 4 itens selecionados na lista da esquerda e o item não está selecionado
        const isDisabled =
          (isLeftList &&
            selectedItems.length >= 4 &&
            !checked.includes(item.id)) ||
          checked.length >= 4;
        return (
          <div
            key={item.id}
            className={`flex items-center p-2 rounded-md my-1 ${
              checked.includes(item.id) ? "bg-blue-300" : "bg-white"
            } ${
              isDisabled ? "cursor-not-allowed opacity-50" : "cursor-pointer"
            }`}
            onClick={() => {
              if (!isDisabled) {
                handleToggle(item)();
              }
            }}
          >
            <input
              type="checkbox"
              className="mr-2"
              checked={checked.includes(item.id)}
              readOnly
            />
            <span className="text-xs">{item.name}</span>
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="flex justify-center items-center space-x-4">
      <div>
        <span>Lista de Produtos</span>
        {customList(items, true)}
      </div>
      {/* Coluna da esquerda */}
      <div className="flex flex-col items-center">
        <button
          className={`mb-2 px-4 py-1 border rounded ${
            leftChecked.length === 0 || selectedItems.length >= 4
              ? "cursor-not-allowed text-gray-300"
              : "cursor-pointer"
          }`}
          onClick={handleCheckedRight}
          disabled={leftChecked.length === 0 || selectedItems.length >= 4}
        >
          &gt;
        </button>
        <button
          className={`mt-2 px-4 py-1 border rounded ${
            rightChecked.length === 0
              ? "cursor-not-allowed text-gray-300"
              : "cursor-pointer"
          }`}
          onClick={handleCheckedLeft}
          disabled={rightChecked.length === 0}
        >
          &lt;
        </button>
      </div>
      <div>
        <span>Produtos em Destaque</span>
        {customList(selectedItems, false)}
      </div>{" "}
      {/* Coluna da direita */}
    </div>
  );
}
