import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import api from "../../api/api";

export default function useAuth() {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      api.defaults.headers.Authorization = `Bearer ${JSON.parse(token)}`;
      setAuthenticated(true);
    }

    setLoading(false);
  }, []);

  async function handleLogin(username, password) {
    try {
      const response = await api.post("/admin/sessions", {
        username,
        password,
      });
      const { token } = response.data;
      localStorage.setItem("token", JSON.stringify(token));
      api.defaults.headers.Authorization = `Bearer ${token}`;

      const userDataResponse = await api.get("/me");
      if (userDataResponse.status === 200) {
        const loggedUser = {
          id: userDataResponse.data.id,
          name: userDataResponse.data.name,
        };
        localStorage.setItem("user", JSON.stringify(loggedUser));
        setAuthenticated(true);
      } else {
        // Tratar caso a resposta não seja 200 ou não contenha um token
        console.log("error na api /me");
      }
    } catch (error) {
      // Handle errors (e.g., invalid credentials, network issues) here
      console.error("Login failed:", error);
      // Optionally, return a message or boolean indicating failure
      throw error; // Re-throw the error if you want to handle it in the component
    }
  }

  function handleLogout() {
    setAuthenticated(false);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("orderSummary");
    api.defaults.headers.Authorization = undefined;
  }

  return { authenticated, loading, handleLogin, handleLogout };
}
