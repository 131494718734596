import React from "react";
import Create from "../components/Compras/NF/Create";

const Nfce = () => {
  return (
    <div>
      <Create />
    </div>
  );
};

export default Nfce;
