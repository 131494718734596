import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import api from "../../api/api";

const Create = ({ setOptions, setWarning, handleOpenSnackbar }) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      button: false, // Adiciona um valor padrão para o checkbox
      textButton: "",
    },
  });

  const [body, setBody] = useState(""); // Estado para manter o valor do editor
  const showTextButton = watch("button"); // Monitora o estado do checkbox "button"

  // Atualize o valor do 'Body' no react-hook-form sempre que o texto mudar
  const handleQuillChange = (value) => {
    setBody(value); // Atualize o estado local
    setValue("text", value, { shouldValidate: true }); // Atualize o react-hook-form e dispare a validação
  };

  const onSubmit = (formData) => {
    api
      .post("/banners/line", formData)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          // 201 para Created
          setWarning({
            type: "success",
            message: "Faixa de Texto criada com sucesso!",
          });
          handleOpenSnackbar();
          setOptions("");
        } else {
          // Alguma outra resposta de sucesso não esperada
          setWarning({
            type: "warning",
            message:
              "Faixa de Texto criada, mas com respostas inesperadas do servidor.",
          });
          handleOpenSnackbar();
          setOptions("");
        }
      })
      .catch((error) => {
        let errorMessage = "Falha ao salvar a Faixa de Texto no servidor!";
        if (error.response) {
          // Adiciona detalhes específicos do erro se disponível
          errorMessage += ` Detalhes: ${
            error.response.data.message || error.response.status
          }`;
        } else if (error.request) {
          // A requisição foi feita mas não houve resposta

          errorMessage = "O servidor não respondeu ao pedido.";
        } else {
          // Algum erro aconteceu ao montar o pedido

          errorMessage = "Erro ao enviar o pedido para o servidor.";
        }
        setWarning({
          type: "error",
          message: errorMessage,
        });
        handleOpenSnackbar();
      });
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="max-w-3xl mx-auto my-10 p-8 bg-white rounded-lg shadow-md"
    >
      <div className="mb-6">
        <label
          htmlFor="position"
          className="block text-gray-700 text-sm font-bold mb-3"
        >
          Escolha a posição
        </label>
        <select
          id="position"
          {...register("position", { required: true })}
          className="appearance-none border rounded  w-full py-3 px-4 text-gray-700 leading-tight focus:border focus:border-blue-300"
        >
          <option value="">Escolha uma opção</option>
          <option value="TOP">Topo</option>
          <option value="ABOVE_HEADER">Abaixo do Carrossel</option>
        </select>
        {errors["position"] && (
          <span className="text-red-500 text-xs italic mt-2 block">
            Este item é obrigatório
          </span>
        )}
      </div>

      <div className="mb-6">
        <label
          htmlFor="type"
          className="block text-gray-700 text-sm font-bold mb-3"
        >
          Tipo:
        </label>
        <select
          {...register("type", { required: true })}
          className="appearance-none border rounded  w-full py-3 px-4 text-gray-700 leading-tight focus:border focus:border-blue-300"
        >
          <option value="">SELECIONE UMA OPÇÂO</option>
          <option value="FOR_YOU">Para você</option>
          <option value="FOR_BUSINESS">Para salão</option>
        </select>
      </div>

      <div className="mb-6">
        <label
          htmlFor="text"
          className="block text-gray-700 text-sm font-bold mb-3"
        >
          Texto
        </label>
        <ReactQuill
          theme="snow"
          onChange={handleQuillChange}
          value={body} // Use o estado local como valor
          modules={Create.modules}
          formats={Create.formats}
          placeholder="Escreva sua mensagem..."
          className="bg-white h-[full max-w-3xl"
        />
        {errors.text && (
          <span className="text-red-500 text-xs italic mt-2 block">
            {errors.text.message}
          </span>
        )}
      </div>

      <div className="mb-6 flex items-center">
        <input
          type="checkbox"
          id="checkbox"
          {...register("button")}
          className="form-checkbox h-3 w-3 text-blue-600 bg-white border-1 border-gray-300 focus:ring-0 focus:outline-none rounded-xl"
        />
        <label
          htmlFor="checkbox"
          className="ml-2 block text-gray-700 text-sm font-bold"
        >
          Adicionar um código de CUPOM
        </label>
      </div>

      {showTextButton && ( // Renderização condicional baseada no estado do checkbox
        <div className="mb-6">
          <label
            htmlFor="textButton"
            className="block text-gray-700 text-sm font-bold mb-3"
          >
            Código do CUPOM
          </label>
          <input
            type="text"
            id="textButton"
            placeholder="Código do CUPOM"
            {...register("textButton", {
              required: "Código do CUPOM é obrigatório",
            })}
            className="appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:border-blue-300"
          />
          {errors.textButton && (
            <span className="text-red-500 text-xs italic mt-2 block">
              {errors.textButton.message}
            </span>
          )}
        </div>
      )}

      <div className="flex items-center justify-between mt-24">
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out transform hover:scale-105"
        >
          Salvar
        </button>
      </div>
    </form>
  );
};

export default Create;

// Adicione módulos e formatos que você deseja habilitar no Quill editor
Create.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
  ],
  clipboard: {
    // Colar sem formatação
    matchVisual: true,
  },
};

Create.formats = [
  "header",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
];
