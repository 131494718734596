import React from "react";
import { useForm } from "react-hook-form";
import api from "../../api/api";

const Edit = ({ selectedBanner, setOption }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: selectedBanner, // Assegura que os valores iniciais sejam carregados
  });

  const onSubmit = (data) => {
    api
      .post(`banners/body`, data) // Mudança para PUT para atualização
      .then((response) => {
        console.log(response.data);
        setOption("view"); // Retorna para a visualização após a atualização
      })
      .catch((error) => console.error("Erro na atualização", error));
  };
  return (
    <div className="space-y-4">
      <button
        onClick={() => setOption("view")}
        className="hover:text-blue-700 text-blue-500 font-bold py-2 px-4 rounded transition ease-in-out duration-150"
      >
        Voltar
      </button>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-4 max-w-xl mx-auto my-10"
      >
        {/* Nome */}
        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Nome
          </label>

          <input
            type="text"
            {...register("name", { required: "Este campo é obrigatório" })}
            className="block w-full p-2 border rounded-md"
            placeholder="Título"
          />
          <p className="text-xs text-gray-500">
            Nome para o banner, será exibido somente no painel administrativo
          </p>
          {errors.name && (
            <span className="text-red-500">{errors.name.message}</span>
          )}
        </div>

        {/* Descrição */}
        <div>
          <label
            htmlFor="text"
            className="block text-sm font-medium text-gray-700"
          >
            Descrição
          </label>

          <input
            type="text"
            {...register("text", { required: "Este campo é obrigatório" })}
            className="block w-full p-2 border rounded-md"
            placeholder="Descrição"
          />
          <p className="text-xs text-gray-500">
            Descrição para o banner, será exibido somente no painel
            administrativo
          </p>
          {errors.text && (
            <span className="text-red-500">{errors.text.message}</span>
          )}
        </div>

        <div>
          <label
            htmlFor="href"
            className="block text-sm font-medium text-gray-700"
          >
            Link
          </label>

          <input
            type="text"
            {...register("href")}
            className="block w-full p-2 border rounded-md"
            placeholder="Href"
          />
          <p className="text-xs text-gray-500">
            Caminho de redirecionamento, este categoryID pode ser retirado do
            próprio site, ou na seção de categorias.
          </p>
          {errors.href && (
            <span className="text-red-500">{errors.href.message}</span>
          )}
        </div>

        <div>
          <label
            htmlFor="imgUrl"
            className="block text-sm font-medium text-gray-700"
          >
            Imagem
          </label>

          <input
            type="text"
            {...register("imgUrl")}
            className="block w-full p-2 border rounded-md"
            placeholder="URL da Imagem"
          />
          <p className="text-xs text-gray-500">Link da imagem</p>
          {errors.text && (
            <span className="text-red-500">{errors.imgUrl.message}</span>
          )}
        </div>

        <button
          type="submit"
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded transition ease-in-out duration-150"
        >
          Salvar Alterações
        </button>
      </form>
    </div>
  );
};

export default Edit;
