import React, { useEffect, useState } from "react";
import Create from "./Create";
import Edit from "./Edit";
import api from "../../api/api";
import Alerts from "../Alerts";

const View = () => {
  const [cards, setCards] = useState("");
  const [banners, setbanners] = useState([]);
  const [item, setItem] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [warning, setWarning] = useState({});

  useEffect(() => {
    // A função só será executada quando `cards` for uma string vazia.
    if (cards !== "") return;
    const fetchFeaturedProducts = async () => {
      try {
        const response = await api.get("/mosaics");
        setbanners(response.data.mosaics);
      } catch (error) {
        console.error("Erro ao buscar mosaicos:", error); // É bom manter o log de erros
        setWarning({
          type: "error",
          message: "Falha ao carregar os mosaicos do servidor!",
        });
        handleOpenSnackbar();
      }
    };

    fetchFeaturedProducts();
  }, [cards]); // Dependência `cards` para re-executar o efeito quando seu valor mudar.

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleposition = (position) => {
    switch (position) {
      case "LEFT":
        return 1;
      case "RIGHT":
        return 2;
      case "BOTTOM_LEFT":
        return 3;
      case "BOTTOM_RIGHT":
        return 4;
      default:
        return 0; // ou outro valor padrão que faça sentido para seu caso
    }
  };

  return (
    <div className="p-4">
      <div className="flex justify-between mb-4">
        <button
          onClick={() => {
            cards === "" ? setCards("create") : setCards("");
          }}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition-colors"
        >
          {cards === "" ? "Criar Mosaico" : "Voltar"}
        </button>
      </div>
      <div>
        <p>layout dos banners</p>
        <img src="https://i.imgur.com/wXF9UMw.jpg" alt="Layout banners" />
      </div>
      {cards === "create" ? (
        <Create
          setWarning={setWarning}
          handleOpenSnackbar={handleOpenSnackbar}
          setCards={setCards}
        />
      ) : cards === "edit" ? (
        <Edit
          setWarning={setWarning}
          handleOpenSnackbar={handleOpenSnackbar}
          item={item}
          setCards={setCards}
        />
      ) : banners && banners.length > 0 ? (
        <div className="space-y-6">
          {banners.map((banner, index) => (
            <div
              key={index}
              className="bg-white border rounded-lg overflow-hidden "
            >
              <>
                <div className="flex justify-between p-5 border-b border-gray-200">
                  <div>
                    <h2 className="text-xl font-bold text-black">
                      Mosaico: {banner.title}
                    </h2>
                    <h4>Posição: {handleposition(banner.position)}</h4>
                  </div>
                  <button
                    onClick={() => {
                      setCards("edit");
                      setItem(banner);
                    }}
                    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 transition-colors"
                  >
                    Editar Card
                  </button>
                </div>
                <div className="flex justify-evenly items-stretch p-1 ">
                  <div
                    key={banner.title}
                    className="group relative text-base sm:text-sm"
                  >
                    <div className="aspect-w-1 aspect-h-1 overflow-hidden flex justify-center rounded-lg bg-gray-50">
                      <img
                        src={banner.imgUrl}
                        alt="alt"
                        className="object-cover object-center h-56 w-56"
                      />
                    </div>
                    <a
                      href={banner.href}
                      className="mt-6 block font-medium text-black"
                    >
                      <span
                        className="absolute inset-0 z-10 "
                        aria-hidden="true"
                      />
                      {banner.title}
                    </a>
                    <p aria-hidden="true" className="mt-1">
                      {banner.text}
                    </p>
                  </div>
                </div>
              </>
            </div>
          ))}
        </div>
      ) : (
        "Carregando..."
      )}
      <Alerts
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        type={warning.type}
        message={warning.message}
      />
    </div>
  );
};

export default View;
