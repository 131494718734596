import React, { useState, useEffect } from "react";
import api from "../../api/api";
import TransferList from "../TransferList";
import { useForm, useFieldArray } from "react-hook-form";
import { Button } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";

const Edit = ({ setCards, setWarning, handleOpenSnackbar, item }) => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      categoryId: item.categoryId,
      viewProducts: item.viewProducts,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "viewProducts",
  });

  const addCard = () => {
    if (fields.length < 2) {
      append({ name: "", href: "", imgSrc: "", imgAlt: "", actionText: "" });
    }
  };

  const onSubmit = (formData) => {
    const requestData = {
      viewProducts: formData.viewProducts.map((product) => ({
        ...product,
        href: `/produtos?category=${product.href}`,
      })),
      categoryId: formData.categoryId,
    };

    api
      .post(`/categoriesFeatured`, requestData)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setWarning({
            type: "success",
            message: "Card editado com sucesso!",
          });
          handleOpenSnackbar();
          setCards("");
        } else {
          setWarning({
            type: "warning",
            message: "Falha ao editar o card.",
          });
          handleOpenSnackbar();
        }
      })
      .catch((error) => {
        let errorMessage = "Falha ao editar o card.";
        if (error.response) {
          errorMessage += ` Detalhes: ${
            error.response.data.message || error.response.status
          }`;
        } else if (error.request) {
          errorMessage = "O servidor não respondeu ao pedido.";
        } else {
          errorMessage = "Erro ao enviar o pedido para o servidor.";
        }
        setWarning({
          type: "error",
          message: errorMessage,
        });
        handleOpenSnackbar();
      });
  };

  return (
    <div className=" mx-auto p-5 ">
      <h1 className="text-2xl font-bold mb-5 text-gray-800">
        Editar os Produtos em destaque no dropdown.
      </h1>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-4 max-w-xl mx-auto my-10"
      >
        <div className="flex flex-col">
          <label
            htmlFor="categoryId"
            className="mb-2 font-medium text-gray-700"
          >
            Categoria:
          </label>
          <input
            {...register("categoryId", { required: true })}
            type="text"
            className="mt-1 block w-full p-2 border rounded-md"
            placeholder="Ex: 65eb23aa360e2d56bf94aa24"
          />
          {errors.categoryId && (
            <span className="text-red-500">Este campo é obrigatório</span>
          )}
        </div>
        {fields.map((item, index) => (
          <div key={item.id}>
            <div className="flex flex-col">
              <label
                htmlFor={`viewProducts[${index}].name`}
                className="mb-2 font-medium text-gray-700"
              >
                Nome do Produto:
              </label>
              <input
                {...register(`viewProducts[${index}].name`, { required: true })}
                type="text"
                className="mt-1 block w-full p-2 border rounded-md"
              />
              {errors.viewProducts &&
                errors.viewProducts[index] &&
                errors.viewProducts[index].name && (
                  <span className="text-red-500">Este campo é obrigatório</span>
                )}
            </div>

            <div className="flex flex-col">
              <label
                htmlFor={`viewProducts[${index}].href`}
                className="mb-2 font-medium text-gray-700"
              >
                Link do Produto:
              </label>
              <input
                {...register(`viewProducts[${index}].href`, { required: true })}
                type="text"
                className="mt-1 block w-full p-2 border rounded-md"
              />
              {errors.viewProducts &&
                errors.viewProducts[index] &&
                errors.viewProducts[index].href && (
                  <span className="text-red-500">Este campo é obrigatório</span>
                )}
            </div>
            <div className="flex flex-col">
              <label
                htmlFor={`viewProducts[${index}].imgSrc`}
                className="mb-2 font-medium text-gray-700"
              >
                Imagem do Produto:
              </label>
              <input
                {...register(`viewProducts[${index}].imgSrc`, {
                  required: true,
                })}
                type="text"
                className="mt-1 block w-full p-2 border rounded-md"
                placeholder="Ex: https://i.imgur.com/mDVlxI2.png"
              />{" "}
              {errors.viewProducts &&
                errors.viewProducts[index] &&
                errors.viewProducts[index].imgSrc && (
                  <span className="text-red-500">Este campo é obrigatório</span>
                )}
            </div>

            <div className="flex flex-col">
              <label
                htmlFor={`viewProducts[${index}].imgAlt`}
                className="mb-2 font-medium text-gray-700"
              >
                Descrição da Imagem:
              </label>
              <input
                {...register(`viewProducts[${index}].imgAlt`, {
                  required: true,
                })}
                type="text"
                className="mt-1 block w-full p-2 border rounded-md"
                placeholder="Descreva a imagem para facilitar mecanismos de busca."
              />{" "}
              {errors.viewProducts &&
                errors.viewProducts[index] &&
                errors.viewProducts[index].imgAlt && (
                  <span className="text-red-500">Este campo é obrigatório</span>
                )}
            </div>

            <div className="flex flex-col">
              <label
                htmlFor={`viewProducts[${index}].actionText`}
                className="mb-2 font-medium text-gray-700"
              >
                Texto de ação:
              </label>
              <input
                {...register(`viewProducts[${index}].actionText`, {
                  required: true,
                })}
                type="text"
                className="mt-1 block w-full p-2 border rounded-md"
                placeholder="Ex: Veja todos os produtos..."
              />{" "}
              {errors.viewProducts &&
                errors.viewProducts[index] &&
                errors.viewProducts[index].actionText && (
                  <span className="text-red-500">Este campo é obrigatório</span>
                )}
            </div>
            <Button
              startIcon={<CloseOutlined />}
              sx={{ mt: 1 }}
              onClick={() => remove(index)}
            >
              Remover Produto
            </Button>
          </div>
        ))}
        {fields.length < 2 && (
          <Button onClick={addCard} variant="contained" sx={{ mt: 1 }}>
            Adicionar Produto
          </Button>
        )}

        <button
          type="submit"
          className="w-full px-4 py-2 bg-blue-500 text-white font-medium rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          Salvar
        </button>
      </form>
    </div>
  );
};

export default Edit;
