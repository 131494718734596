import React, { useEffect, useState } from "react";
import { Header } from "../components";
import SellersTable from "../components/Sellers/Tabela";
import api from "../api/api";
import Create from "../components/Sellers/Create";
import Alerts from "../components/Alerts";
import Edit from "../components/Sellers/Edit";

const Sellers = () => {
  const [sellersData, setsellersData] = useState([]);
  const [userToEdit, setUserToEdit] = useState({});
  const [step, setStep] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [warning, setWarning] = useState({});

  useEffect(() => {
    try {
      api.get("sellers").then((res) => {
        res.status === 200
          ? setsellersData(res.data.sellers)
          : setsellersData([]);
      });
    } catch (error) {}
  }, []);

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleSave = () => {
    try {
      api.get("sellers").then((res) => {
        if (res.status === 200) {
          setsellersData(res.data.sellers);
          setStep("");
          setWarning({
            type: "success",
            message: "Lista de Vendedores Atualizados!",
          });
          handleOpenSnackbar();
        } else {
          setsellersData([]);
        }
      });
    } catch (error) {
      setWarning({
        type: "error",
        message: "Falha ao carregar os Vendedores!",
      });
      handleOpenSnackbar();
    }
  };

  const handleEdit = (client) => {
    setUserToEdit(client);
    setStep("edit");
  };

  const handleUpdate = async (id, data) => {
    try {
      await api.put(`users/${id}`, data);
      setWarning({
        type: "success",
        message: "Vendedor Atualizado!",
      });
      handleOpenSnackbar();
      // Atualiza a lista de Vendedores após a edição
      const res = await api.get("sellers");
      if (res.status === 200) {
        setsellersData(res.data.sellers);
      }
    } catch (error) {
      setWarning({
        type: "error",
        message: "Erro ao atualizar Vendedor.",
      });
      handleOpenSnackbar();
    }
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Administrativo" title="Vendedores" />
      <div className="flex justify-between items-center mb-10">
        <button
          onClick={() => setStep(step === "create" ? "" : "create")}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          {step === "create" ? "Voltar" : "Criar Novo Vendedor"}
        </button>
      </div>
      {step === "create" ? (
        <Create
          onSave={handleSave}
          setWarning={setWarning}
          handleOpenSnackbar={handleOpenSnackbar}
        />
      ) : step === "edit" ? (
        userToEdit && (
          <Edit
            seller={userToEdit}
            setWarning={setWarning}
            handleOpenSnackbar={handleOpenSnackbar}
            onClose={() => setStep("")}
            onSave={handleUpdate}
          />
        )
      ) : (
        <SellersTable clients={sellersData} onEdit={handleEdit} />
      )}
      <Alerts
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        type={warning.type}
        message={warning.message}
      />
    </div>
  );
};

export default Sellers;
