import { useState } from "react";
import { useForm } from "react-hook-form";
import api from "../../api/api";
import {
  Button,
  TextField,
  TextareaAutosize,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import DeleteIcon from "@mui/icons-material/Delete";

export const Edit = ({ categoryToEdit, setCategoryToEdit, setStep }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: categoryToEdit.name,
      description: categoryToEdit.description || "",
    },
  });
  const [openDeleteProduct, setOpenDeleteProduct] = useState(false);
  const [productToEdit, setProductToEdit] = useState({});
  const [productIDs, setProductIDs] = useState([]);

  const onSubmit = (data) => {
    const sendData = {
      name: data.name,
      disconnectProducts: productIDs,
    };
    api
      .put(`/categories/${categoryToEdit.id}`, sendData)
      .then((res) => {
        if (res.status === 200) {
          setStep("");
        }
      })
      .catch((error) => console.log(error));
    console.log("Dados do formulário enviados:", data);
  };

  const handleRemoveProduct = () => {
    // Remove o produto da lista visualmente
    const updatedProducts = categoryToEdit.products.filter(
      (product) => product.id !== productToEdit.id
    );
    // Adiciona o ID do produto aos IDs dos produtos a serem removidos
    setProductIDs([...productIDs, productToEdit.id]);
    // Atualiza a lista de produtos na categoria
    setCategoryToEdit({ ...categoryToEdit, products: updatedProducts });
    setOpenDeleteProduct(false);
  };

  return (
    <>
      <div>
        <h2 className="text-lg font-semibold">
          Editando Categoria: {categoryToEdit.name}
        </h2>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-4 space-y-4">
          <div>
            <TextField
              {...register("name", { required: "Nome é obrigatório" })}
              label="Nome"
              variant="outlined"
              fullWidth
              error={errors.name ? true : false}
              helperText={errors.name ? errors.name.message : ""}
            />
          </div>
          <div>
            <TextareaAutosize
              {...register("description")}
              placeholder="Descrição"
              rows={4}
              className="w-full border rounded-md p-2"
            />
          </div>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" component="div" mt={4}>
                  Produtos na Categoria
                </Typography>
                <List className="max-h-[800px] overflow-auto" dense={true}>
                  {categoryToEdit.products.map((product) => (
                    <>
                      <ListItem key={product.id}>
                        <ListItemText primary={product.name} />
                        <ListItemSecondaryAction>
                          <Tooltip title="Desvincular Produto">
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              color="error"
                              onClick={() => {
                                setOpenDeleteProduct(true);
                                setProductToEdit(product);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </>
                  ))}
                </List>
              </Grid>
            </Grid>
          </div>

          <Button type="submit" variant="contained" color="success">
            Salvar
          </Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            sx={{ marginLeft: 1 }}
            onClick={() => setStep("")}
          >
            Voltar
          </Button>
        </form>
      </div>

      {/* Modal para Excluir Categoria */}
      <Dialog
        open={openDeleteProduct}
        onClose={() => setOpenDeleteProduct(false)}
      >
        <DialogTitle>Confirmar Desvinculação do Produto</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza de que deseja desvincular o produto{" "}
            <span className="text-black underline">{productToEdit.name}</span>{" "}
            da categoria{" "}
            <span className="text-black underline">{categoryToEdit.name}</span>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteProduct(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleRemoveProduct} color="error">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
