import React, { useEffect, useState } from "react";
import { Header } from "../../components";
import { useForm } from "react-hook-form";
import api from "../../api/api";
import {
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from "react-icons/ai"; // Importando ícones de setas

const VendasProdutos = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [report, setReport] = useState({ result: {}, status: false });
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });

  const onSubmit = (formData) => {
    const formattedData = {
      ...formData,
      startDate: formatDate(formData.startDate),
      endDate: formatDate(formData.endDate),
    };

    api
      .get(
        `reports/best-selling-products?startDate=${formattedData.startDate}&endDate=${formattedData.endDate}&ranking=${formattedData.ranking}`
      )
      .then((response) => setReport({ result: response.data, status: true }))
      .catch((error) => console.error(error));
  };

  const formatDate = (date) => {
    const [day, month, year] = date.split("-");
    return `${year}-${month}-${day}`;
  };

  const sortedProducts = Array.isArray(report.result.Products)
    ? [...report.result.Products].sort((a, b) => {
        if (sortConfig.key) {
          const aValue = a[sortConfig.key];
          const bValue = b[sortConfig.key];

          return sortConfig.direction === "asc"
            ? Number(aValue) - Number(bValue)
            : Number(bValue) - Number(aValue);
        }

        return 0; // Não classifica se não houver chave
      })
    : [];

  useEffect(() => {
    console.log(sortedProducts);
  }, [sortConfig]);

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const formatCurrency = (value) => {
    const numberValue = Number(value);
    if (isNaN(numberValue)) {
      return "R$ 0,00";
    }
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(numberValue);
  };

  const convertToCSV = (data) => {
    const csvRows = [];
    const headers = [
      "ID do Produto",
      "Nome",
      "Categorias",
      "Imagem",
      "Preço (R$)",
      "Custo do Produto (R$)",
      "Quantidade Vendida",
      "Total (R$)",
      "Custo Total (R$)",
      "Lucro (R$)",
    ];
    csvRows.push(headers.join(","));

    data.Products.forEach((product) => {
      const row = [
        product.orderProductId,
        product.name,
        product.categoryNames.join(" | "),
        product.imgUrl,
        formatCurrency(product.price),
        formatCurrency(product.productCost),
        product.amountSold,
        formatCurrency(product.total),
        formatCurrency(product.TotalCost),
        formatCurrency(product.profit),
      ];
      csvRows.push(row.join(","));
    });

    return csvRows.join("\n");
  };

  const downloadCSV = () => {
    const csvData = convertToCSV(report.result);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `relatorio_vendas_${report.result.startDate}_to_${report.result.endDate}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Relatorios" title="VENDA DE PRODUTOS" />
      <div className=" mx-auto p-5 ">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-md space-y-4"
        >
          <div className="flex flex-col">
            <label
              htmlFor="startDate"
              className="text-sm font-medium text-gray-700"
            >
              Data Inicial da busca (DD-MM-AAAA)
            </label>
            <input
              type="text"
              id="startDate"
              maxLength="10"
              {...register("startDate", {
                required: "Data final é obrigatória",
                pattern: {
                  value: /^(0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-\d{4}$/,
                  message: "Data inválida, use o formato DD-MM-AAAA",
                },
              })}
              placeholder="DD-MM-AAAA"
              className="mt-1 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#312e81]"
              onInput={(e) => {
                e.target.value = e.target.value.replace(
                  /^(\d{2})(\d{2})(\d{4})$/,
                  "$1-$2-$3"
                );
              }}
            />
            {errors.startDate && (
              <span className="text-red-500 text-sm">
                {errors.startDate.message}
              </span>
            )}
          </div>

          <div className="flex flex-col">
            <label
              htmlFor="endDate"
              className="text-sm font-medium text-gray-700"
            >
              Data Final da busca (DD-MM-AAAA)
            </label>
            <input
              type="text"
              id="endDate"
              maxLength="10"
              {...register("endDate", {
                required: "Data final é obrigatória",
                pattern: {
                  value: /^(0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-\d{4}$/,
                  message: "Data inválida, use o formato DD-MM-AAAA",
                },
              })}
              placeholder="DD-MM-AAAA"
              className="mt-1 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#312e81]"
              onInput={(e) => {
                e.target.value = e.target.value.replace(
                  /^(\d{2})(\d{2})(\d{4})$/,
                  "$1-$2-$3"
                );
              }}
            />
            {errors.endDate && (
              <span className="text-red-500 text-sm">
                {errors.endDate.message}
              </span>
            )}
          </div>

          <div className="flex flex-col">
            <label
              htmlFor="ranking"
              className="text-sm font-medium text-gray-700"
            >
              Ranking
            </label>
            <input
              type="number"
              id="ranking"
              {...register("ranking", {
                required: "Ranking é obrigatório",
              })}
              className="mt-1 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#312e81]"
            />
            {errors.ranking && (
              <span className="text-red-500 text-sm">
                {errors.ranking.message}
              </span>
            )}
          </div>

          <button
            type="submit"
            className="mt-4 p-2 bg-blue-600 text-white rounded-md"
          >
            Enviar
          </button>
        </form>
      </div>

      {report.status && (
        <div className="overflow-x-auto">
          <h2 className="text-lg font-semibold mb-4">
            Relatório de Vendas de Produtos ({report.result.startDate} Até{" "}
            {report.result.endDate})
          </h2>
          <button
            onClick={downloadCSV}
            className="mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Baixar Relatório em CSV
          </button>
          <table className="min-w-full border border-gray-200">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-2 border-b">Imagem</th>
                <th className="p-2 border-b">Nome</th>
                <th className="p-2 border-b">Categorias</th>
                <th
                  className="p-2 border-b cursor-pointer"
                  onClick={() => requestSort("price")}
                >
                  Preço (R$)
                  {sortConfig.key === "price" &&
                    (sortConfig.direction === "asc" ? (
                      <AiOutlineSortAscending className="inline ml-2" />
                    ) : (
                      <AiOutlineSortDescending className="inline ml-2" />
                    ))}
                </th>
                <th
                  className="p-2 border-b cursor-pointer"
                  onClick={() => requestSort("productCost")}
                >
                  Custo do Produto (R$){" "}
                  {sortConfig.key === "productCost" &&
                    (sortConfig.direction === "asc" ? (
                      <AiOutlineSortAscending className="inline ml-2" />
                    ) : (
                      <AiOutlineSortDescending className="inline ml-2" />
                    ))}
                </th>
                <th
                  className="p-2 border-b cursor-pointer"
                  onClick={() => requestSort("amountSold")}
                >
                  Quantidade Vendida{" "}
                  {sortConfig.key === "amountSold" &&
                    (sortConfig.direction === "asc" ? (
                      <AiOutlineSortAscending className="inline ml-2" />
                    ) : (
                      <AiOutlineSortDescending className="inline ml-2" />
                    ))}
                </th>
                <th
                  className="p-2 border-b cursor-pointer"
                  onClick={() => requestSort("total")}
                >
                  Total (R$){" "}
                  {sortConfig.key === "total" &&
                    (sortConfig.direction === "asc" ? (
                      <AiOutlineSortAscending className="inline ml-2" />
                    ) : (
                      <AiOutlineSortDescending className="inline ml-2" />
                    ))}
                </th>
                <th
                  className="p-2 border-b cursor-pointer"
                  onClick={() => requestSort("TotalCost")}
                >
                  Custo Total (R$){" "}
                  {sortConfig.key === "TotalCost" &&
                    (sortConfig.direction === "asc" ? (
                      <AiOutlineSortAscending className="inline ml-2" />
                    ) : (
                      <AiOutlineSortDescending className="inline ml-2" />
                    ))}
                </th>
                <th
                  className="p-2 border-b cursor-pointer"
                  onClick={() => requestSort("profit")}
                >
                  Lucro (R$){" "}
                  {sortConfig.key === "profit" &&
                    (sortConfig.direction === "asc" ? (
                      <AiOutlineSortAscending className="inline ml-2" />
                    ) : (
                      <AiOutlineSortDescending className="inline ml-2" />
                    ))}
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedProducts.map((product) => (
                <tr
                  key={product.orderProductId + product.price}
                  className="text-center border-b"
                >
                  <td className="p-2">
                    <img
                      src={product.imgUrl}
                      alt={product.name}
                      className="w-16 h-16 object-cover rounded"
                    />
                  </td>
                  <td className="p-2">{product.name}</td>
                  <td className="p-2">{product.categoryNames.join(", ")}</td>
                  <td className="p-2">{formatCurrency(product.price)}</td>
                  <td className="p-2">{formatCurrency(product.productCost)}</td>
                  <td className="p-2">{product.amountSold}</td>
                  <td className="p-2">{formatCurrency(product.total)}</td>
                  <td className="p-2">{formatCurrency(product.TotalCost)}</td>
                  <td className="p-2">{formatCurrency(product.profit)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default VendasProdutos;
