import React from "react";
import { Header } from "../components";
import View from "../components/Navbar/View";

const NavFeatured = () => {
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Navbar" title="Destaques" />
      <View />
    </div>
  );
};

export default NavFeatured;
