import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Create from "../components/Voucher/Create";
import Edit from "../components/Voucher/Edit";
import Loading from "../components/Loading";
import api from "../api/api";

const Voucher = () => {
  const [step, setStep] = useState("");
  const [cupom, setCupom] = useState([]);
  const [idToEdit, setIdToEdit] = useState("");

  useEffect(() => {
    if (step !== "") return;

    const fetchVouchers = async () => {
      try {
        const res = await api.get("vouchers");
        if (res.status === 200) {
          setCupom(res.data.vouchers);
        }
      } catch (error) {
        console.error("Erro ao buscar cupons:", error);
      }
    };

    fetchVouchers();
  }, [step]);

  const handleCreateClick = () => setStep("create");
  const handleEditClick = (id) => {
    setIdToEdit(id);
    setStep("edit");
    console.log("click no Edit");
  };

  return (
    <div className="m-4 md:m-8 mt-24 p-4 md:p-8 bg-white rounded-lg shadow-lg">
      <Header category="Promoções" title="Cupons" />
      {step === "create" ? (
        <Create setStep={setStep} />
      ) : step === "edit" ? (
        <Edit setStep={setStep} cupomId={idToEdit} />
      ) : step === "" && cupom.length > 0 ? (
        <div className="p-4">
          <div className="flex justify-between items-center mb-4 border-b-1">
            <button
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mb-1"
              onClick={handleCreateClick}
            >
              Criar Novo Cupom
            </button>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {cupom.map((coupon) => (
              <div
                key={coupon.id}
                className="bg-white border border-gray-200 rounded-lg shadow-md p-4"
              >
                <div className="flex justify-between">
                  <h3 className="text-lg text-black font-semibold">
                    {coupon.name}
                  </h3>
                  <button
                    className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
                    onClick={() => handleEditClick(coupon.id)}
                  >
                    Editar
                  </button>
                </div>
                <p className="text-black mb-2 text-sm">{coupon.description}</p>
                <p className="text-black mb-2">
                  Uso único:{" "}
                  <span className="underline font-semibold">
                    {coupon.isUnique ? "Sim" : "Não"}
                  </span>
                </p>
                <p className="text-black mb-2">
                  Código:{" "}
                  <span className="underline font-semibold">{coupon.code}</span>
                </p>
                <p className="text-gray-600 mb-2">
                  Desconto:{" "}
                  <span className="underline font-semibold">
                    {coupon.discount}%
                  </span>
                </p>
                <p className="text-black mb-4 ">
                  Expira em:{" "}
                  <span className="underline font-semibold text-black">
                    {new Intl.DateTimeFormat("pt-BR").format(
                      new Date(coupon.expiresIn)
                    )}
                  </span>
                </p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Voucher;
