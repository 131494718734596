import React, { useCallback, useEffect, useState } from "react";
import Create from "../components/Categorias/Create";
import { View } from "../components/Categorias/View";
import { Edit } from "../components/Categorias/Edit";
import { Header } from "../components";
import api from "../api/api";
import { IconButton, InputBase, Paper, Stack, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";
import { useStateContext } from "../contexts/ContextProvider";

const Category = () => {
  const { currentColor } = useStateContext();
  const [option, setOption] = useState("view");
  const [step, setStep] = useState("");
  const [category, setCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [form, setForm] = useState({});
  const [filters, setFilters] = useState({ name: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const debouncedSearch = useCallback(
    debounce(() => {
      api
        .get(`/categories/nested?name=${filters.name}`)
        .then((res) => {
          setCategory(res.data.categories);
        })
        .catch((error) => {
          console.error("Erro ao carregar as categorias:", error);
        });
    }, 500),
    [filters.name, currentPage]
  );

  const handleSearchChange = (event) => {
    setFilters({ ...filters, name: event.target.value });
  };

  const handlePaginationChange = (event, page) => {
    event.preventDefault();
    setCurrentPage(page);
  };

  useEffect(() => {
    api.get("categories/nested").then((response) => {
      setCategory(response.data.categories);
    });
  }, [option, step]);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      {option === "create" ? (
        <>
          <Header category="Produto" title="Nova Categoria" />
          <Create setOption={setOption} categories={category} />
        </>
      ) : option === "edit" && selectedCategory ? ( // Garantir que selectedCategory não é nulo
        <>
          <Header category="Produto" title="Editar Categoria" />
          <Edit selectedCategory={selectedCategory} setOption={setOption} />
        </>
      ) : category?.length > 0 ? (
        <>
          <Header category="Produto" title="Categorias" />
          <Stack
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            padding={1}
            direction="row"
            gap={2}
          >
            <Typography variant="h5">{"Categorias"}</Typography>
            <Paper
              component="form"
              sx={{ display: "flex", alignItems: "center", width: 400 }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={"Procurar Categoria"}
                inputProps={{ "aria-label": "procurar produto" }}
                value={filters.name}
                onChange={handleSearchChange}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
            <div className="flex flex-col  md:flex-row">
              <button
                style={{ backgroundColor: currentColor }}
                className="ml-2 inline-block text-white py-1 px-3 rounded-full text-sm cursor-pointer  transition-colors"
                onClick={() => setOption("create")}
              >
                Adicionar Categoria
              </button>
            </div>
          </Stack>
          <View
            category={category}
            setOption={setOption}
            setSelectedCategory={setSelectedCategory}
            step={step}
            setStep={setStep}
          />
        </>
      ) : category?.length === 0 ? (
        <>
          <Header category="Produto" title="Categorias" />
          <Stack
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            padding={1}
            direction="row"
            gap={2}
          >
            <Typography variant="h5">{"Categorias"}</Typography>
            <Paper
              component="form"
              sx={{ display: "flex", alignItems: "center", width: 400 }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={"Procurar Categoria"}
                inputProps={{ "aria-label": "procurar produto" }}
                value={filters.name}
                onChange={handleSearchChange}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
            <div className="flex flex-col  md:flex-row">
              <button
                style={{ backgroundColor: currentColor }}
                className="ml-2 inline-block text-white py-1 px-3 rounded-full text-sm cursor-pointer  transition-colors"
                onClick={() => setOption("create")}
              >
                Adicionar Categoria
              </button>
            </div>
          </Stack>
          Nenhuma categoria encontrada.
        </>
      ) : (
        <>
          <Header category="Produto" title="Categorias" />
          <Stack
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            padding={1}
            direction="row"
            gap={2}
          >
            <Typography variant="h5">{"Categorias"}</Typography>
            <Paper
              component="form"
              sx={{ display: "flex", alignItems: "center", width: 400 }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={"Procurar Categoria"}
                inputProps={{ "aria-label": "procurar produto" }}
                value={filters.name}
                onChange={handleSearchChange}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
            <div className="flex flex-col  md:flex-row">
              <button
                style={{ backgroundColor: currentColor }}
                className="ml-2 inline-block text-white py-1 px-3 rounded-full text-sm cursor-pointer  transition-colors"
                onClick={() => setOption("create")}
              >
                Adicionar Categoria
              </button>
            </div>
          </Stack>
          Carregando...
        </>
      )}
    </div>
  );
};

export default Category;
