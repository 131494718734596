import ReactDOM from "react-dom";
import App from "./App";
import { ContextProvider } from "./contexts/ContextProvider";
import { AuthProvider } from "./contexts/AuthContext";
import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
} from "react-router-dom";
import { Context as AuthContext } from "./contexts/AuthContext";
import Loading from "./components/Loading";
import LoginForm from "./components/LoginForm";
import Mosaico from "./pages/Mosaico";
import {
  Ecommerce,
  Orders,
  Calendar,
  Employees,
  Stacked,
  Pyramid,
  Customers,
  Kanban,
  Line,
  Area,
  Bar,
  Pie,
  Financial,
  ColorPicker,
  ColorMapping,
  Editor,
  ShowProducts,
  Featured,
  MelhorEnvio,
  Category,
  CreateBanner,
  CreateBanners,
  FaixaTexto,
  Nfce,
  NewsLetters,
  Voucher,
  Promotions,
  Sellers,
} from "./pages";
import "./index.css";
import { useContext } from "react";
import NavFeatured from "./pages/NavFeatured";
import VendasProdutos from "./pages/Reports/VendasProdutos";

function ProtectedRoute({ children }) {
  const { authenticated, loading } = useContext(AuthContext);

  if (loading) {
    // You can render a loading indicator here while checking authentication
    return <Loading />;
  }

  if (!authenticated) {
    // Save the intended location in local storage or state
    localStorage.setItem("intendedLocation", window.location.pathname);

    // Redirect them to the /login page
    return <Navigate to="/login" replace />;
  }

  // If authenticated, render the protected route
  return children;
}
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: (
          <ProtectedRoute>
            <Ecommerce />
          </ProtectedRoute>
        ),
      },
      {
        path: "ecommerce",
        element: (
          <ProtectedRoute>
            <Ecommerce />
          </ProtectedRoute>
        ),
      },
      {
        path: "sales",
        element: (
          <ProtectedRoute>
            <Orders />
          </ProtectedRoute>
        ),
      },
      {
        path: "employees",
        element: (
          <ProtectedRoute>
            <Employees />
          </ProtectedRoute>
        ),
      },
      {
        path: "sellers",
        element: (
          <ProtectedRoute>
            <Sellers />
          </ProtectedRoute>
        ),
      },
      {
        path: "customers",
        element: (
          <ProtectedRoute>
            <Customers />
          </ProtectedRoute>
        ),
      },
      {
        path: "produtos",
        element: (
          <ProtectedRoute>
            <ShowProducts />
          </ProtectedRoute>
        ),
      },
      {
        path: "faixa-texto",
        element: (
          <ProtectedRoute>
            <FaixaTexto />
          </ProtectedRoute>
        ),
      },
      {
        path: "mosaico",
        element: (
          <ProtectedRoute>
            <Mosaico />
          </ProtectedRoute>
        ),
      },
      {
        path: "destaques",
        element: (
          <ProtectedRoute>
            <Featured />
          </ProtectedRoute>
        ),
      },
      {
        path: "navbar/destaques",
        element: (
          <ProtectedRoute>
            <NavFeatured />
          </ProtectedRoute>
        ),
      },
      {
        path: "categorias",
        element: (
          <ProtectedRoute>
            <Category />
          </ProtectedRoute>
        ),
      },
      {
        path: "banners",
        element: (
          <ProtectedRoute>
            <CreateBanner />
          </ProtectedRoute>
        ),
      },
      {
        path: "banners/body",
        element: (
          <ProtectedRoute>
            <CreateBanners />
          </ProtectedRoute>
        ),
      },
      {
        path: "token",
        element: (
          <ProtectedRoute>
            <MelhorEnvio />
          </ProtectedRoute>
        ),
      },
      {
        path: "newsletters",
        element: (
          <ProtectedRoute>
            <NewsLetters />
          </ProtectedRoute>
        ),
      },
      {
        path: "promos",
        element: (
          <ProtectedRoute>
            <Promotions />
          </ProtectedRoute>
        ),
      },
      {
        path: "vouchers",
        element: (
          <ProtectedRoute>
            <Voucher />
          </ProtectedRoute>
        ),
      },
      {
        path: "nfce",
        element: (
          <ProtectedRoute>
            <Nfce />
          </ProtectedRoute>
        ),
      },
      {
        path: "reports/products-sales",
        element: (
          <ProtectedRoute>
            <VendasProdutos />
          </ProtectedRoute>
        ),
      },
      {
        path: "editor",
        element: (
          <ProtectedRoute>
            <Editor />
          </ProtectedRoute>
        ),
      },
      {
        path: "calendar",
        element: (
          <ProtectedRoute>
            <Calendar />
          </ProtectedRoute>
        ),
      },
      {
        path: "color-picker",
        element: (
          <ProtectedRoute>
            <ColorPicker />
          </ProtectedRoute>
        ),
      },
      {
        path: "line",
        element: (
          <ProtectedRoute>
            <Line />
          </ProtectedRoute>
        ),
      },
      {
        path: "area",
        element: (
          <ProtectedRoute>
            <Area />
          </ProtectedRoute>
        ),
      },
      {
        path: "bar",
        element: (
          <ProtectedRoute>
            <Bar />
          </ProtectedRoute>
        ),
      },
      {
        path: "pie",
        element: (
          <ProtectedRoute>
            <Pie />
          </ProtectedRoute>
        ),
      },
      {
        path: "financial",
        element: (
          <ProtectedRoute>
            <Financial />
          </ProtectedRoute>
        ),
      },
      {
        path: "color-mapping",
        element: (
          <ProtectedRoute>
            <ColorMapping />
          </ProtectedRoute>
        ),
      },
      {
        path: "pyramid",
        element: (
          <ProtectedRoute>
            <Pyramid />
          </ProtectedRoute>
        ),
      },
      {
        path: "stacked",
        element: (
          <ProtectedRoute>
            <Stacked />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/login",
    element: <LoginForm />,
  },
  {
    path: "*",
    element: <App />,
  },
]);

ReactDOM.render(
  <AuthProvider>
    <ContextProvider>
      <RouterProvider router={router} />
    </ContextProvider>
  </AuthProvider>,
  document.getElementById("root")
);
