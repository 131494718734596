import React, { useEffect, useRef, useState } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  ExcelExport,
  PdfExport,
  Edit,
  Inject,
} from "@syncfusion/ej2-react-grids";
import { L10n, setCulture } from "@syncfusion/ej2-base";
import { contextMenuItems } from "../data/dummy";
import { Header } from "../components";
import api from "../api/api";
import UniqueOrder from "./UniqueOrder";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListIcon from "@mui/icons-material/List";
import {
  Divider,
  Typography,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useStateContext } from "../contexts/ContextProvider";
import Alerts from "../components/Alerts";
import ChooseFreight from "../components/Compras/ChooseFreight";
import Loading from "../components/Loading";
import Create from "../components/Compras/NF/Create";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";

const Orders = () => {
  const [ordersData, setOrdersData] = useState(null);
  const [uniqueOrder, setUniqueOrder] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [warning, setWarning] = useState({});
  const [orderSeted, setOrderSeted] = useState(null);
  const [organizeOrder, setOrganizeOrder] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);

  let navigate = useNavigate();

  useEffect(() => {
    api
      .get("/orders")
      .then((response) => {
        // Verifica se o componente ainda está montado
        const orders = response.data.orders;

        // Ordena as ordens por createdAt
        const sortedOrders = orders.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );

        // Adiciona o campo NPedido sequencial
        const ordersWithNPedido = sortedOrders.map((order, index) => ({
          ...order,
          NPedido: index + 1,
        }));

        // Ordena novamente por updatedAt
        const newSorted = ordersWithNPedido.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );

        // Filtra os pedidos indesejados
        const newFilteredOrders = newSorted.filter(
          (order) =>
            !["ORDEM_CANCELADA", "ORDEM_FINALIZADA"].includes(order.orderStatus)
        );

        // Atualiza o estado com as ordens filtradas
        setOrdersData(ordersWithNPedido);
        setFilteredOrders(newFilteredOrders);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    let newFilteredOrders = ordersData ? ordersData : [];
    if (organizeOrder === "andamento") {
      // Filtra os pedidos para excluir os com status indesejados
      newFilteredOrders = ordersData.filter(
        (order) =>
          !["ORDEM_CANCELADA", "ORDEM_FINALIZADA", "PEDIDO_ENTREGUE"].includes(
            order.orderStatus
          )
      );

      // Ordena os pedidos pelos mais recentes
      newFilteredOrders = newFilteredOrders.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );
    } else if (organizeOrder === "tudo") {
      newFilteredOrders = ordersData.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );
    } else if (organizeOrder === "pago") {
      newFilteredOrders = ordersData.filter(
        (order) => order.orderStatus === "PAGAMENTO_REALIZADO"
      );
    } else if (organizeOrder === "separacao") {
      newFilteredOrders = ordersData.filter(
        (order) => order.orderStatus === "EM_SEPARACAO"
      );
    } else if (organizeOrder === "rota") {
      newFilteredOrders = ordersData.filter(
        (order) => order.shippingStatus === "EM_TRANSITO"
      );
    } else if (organizeOrder === "entregue") {
      newFilteredOrders = ordersData.filter(
        (order) => order.shippingStatus === "ENTREGUE"
      );
    } else if (organizeOrder === "finalizado") {
      newFilteredOrders = ordersData.filter(
        (order) => order.orderStatus === "ORDEM_FINALIZADA"
      );
    } else if (organizeOrder === "problema") {
      newFilteredOrders = ordersData.filter(
        (order) => order.orderStatus === "PROBLEMAS_PAGAMENTO"
      );
    } else if (organizeOrder === "cancelado") {
      newFilteredOrders = ordersData.filter(
        (order) => order.orderStatus === "ORDEM_CANCELADA"
      );
    }
    setFilteredOrders(newFilteredOrders);
  }, [organizeOrder]);

  useEffect(() => {
    ordersData ? setLoaded(true) : setLoaded(false);
  }, [ordersData]);

  setCulture("pt-BR");

  L10n.load({
    "pt-BR": {
      grid: {
        EmptyRecord: "Nenhum registro para exibir",
        // Adicione outras traduções necessárias aqui
      },
    },
  });

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleRowSelect = (args) => {
    // Obtendo o objeto de pedido correspondente ao índice da linha selecionada
    const selectedOrder = filteredOrders[args.rowIndex];
    // Atualizando o estado uniqueOrder com o objeto de pedido selecionado
    setUniqueOrder(selectedOrder);
  };

  const gridOrderStatus = (props) => (
    <button
      type="button"
      style={{
        background:
          props.orderStatus === "CRIADO"
            ? "#B3B3B3"
            : props.orderStatus === "AGUARDANDO_PAGAMENTO"
            ? "#F2F20C"
            : props.orderStatus === "PROBLEMAS_PAGAMENTO"
            ? "#F3C211"
            : props.orderStatus === "PAGAMENTO_RECUSADO"
            ? "#FF4D4D"
            : props.orderStatus === "PAGAMENTO_REALIZADO"
            ? "#FFD400"
            : props.orderStatus === "EM_SEPARACAO"
            ? "#FFAA00"
            : props.orderStatus === "PEDIDO_SEPARADO"
            ? "#F28B24"
            : props.orderStatus === "AGUARDANDO_COLETA"
            ? "#F28B24"
            : props.orderStatus === "NOTA_FISCAL_EMITIDA"
            ? "#22C5E5"
            : props.orderStatus === "PEDIDO_DESPACHADO"
            ? "#2681FF"
            : props.orderStatus === "PEDIDO_ENTREGUE"
            ? "#3DCC6D"
            : props.orderStatus === "ORDEM_FINALIZADA"
            ? "#3DCC6D"
            : props.orderStatus === "ORDEM_CANCELADA"
            ? "#FF4D4D"
            : "#FF4D4D",
      }}
      className="text-[#000000] py-2 px-4 capitalize rounded-2xl text-xs font-semibold"
    >
      {props.orderStatus === "CRIADO"
        ? "CRIADO"
        : props.orderStatus === "AGUARDANDO_PAGAMENTO"
        ? "AGUARDANDO PAGAMENTO"
        : props.orderStatus === "PROBLEMAS_PAGAMENTO"
        ? "PROBLEMAS COM PAGAMENTO"
        : props.orderStatus === "PAGAMENTO_RECUSADO"
        ? "PAGAMENTO RECUSADO"
        : props.orderStatus === "PAGAMENTO_REALIZADO"
        ? "PAGAMENTO REALIZADO"
        : props.orderStatus === "EM_SEPARACAO"
        ? "EM SEPRARAÇÃO"
        : props.orderStatus === "PEDIDO_SEPARADO"
        ? "PEDIDO SEPARADO"
        : props.orderStatus === "AGUARDANDO_COLETA"
        ? "AGUARDANDO COLETA"
        : props.orderStatus === "NOTA_FISCAL_EMITIDA"
        ? "NOTA FISCAL EMITIDA"
        : props.orderStatus === "PEDIDO_DESPACHADO"
        ? "PEDIDO DESPACHADO"
        : props.orderStatus === "PEDIDO_ENTREGUE"
        ? "PEDIDO ENTREGUE"
        : props.orderStatus === "ORDEM_FINALIZADA"
        ? "FINALIZADO"
        : props.orderStatus === "ORDEM_CANCELADA"
        ? "CANCELADO"
        : "OUTROS"}
    </button>
  );

  const gridShipStatus = (props) => (
    <button
      type="button"
      style={{
        background:
          props.shippingStatus === "PENDENTE"
            ? "#F2F20C"
            : props.shippingStatus === "EM_SEPARACAO"
            ? "#FFD400"
            : props.shippingStatus === "FRETE_COMPRADO"
            ? "#FFAA00"
            : props.shippingStatus === "AGUARDANDO_COLETA"
            ? "#F28B24"
            : props.shippingStatus === "COLETADO"
            ? "#A2C5E5"
            : props.shippingStatus === "EM_TRANSITO"
            ? "#22C5E5"
            : props.shippingStatus === "ENTREGUE"
            ? "#3DCC6D"
            : props.shippingStatus === "NAO_ENTREGUE"
            ? "#D941A6"
            : props.shippingStatus === "CANCELADO"
            ? "#FF4D4D"
            : "#FF4D4D",
      }}
      className="text-[#000000] py-2 px-4 capitalize rounded-2xl text-xs font-semibold"
    >
      {props.shippingStatus === "PENDENTE"
        ? "PENDENTE"
        : props.shippingStatus === "EM_SEPARACAO"
        ? "EM SEPARAÇÃO"
        : props.shippingStatus === "FRETE_COMPRADO"
        ? "FRETE COMPRADO"
        : props.shippingStatus === "AGUARDANDO_COLETA"
        ? "AGUARDANDO COLETA"
        : props.shippingStatus === "COLETADO"
        ? "COLETADO"
        : props.shippingStatus === "EM_TRANSITO"
        ? "EM TRÂNSITO"
        : props.shippingStatus === "ENTREGUE"
        ? "ENTREGUE"
        : props.shippingStatus === "NAO_ENTREGUE"
        ? "NÃO ENTREGUE"
        : props.shippingStatus === "CANCELADO"
        ? "CANCELADO"
        : "OUTROS"}
    </button>
  );

  const gridPaymentStatus = (status) => (
    <button
      type="button"
      style={{
        background:
          status.paymentStatus === "PENDING"
            ? "#FFC107"
            : status.paymentStatus === "PAYMENT_CREATED"
            ? "#007BFF"
            : status.paymentStatus === "PAYMENT_APPROVED_BY_RISK_ANALYSIS"
            ? "#28A745"
            : status.paymentStatus === "PAYMENT_REPROVED_BY_RISK_ANALYSIS"
            ? "#DC3545"
            : status.paymentStatus === "PAYMENT_UPDATED"
            ? "#007BFF"
            : status.paymentStatus === "PAYMENT_CONFIRMED"
            ? "#28A745"
            : status.paymentStatus === "PAYMENT_RECEIVED"
            ? "#28A745"
            : status.paymentStatus === "PAYMENT_CREDIT_CARD_CAPTURE_REFUSED"
            ? "#DC3545"
            : status.paymentStatus === "PAYMENT_ANTICIPATED"
            ? "#4C16E0"
            : status.paymentStatus === "PAYMENT_OVERDUE"
            ? "#DC3545"
            : status.paymentStatus === "PAYMENT_DELETED"
            ? "#DC3545"
            : status.paymentStatus === "PAYMENT_RESTORED"
            ? "#ECDD00"
            : status.paymentStatus === "PAYMENT_REFUNDED"
            ? "#DC3545"
            : status.paymentStatus === "PAYMENT_UPDATED"
            ? "#DC3545"
            : "#3D3D3D",
      }}
      className="text-black py-2 px-4 capitalize rounded-2xl text-xs font-semibold"
    >
      {status.paymentStatus === "PENDING"
        ? "Pendente"
        : status.paymentStatus === "PAYMENT_CREATED"
        ? "Criado"
        : status.paymentStatus === "PAYMENT_APPROVED_BY_RISK_ANALYSIS"
        ? "Aprovado pela Análise de Riscos"
        : status.paymentStatus === "PAYMENT_REPROVED_BY_RISK_ANALYSIS"
        ? "Reprovado pela Análise de Riscos"
        : status.paymentStatus === "PAYMENT_UPDATED"
        ? "Atualizado"
        : status.paymentStatus === "PAYMENT_CONFIRMED"
        ? "Confirmado"
        : status.paymentStatus === "PAYMENT_RECEIVED"
        ? "Recebido"
        : status.paymentStatus === "PAYMENT_CREDIT_CARD_CAPTURE_REFUSED"
        ? "Recusado pela Operadora do Cartão"
        : status.paymentStatus === "PAYMENT_ANTICIPATED"
        ? "Antecipado"
        : status.paymentStatus === "PAYMENT_OVERDUE"
        ? "Expirado"
        : status.paymentStatus === "PAYMENT_DELETED"
        ? "Deletado"
        : status.paymentStatus === "PAYMENT_RESTORED"
        ? "Restaurado"
        : status.paymentStatus === "PAYMENT_REFUNDED"
        ? "Extornado"
        : "Outros"}
    </button>
  );

  const formatCurrency = (value) => {
    return `R$ ${value.toFixed(2).replace(".", ",")}`;
  };

  const CurrencyTemplate = (props) => {
    return <span>{formatCurrency(props.total)}</span>;
  };

  const InvoiceTemplate = (props) => {
    // Verifica se a nota fiscal foi criada
    const isInvoiceCreated = props.invoiceId !== null; // Converte o valor para um booleano

    // Define o ícone correspondente com base no valor da nota fiscal
    const IconComponent = isInvoiceCreated ? CheckCircleIcon : CircleIcon;
    const iconColor = isInvoiceCreated ? "green" : "grey"; // Define a cor do ícone

    return (
      <IconComponent style={{ color: iconColor, width: 15, height: 15 }} />
    );
  };

  const DateTemplate = (props) => {
    // Aqui você pode formatar a data no formato brasileiro
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const formattedDate = new Date(props.createdAt).toLocaleDateString(
      "pt-BR",
      options
    ); // Formato brasileiro de data

    return <span>{formattedDate}</span>;
  };

  const UpdatedTemplate = (props) => {
    // Aqui você pode formatar a data no formato brasileiro
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const formattedDate = new Date(props.updatedAt).toLocaleDateString(
      "pt-BR",
      options
    ); // Formato brasileiro de data

    return <span>{formattedDate}</span>;
  };

  const handlePrint = async (orderId) => {
    try {
      // Requisição para obter o PDF do pedido
      const pdfResponse = await api.get(`/orders/print/${orderId}`, {
        responseType: "arraybuffer", // Certifique-se de solicitar uma resposta no formato de array de bytes
      });

      if (pdfResponse.status === 200) {
        // Criar o Blob com o conteúdo do PDF
        const pdfBlob = new Blob([pdfResponse.data], {
          type: "application/pdf",
        });

        // Criar o URL do PDF a partir do Blob
        const pdfUrl = URL.createObjectURL(pdfBlob);

        window.open(pdfUrl, "_blank");
        window.location.reload();
      }
    } catch (error) {
      console.error("Erro ao imprimir o pedido:", error);
    }
  };

  const handlePrivatePDF = async (orderId) => {
    try {
      // Requisição para obter o PDF do pedido
      const pdfResponse = await api.get(
        `/private/freight/tags/print/${orderId}`,
        {
          responseType: "arraybuffer", // Certifique-se de solicitar uma resposta no formato de array de bytes
        }
      );

      if (pdfResponse.status === 200) {
        // Criar o Blob com o conteúdo do PDF
        const pdfBlob = new Blob([pdfResponse.data], {
          type: "application/pdf",
        });

        // Criar o URL do PDF a partir do Blob
        const pdfUrl = URL.createObjectURL(pdfBlob);

        window.open(pdfUrl, "_blank");
        window.location.reload();
      }
    } catch (error) {
      console.error("Erro ao imprimir a etiqueta:", error);
    }
  };

  const handlePrivateZPL = async (orderId) => {
    try {
      // Requisição para obter o ZPL do pedido
      const zplResponse = await api.get(
        `/private/freight/tags/zpl/${orderId}`,
        {
          responseType: "text", // Solicitando uma resposta no formato de texto
        }
      );

      if (zplResponse.status === 200) {
        // Criar o Blob com o conteúdo do ZPL
        const zplBlob = new Blob([zplResponse.data], {
          type: "text/plain", // Tipo MIME para texto simples
        });

        // Obter o nome do arquivo do cabeçalho de resposta
        const contentDisposition = zplResponse.headers["content-disposition"];
        const filenameMatch = contentDisposition
          ? contentDisposition.match(/filename="(.+)"/i)
          : null;
        let filename = "unknown.zpl"; // Nome padrão do arquivo, caso não seja encontrado no cabeçalho

        if (filenameMatch && filenameMatch.length > 1) {
          filename = filenameMatch[1];
        }

        // Criar o URL do ZPL a partir do Blob
        const zplUrl = URL.createObjectURL(zplBlob);

        // Criar um elemento de link para download
        const downloadLink = document.createElement("a");
        downloadLink.href = zplUrl;
        downloadLink.download = filename; // Usar o nome do arquivo obtido do cabeçalho de resposta
        document.body.appendChild(downloadLink);
        downloadLink.click();

        // Remover o elemento de link após o clique
        document.body.removeChild(downloadLink);

        // Revogar o URL do Blob para liberar memória
        URL.revokeObjectURL(zplUrl);

        // Reload na pagina de Order
        window.location.reload();
      }
    } catch (error) {
      console.error("Erro ao carregar o ZPL do pedido:", error);
      // Adicionar feedback para o usuário em caso de erro
    }
  };

  const handlePrivateSend = async (orderId) => {
    try {
      const response = await api.put(`/orders/${orderId}`, {
        shippingStatus: "COLETADO",
      });

      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {}
  };

  const handlePrivateDelivery = async (orderId) => {
    try {
      const response = await api.put(`/orders/${orderId}`, {
        shippingStatus: "ENTREGUE",
      });

      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {}
  };

  const handleAddFreight = async (orderId) => {
    try {
      const response = await api.post("/me/freight/add", { orderId: orderId });

      if (response.status === 200) {
        const quoteDetails = {
          meId: response.data.id,
          protocol: response.data.protocol,
        };

        // Atualizar estado local com setOrderSeted ou outra lógica necessária
        setOrderSeted(response.data);

        const index = ordersData.findIndex((order) => order.id === orderId);
        if (index !== -1) {
          let updatedOrder = {
            ...ordersData[index],
            freightDetails: {
              ...ordersData[index].freightDetails,
              quote: [
                ...(ordersData[index].freightDetails?.quotes || []),
                quoteDetails,
              ],
              purchase: ordersData[index].freightDetails?.purchase || {
                meId: "",
                protocol: "",
              },
            },
            tagDetails: { status: false, url: "https://someurl.com" },
          };

          // Atualização no servidor com a chamada PUT
          const updateResponse = await api.put(
            `/orders/${orderId}`,
            updatedOrder
          );
          if (updateResponse.status === 200) {
            let updatedOrders = [...ordersData];
            updatedOrders[index] = updateResponse.data;
            setOrdersData(updatedOrders);
            setIsOpen(true);
            return { orderId, quoteDetails };
          } else {
            throw new Error("Falha ao atualizar ordem com frete adicionado");
          }
        }
      } else {
        throw new Error("Erro ao adicionar frete");
      }
    } catch (error) {
      console.error("handleAddFreight error:", error);
      console.log("Deu alguma merda");
      throw error;
    }
  };

  const handleBuyFreight = async (orderId) => {
    try {
      let index = ordersData.findIndex((order) => order.id === orderId);
      let updatedOrder;
      if (index !== -1) {
        const uOrder = ordersData[index]?.freightDetails.quote[0];
        const response = await api.post("/me/freight/purchasing", {
          orders: [uOrder.meId],
        });
        if (response.status === 200) {
          const purchaseDetails = {
            meId: response.data?.purchase?.orders[0].id,
            protocol: response.data?.purchase?.protocol,
          };

          updatedOrder = {
            ...ordersData[index],
            freightDetails: {
              ...ordersData[index].freightDetails,
              purchase: purchaseDetails,
            },
          };

          // Atualização no servidor com a chamada PUT
          const updateResponse = await api.put(
            `/orders/${orderId}`,
            updatedOrder
          );
          if (updateResponse.status === 200) {
            // Atualizar o estado local com os dados atualizados
            let updatedOrders = [...ordersData];
            updatedOrders[index] = updateResponse.data;
            setOrdersData(updatedOrders);
            window.location.reload();
          } else {
            throw new Error("Falha ao atualizar ordem com frete comprado");
          }
        }
      }
    } catch (error) {
      console.error("handleBuyFreight error:", error);
      throw error;
    }
  };

  const handleCreateTag = async (orderId) => {
    try {
      const index = ordersData.findIndex((order) => order.id === orderId);
      const uOrder = ordersData[index]?.freightDetails.purchase;

      const response = await api.post("/me/freight/tags", {
        orderId: orderId,
        orders: [uOrder.meId],
      });
      if (response.status === 200) {
        const tagDetail = response.data; // Assumindo que a resposta contém os detalhes da tag

        if (index !== -1) {
          window.open(tagDetail.url, "_blank", "noopener,noreferrer");
          window.location.reload();
        }
      }
    } catch (error) {
      console.error("handleCreateTag error:", error);
      throw error;
    }
  };

  const handleViewTag = async (orderId) => {
    let index = ordersData.findIndex((order) => order.id === orderId);
    try {
      const tagUrl = ordersData[index]?.tagDetails.url;
      const tagStatus = ordersData[index]?.tagDetails.status;

      if (tagStatus) {
        window.open(tagUrl, "_blank", "noopener,noreferrer");
        window.location.reload();
      } else {
        setWarning({
          type: "error",
          message: "Etiqueta ainda não foi gerada!",
        });
        handleOpenSnackbar();
      }
    } catch (error) {}
  };

  const handleTracking = async (orderId) => {
    let index = ordersData.findIndex((order) => order.id === orderId);
    try {
      const tagTracking = ordersData[index]?.shippingTrack;

      if (tagTracking && tagTracking !== "") {
        window.open(
          `https://app.melhorrastreio.com.br/app/melhorenvio/${tagTracking.toUpperCase()}`,
          "_blank",
          "noopener,noreferrer"
        );
        window.location.reload();
      } else {
        setWarning({
          type: "error",
          message: "Rastreio ainda não foi gerado!",
        });
        handleOpenSnackbar();
      }
    } catch (error) {}
  };

  const processFreightForOrder = async (orderId) => {
    try {
      // Adiciona o frete e atualiza a ordem
      const addFreightResult = await handleAddFreight(orderId);
      console.log("Frete adicionado e ordem atualizada:", addFreightResult);

      // Compra o frete com base nos detalhes do frete adicionado e atualiza a ordem
      const buyFreightResult = await handleBuyFreight(addFreightResult);
      console.log("Frete comprado e ordem atualizada:", buyFreightResult);

      // Cria a etiqueta com base nos detalhes da compra do frete e atualiza a ordem
      const tagDetails = await handleCreateTag(buyFreightResult);
      console.log("Etiqueta criada e ordem atualizada:", tagDetails);

      // Se necessário, execute mais ações aqui após a conclusão bem-sucedida de todas as etapas
      console.log(
        "Processo de frete concluído com sucesso para a ordem:",
        orderId
      );
    } catch (error) {
      console.error(
        "Erro durante o processo de frete para a ordem:",
        orderId,
        error
      );
      // Aqui você pode tratar erros específicos ou mostrar mensagens ao usuário
    }
  };

  const generateNF = (orderId) => {
    // Encontra a ordem pelo id
    let order = ordersData.find((order) => order.id === orderId);

    // Checa se a ordem foi encontrada antes de navegar
    if (order) {
      navigate("/nfce", { state: { order } }); // Passa a ordem inteira no estado
    } else {
      console.error("Ordem não encontrada.");
    }
  };

  const finishOrder = async (orderId) => {
    try {
      const response = await api.put(`/orders/${orderId}`, {
        orderStatus: "ORDEM_FINALIZADA",
      });

      if (response.status === 200) {
        setWarning({
          type: "success",
          message: "Pedido Finalizado",
        });
        handleOpenSnackbar();
      }
    } catch (error) {}
  };

  const cancelOrder = async (orderId) => {
    try {
      const response = await api.put(`/orders/${orderId}`, {
        orderStatus: "ORDEM_CANCELADA",
      });

      if (response.status === 200) {
        setWarning({
          type: "success",
          message: "Pedido Cancelado",
        });
        handleOpenSnackbar();
      }
    } catch (error) {}
  };

  function BasicMenu({
    orderId,
    onPrint,
    onPrivatePDF,
    onPrivateZPL,
    onPrivateSend,
    onPrivateDelivery,
    onAddFreight,
    onBuyFreight,
    onTagCreate,
    onViewTag,
    onTracking,
    onNfCreate,
    onFinishOrder,
    oncancelOrder,
  }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      event.stopPropagation(); // This will prevent the event from propagating to the parent
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handlePrint = () => {
      // Close the menu
      handleClose();

      onPrint(orderId);
    };

    const handlePrivatePDF = () => {
      // Close the menu
      handleClose();

      onPrivatePDF(orderId);
    };

    const handlePrivateZPL = () => {
      // Close the menu
      handleClose();

      onPrivateZPL(orderId);
    };

    const handlePrivateSend = () => {
      // Close the menu
      handleClose();

      onPrivateSend(orderId);
    };

    const handlePrivateDelivery = () => {
      // Close the menu
      handleClose();

      onPrivateDelivery(orderId);
    };

    const handleAddFreight = () => {
      // Close the menu
      handleClose();
      // Call the onAddFreight function passed as a prop
      onAddFreight(orderId);
    };

    const handleBuyFreight = () => {
      handleClose();
      onBuyFreight(orderId);
    };

    const handleTagCreate = () => {
      // Close the menu
      handleClose();
      onTagCreate(orderId);
    };

    const handleViewTag = () => {
      // Close the menu
      handleClose();
      onViewTag(orderId);
    };

    const handleTracking = () => {
      // Close the menu
      handleClose();
      onTracking(orderId);
    };

    const handleNfCreate = () => {
      // Close the menu
      handleClose();
      onNfCreate(orderId);
    };

    const finishOrder = () => {
      handleClose();
      onFinishOrder(orderId);
    };
    const cancelOrder = () => {
      handleClose();
      oncancelOrder(orderId);
    };

    // Encontra a ordem pelo id

    let order = ordersData.find((order) => order.id === orderId);

    return loaded ? (
      <div>
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <ListIcon />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <Typography variant="subtitle2" className="px-2">
            Pedido
          </Typography>
          <MenuItem onClick={handlePrint}>imprimir</MenuItem>
          <MenuItem onClick={finishOrder}>Finalizar</MenuItem>
          <MenuItem onClick={cancelOrder}>Cancelar</MenuItem>
          <Divider />
          <Typography variant="subtitle2" className="px-2">
            Envio Privado
          </Typography>
          <MenuItem onClick={handlePrivatePDF}>Etiqueta Pdf</MenuItem>
          <MenuItem onClick={handlePrivateZPL}>Etiqueta Zpl</MenuItem>
          <MenuItem onClick={handlePrivateSend}>Coletado</MenuItem>
          <MenuItem onClick={handlePrivateDelivery}>Entregue</MenuItem>
          <Divider />
          <Typography variant="subtitle2" className="px-2">
            Melhor Envio
          </Typography>
          <MenuItem onClick={handleAddFreight}>Cotar Frete</MenuItem>
          <MenuItem
            onClick={handleBuyFreight}
            disabled={
              !order?.freightDetails?.quote ||
              order?.freightDetails?.quote?.length === 0
            }
          >
            Comprar Frete
          </MenuItem>
          <MenuItem
            onClick={handleTagCreate}
            disabled={
              !order?.freightDetails?.purchase ||
              order?.freightDetails?.purchase?.protocol === ""
            }
          >
            Gerar Etiqueta
          </MenuItem>
          <MenuItem
            onClick={handleViewTag}
            disabled={!order?.tagDetails?.status}
          >
            Visualizar Etiqueta
          </MenuItem>
          <MenuItem
            onClick={handleTracking}
            disabled={!order?.shippingTrack || order?.shippingTrack === ""}
          >
            Acompanhar Pedido
          </MenuItem>
          <Divider />
          <Typography variant="subtitle2" className="px-2">
            Nota Fácil
          </Typography>
          <MenuItem onClick={handleNfCreate}>Gerar NF-e</MenuItem>
          <MenuItem onClick={handleClose}>Emitir NF-e</MenuItem>
          <MenuItem onClick={handleClose}>Visualizar NF-e</MenuItem>
        </Menu>
      </div>
    ) : (
      <Loading />
    );
  }

  const ActionMenuTemplate = ({ orderId }) => (
    <BasicMenu
      orderId={orderId}
      onPrint={handlePrint}
      onPrivatePDF={handlePrivatePDF}
      onPrivateZPL={handlePrivateZPL}
      onPrivateSend={handlePrivateSend}
      onPrivateDelivery={handlePrivateDelivery}
      onAddFreight={handleAddFreight}
      onTracking={handleTracking}
      onBuyFreight={handleBuyFreight}
      onTagCreate={handleCreateTag}
      onViewTag={handleViewTag}
      onNfCreate={generateNF}
      onFinishOrder={finishOrder}
      oncancelOrder={cancelOrder}
    />
  );

  const ordersGrid = [
    {
      field: "NPedido",
      headerText: "Nº Pedido",
      class: "text-white",
      width: "150",
      textAlign: "Center",
      template: (rowData) => <span>{rowData.NPedido}</span>,
    },
    {
      field: "createdAt",
      headerText: "Data do Pedido",
      width: "150",
      textAlign: "Center",
      template: DateTemplate, // substitua 'DateTemplate' pelo seu template de data, se necessário
    },
    {
      field: "user.name",
      headerText: "Cliente",
      width: "150",
      textAlign: "Center",
    },
    {
      field: "total",
      headerText: "Total",
      template: CurrencyTemplate,
      textAlign: "Center",
      width: "150",
    },
    {
      headerText: "Status do Pedido",
      template: gridOrderStatus,
      field: "orderStatus",
      textAlign: "Center",
      width: "250",
    },
    {
      headerText: "Status do Pagamento",
      template: gridPaymentStatus,
      field: "orderStatus",
      textAlign: "Center",
      width: "150",
    },
    {
      headerText: "NF",
      field: "invoiceId",
      textAlign: "Center",
      width: "50",
      template: InvoiceTemplate, // substitua 'InvoiceTemplate' pelo seu template para a NF, se necessário
    },
    {
      headerText: "Status da Entrega",
      template: gridShipStatus,
      field: "shippingStatus",
      textAlign: "Center",
      width: "150",
    },
    {
      field: "orderShippingAddress.city",
      headerText: "Cidade de Entrega",
      width: "150",
      textAlign: "Center",
    },
    {
      headerText: "Ações",
      width: "100",
      textAlign: "Center",
      template: ({ id }) => <ActionMenuTemplate orderId={id} />,
    },
  ];

  return loaded ? (
    <>
      {uniqueOrder ? (
        <UniqueOrder order={uniqueOrder} setUniqueOrder={setUniqueOrder} />
      ) : (
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white dark:text-gray-200 dark:bg-secondary-dark-bg bg-no-repeat bg-cover bg-center rounded-3xl">
          <Header category="Administrativo" title="Vendas" />
          <div className="flex flex-col gap-y-2">
            <div className="w-1/5">
              <OrderFilterSelect
                organizeOrder={organizeOrder}
                setOrganizeOrder={setOrganizeOrder}
              />
            </div>
            <GridComponent
              key={organizeOrder}
              id="gridcomp"
              dataSource={filteredOrders}
              allowPaging
              allowSorting
              allowExcelExport
              allowPdfExport
              contextMenuItems={contextMenuItems}
              rowSelected={handleRowSelect}
              className="custom-cursor-pointer"
            >
              <ColumnsDirective>
                {ordersGrid.map((item, index) => (
                  <ColumnDirective key={index} {...item} />
                ))}
              </ColumnsDirective>
              <Inject
                services={[
                  Resize,
                  Sort,
                  ContextMenu,
                  Filter,
                  Page,
                  ExcelExport,
                  Edit,
                  PdfExport,
                ]}
              />
            </GridComponent>{" "}
          </div>
        </div>
      )}
      <ChooseFreight
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        freight={orderSeted}
      />
      <Alerts
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        type={warning.type}
        message={warning.message}
      />
    </>
  ) : (
    <Loading />
  );
};
export default Orders;

const OrderFilterSelect = ({ organizeOrder, setOrganizeOrder }) => {
  const handleChange = (event) => {
    setOrganizeOrder(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="order-filter-label">Filtrar Pedidos</InputLabel>
      <Select
        labelId="order-filter-label"
        value={organizeOrder}
        onChange={handleChange}
        label="Filtrar Pedidos"
      >
        <MenuItem value="andamento">Pedidos em Andamento</MenuItem>
        <MenuItem value="tudo">Ver Tudo</MenuItem>
        <MenuItem value="pago">Pedidos Pago</MenuItem>
        <MenuItem value="separacao">Pedidos em Separação</MenuItem>
        <MenuItem value="rota">Pedidos em Rota de Entrega</MenuItem>
        <MenuItem value="entregue">Pedidos Entregues</MenuItem>
        <MenuItem value="problema">Pedidos com Problema</MenuItem>
        <MenuItem value="finalizado">Pedidos Finalizados</MenuItem>
        <MenuItem value="cancelado">Pedidos Cancelados</MenuItem>
      </Select>
    </FormControl>
  );
};
