import React from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import api from "../../api/api";
import dayjs from "dayjs";
import "dayjs/locale/pt-br"; // Importa o locale pt-br do dayjs

dayjs.locale("pt-br"); // Define o locale como pt-br

const Create = (props) => {
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      name: "",
      description: "",
      code: "",
      discount: "",
      expiresIn: dayjs().format("YYYY-MM-DD"),
      isUnique: false,
    },
  });

  const onSubmit = async (data) => {
    try {
      const formattedDiscount = parseInt(data.discount);
      const formattedDate = dayjs(data.expiresIn).toISOString();
      const res = await api.post("/vouchers", {
        ...data,
        expiresIn: formattedDate,
        discount: formattedDiscount,
      }); // Ajuste o endpoint conforme necessário
      if (res.status === 201) {
        reset();
        props.setStep(""); // Chame a função onClose para fechar o formulário após a criação do cupom
      }
    } catch (error) {
      console.error("Erro ao criar cupom:", error);
    }
  };
  return (
    <Box sx={{ padding: 2, maxWidth: 400, margin: "0 auto" }}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => props.setStep("")}
        sx={{ mb: 2 }}
      >
        Voltar
      </Button>
      <Typography variant="h5" mb={2}>
        Criar Novo Cupom
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Nome"
              variant="outlined"
              fullWidth
              margin="normal"
              required
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Descrição"
              variant="outlined"
              fullWidth
              margin="normal"
              required
            />
          )}
        />
        <Controller
          name="code"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Código"
              variant="outlined"
              fullWidth
              margin="normal"
              required
            />
          )}
        />
        <Controller
          name="discount"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Desconto (%)"
              type="number"
              variant="outlined"
              fullWidth
              margin="normal"
              required
            />
          )}
        />
        <Controller
          name="expiresIn"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Data de Expiração"
              type="date"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              fullWidth
              margin="normal"
              required
            />
          )}
        />
        <Controller
          name="isUnique"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label="Este cupom é de uso único?"
            />
          )}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Criar Cupom
        </Button>
      </form>
    </Box>
  );
};

export default Create;
